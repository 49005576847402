om-maturity-input {
  padding: 9px 12px;
  .mat-mdc-form-field-infix {
    width: 100%;
    padding: 0 !important;
    .mat-mdc-chip-set {
      margin: -8px 0;
    }
  }
  > input {
    display: none !important;
  }
  display: flex;
  align-items: center;
}
