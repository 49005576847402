om-issuer-profile-notes-overview-widget {
  display: flex !important;
  flex-direction: column;
  article {
    overflow: auto !important;
    padding: 0 !important;
    a {
      cursor: pointer;
    }
  }
  .mat-mdc-card {
    border: none;
    border-bottom: solid var(--color-stroke) 1px;
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
