om-data-table-input {
  display: block;
  margin: 4px 0 -4px;
  overflow: hidden;
  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);

  ag-grid-angular {
    height: auto;
    .ag-cell:last-child,
    .ag-header-cell:last-child {
      border-right: 0;
    }
    .ag-layout-auto-height .ag-center-cols-viewport {
      min-height: 0;
    }
    .ag-row-even {
      background: var(--color-background-2);
    }
    .ag-row-odd {
      background: var(--color-row-odd);
    }
    .ag-cell.is-readonly {
      background: var(--color-background-3);
      color: var(--color-primary);
      font-weight: var(--font-weight-bold);
    }
    .ag-cell {
      border-right: 1px solid var(--color-stroke) !important;

      &:last-of-type {
        border-right: 0 !important;
      }
    }
  }
  > div {
    height: var(--button-height);
    position: relative;
    .mat-mdc-button {
      padding: 0 10px;
      border: none !important;
      border-radius: 0 !important;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}

om-data-table-row-delete-cell-renderer {
  position: absolute;
  left: -3.5px;
  top: -3.5px;
}
