om-users-icons-cell-renderer {
  display: flex;
  .om-circle-content {
    border-radius: 50%;
    background-color: cadetblue;
    display: flex;
    width: 25px;
    height: 25px;
    justify-content: center;
    align-items: center;
    margin: var(--spacing-small);
    margin-top: 6px;
    font-weight: var(--font-weight-bold);
    font-size: small;
  }
}
