gridster {
  background: unset !important;
  max-width: 1200px;
  margin: auto;
  gridster-item {
    box-shadow: 0px 2px 10px var(--color-stroke);
    overflow: unset !important;
  }
}

.grid-layout-container {
  width: 100%;
  height: 100%;
  padding-bottom: var(--spacing-large);
}

om-grid-layout {
  height: 100%;
}
