om-curve-analysis-page {
  overflow-y: auto;

  main {
    height: auto;
    overflow-x: visible;
    overflow-y: visible;
    padding-bottom: 50px;
  }
  .empty-template {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h2,
    p {
      display: flex;
      justify-content: center;
    }

    p {
      width: 400px;
      text-align: center;
    }

    img {
      &:nth-child(1) {
        width: 400px;
      }
      &:nth-child(2) {
        width: 400px;
      }
    }
  }
}
