om-pricer {
  display: block;
  .mat-toolbar {
    section {
      &:nth-child(1) {
        width: 84px;
      }

      .mat-mdc-form-field {
        min-width: 160px;
        width: 160px;
        height: 40px;
        &:not(.is-inline) {
          background-color: var(--color-background-2);
          border-radius: 6px;
          height: unset;
          color: var(--color-primary);
          .mat-mdc-form-field-infix {
            padding: 9px 0px 9px 12px;
          }
          .mat-mdc-chip-set {
            margin-left: 0 !important;
          }
        }
        .mat-mdc-text-field-wrapper {
          padding: 0;
          margin: 0;
          height: 40px;
        }
      }
    }
  }
  span {
    font-weight: normal !important;
  }
}
