om-document-generator-toolbar-secondary {
  .mat-toolbar {
    om-shared-search {
      margin-left: 0;
      .om-multi-action {
        padding-right: 0 !important;
      }
    }
  }

  .om-toolbar-small {
    display: none !important;
  }
  .om-toolbar-large {
    display: flex !important;
  }

  @media (max-width: 1366px) {
    .mat-toolbar {
      &.om-can-minimise {
        .om-toolbar-small {
          display: flex !important;
          &.mdc-icon-button {
            width: fit-content;
            > span {
              font-size: var(--font-size-medium);
              font-weight: var(--font-weight-bold);
              line-height: 20px;
            }
          }
        }
        .om-toolbar-large {
          display: none !important;
        }
      }
    }
  }
}
