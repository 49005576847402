om-curve-analysis-widget {
  background: url($assets + 'img/curve-analysis-widget-bg.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  height: 100%;
  h2 {
    font-size: var(--font-size-large);
  }

  article {
    height: calc(100% - 52px);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 15px 0;

    .mat-toolbar {
      background: transparent;
    }
  }
}
