om-forbidden,
om-not-found {
  color: var(--color-primary);
  background: var(--color-background-1);
  h1,
  h2 {
    margin: 0 auto 32px;
  }
  h1 {
    margin-top: 64px;
    font-size: 120px;
    line-height: 1em;
  }
}
