om-table-input {
  .mat-mdc-table {
    margin: -3px 0;
    border-radius: var(--border-radius);
    overflow: hidden;
    background: transparent;
    .mat-mdc-row {
      .mat-mdc-cell {
        border-radius: none;
        border-color: var(--color-stroke);
        padding: 0 12px;
        &:first-child {
          color: var(--color-primary);
          font-size: var(--font-size-medium);
          font-weight: var(--font-weight-normal);
          padding-left: 10px;
          padding-right: 16px;
          width: 50%;
          white-space: nowrap;
          cursor: pointer;
          border-right-width: 1px;
          border-right-style: solid;
          background-color: var(--color-row-odd);
        }
        .mat-mdc-form-field {
          .mat-mdc-form-field-infix {
            padding: 0;
            padding-top: 0px !important;
            padding-bottom: 0px !important;
            * {
              padding: 0;
            }
            om-select-input {
              .mat-mdc-select-arrow-wrapper {
                margin-right: 10px;
              }
            }
          }
        }
      }
      &:last-child {
        .mat-mdc-cell {
          border-bottom: none;
        }
      }
    }
  }
}
