.om-trade-reviews-status-menu {
  padding-right: var(--spacing-modal-side);
  padding-left: var(--spacing-modal-side);
  padding-top: 10px;
  padding-bottom: var(--spacing-modal-side);
  width: 415px;

  .mat-mdc-card {
    padding: 0;
    margin: 10px 0;
    .mat-mdc-card-header {
      padding: 16px;
      padding-right: 14px;
      margin: 0;
      .mat-icon {
        margin: 7px;
      }
    }
    .mat-mdc-card-content {
      padding: 10px 16px;
    }
    .mat-mdc-card-title {
      font-size: var(--font-size-large) !important;
    }
    .mat-mdc-card-subtitle {
      font-size: var(--font-size-medium) !important;
    }

    .mat-mdc-card-content {
      border-top: 1px solid var(--color-stroke);
      & > div {
        padding: 10px 0;
        display: flex !important;
        justify-content: space-between;
        align-items: center;
        span {
          display: block;
          &:nth-child(2) {
            color: var(--color-label);
            font-size: var(--font-size-small);
          }
        }
        .mat-mdc-button {
          min-width: 0 !important;
          padding: 0 5px;
        }
      }
    }
  }
}

.om-empty-review-modal {
  padding: 0 25px;
}
