@mixin funLogo($logoPath) {
  content: '-';
  color: transparent;
  background-image: url($assets + $logoPath);
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  display: block;
  pointer-events: none;
  background-color: var(--color-navigation-1);
}

&.om-christmas {
  om-main-view-sidenav .mat-toolbar::after {
    content: '-';
    color: transparent;
    background-image: url($assets + 'img/fun/santa-hat-2.png');
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    display: block;
    width: 22px;
    top: 12px;
    left: 18px;
  }
}
&.om-christmas-large {
  om-main-view-sidenav .mat-toolbar {
    &::before {
      content: '-';
      color: transparent;
      background-image: url($assets + 'img/origin-markets/icon-white.svg');
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      display: block;
      width: 28px;
      top: 11px;
      left: 18px;
      pointer-events: none;
    }
    &::after {
      content: '-';
      color: transparent;
      background-image: url($assets + 'img/fun/santa-hat-2.png');
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      display: block;
      width: 31px;
      top: 4px;
      left: 12px;
      pointer-events: none;
    }
  }
}
&.om-easter {
  om-main-view-sidenav .mat-toolbar {
    &::before {
      @include funLogo('img/fun/easter-chick.svg');
      width: 24px;
      top: 10px;
      left: 20px;
    }
  }
}
&.om-halloween {
  om-main-view-sidenav .mat-toolbar {
    &::before {
      content: '-';
      color: transparent;
      background-image: url($assets + 'img/origin-markets/icon-white.svg');
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      display: block;
      width: 22px;
      top: 14px;
      left: 21px;
      pointer-events: none;
    }
    &::after {
      content: '-';
      color: transparent;
      background-image: url($assets + 'img/fun/witch-hat.svg');
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      display: block;
      transform: scale(1.5);
      width: 27px;
      top: 4.5px;
      left: 13px;
      pointer-events: none;
    }
  }
}
&.om-lunar-new-year {
  om-main-view-sidenav .mat-toolbar {
    &::before {
      content: '-';
      color: transparent;
      background-image: url($assets + 'img/fun/lantern.svg');
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      display: block;
      transform: scale(2);
      width: 16px;
      top: 6px;
      left: 25px;
      pointer-events: none;
    }
    &::after {
      content: '-';
      color: transparent;
      background-image: url($assets + 'img/fun/icon-yellow.svg');
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      display: block;
      width: 16px;
      top: 17px;
      left: 25px;
      pointer-events: none;
    }
  }
}
