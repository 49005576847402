om-file-input {
  display: flex;
  margin: -20px -9px -12px;
  padding: 31px 14px 23px 21px;
  border-radius: 4px;
  cursor: pointer;
  justify-content: space-between;

  > span {
    display: inline-block;
    width: calc(100% - 40px);
    line-height: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
    &.om-filename {
      width: 175px;
      display: inline-flex;
      align-items: center;
      span {
        white-space: pre;
        &:nth-child(1) {
          flex-grow: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: fit-content;
        }
        &:nth-child(2) {
          flex-grow: 0;
          width: fit-content;
        }
      }
    }
  }
  input {
    display: none;
  }

  .mat-mdc-menu-trigger {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding: 15px;
    opacity: 0;
    z-index: -1;
  }

  button {
    margin-right: -5px;
  }
}

.mat-form-field-disabled {
  om-file-input {
    opacity: var(--disabled-opacity);
    cursor: inherit;
    pointer-events: none;
  }
}
