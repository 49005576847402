om-document-extractor-confirm {
  height: 100%;
  display: flex;
  flex-direction: column;

  .progress-container {
    padding: 30px 90px 40px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex: 0 0 auto;
    .progress-section {
      position: relative;
      display: flex;
      align-items: center;
      flex-direction: column;
      opacity: 0.4;
      padding: 0 var(--spacing-small);

      &.active,
      &.complete {
        opacity: 1;
        .mat-icon {
          background-color: var(--color-accent-soft);
          outline: 4px solid var(--color-special);
        }
      }

      &.complete {
        .mat-icon {
          outline: none;
          padding: 2px !important;
        }
      }

      .mat-icon {
        background-color: var(--color-label);
        color: var(--color-background-2);
        height: 20px;
        width: 20px;
        padding: 3px 6px;
        border-radius: 50%;
        font-size: var(--font-size-medium-uppercase);
      }

      .label {
        position: absolute;
        font-size: var(--font-size-medium);
        font-weight: var(--font-weight-semi-bold);
        color: var(--color-primary);
        line-height: 14px;
        top: 140%;
        white-space: nowrap;
      }

      &:first-of-type {
        .mat-icon {
          padding: 3px 7px;
        }
      }
    }

    .mat-divider {
      width: 100%;
      &.complete {
        border-top: 3px solid var(--color-accent-soft);
      }
    }
  }

  .content-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
    .info-text-container,
    .form-container {
      padding: 0 62px;
    }
    .info-text-container {
      flex: 0 0 auto;
      z-index: 100;
      background-color: var(--color-background-2);
      padding-bottom: var(--spacing-large);

      .info-text {
        display: flex;
        align-items: center;
        padding: 10px var(--spacing-large);
        background-color: var(--color-alert-background);
        border: 1px solid var(--color-alert-background);
        border-radius: var(--border-radius);
        font-size: var(--font-size-medium);
        line-height: var(--line-height-small);

        .mat-icon {
          width: 24px;
          height: 24px;
          margin-right: var(--spacing-small);
        }
      }
    }

    .form-container {
      padding-top: var(--spacing-medium);
      flex: 1;
      overflow: scroll;

      .scroll-button {
        position: absolute;
        z-index: 100;
        bottom: 0;
        right: 50%;
        transform: translate(50%, -270%);
        width: 92px;
        border-radius: var(--border-radius-large);
        padding: var(--spacing-small) 10px var(--spacing-small) 16px !important;
        background-color: var(--color-special);
        color: var(--color-primary);
        box-shadow: 0px 2px 10px 2px #00000026;

        .button-text {
          margin-right: var(--spacing-small);
          font-size: var(--font-size-medium);
          font-weight: var(--font-weight-semi-bold);
          text-transform: uppercase;
        }

        .mdc-icon-button__ripple {
          border-radius: var(--border-radius-large);
        }

        &:hover {
          background: #8cc2ec !important;
          color: var(--color-primary) !important;
        }
      }
    }

    .buttons-container {
      padding: 16px 62px 30px;
      box-shadow: 0px -2px 10px 2px rgba(0, 0, 0, 0.15);
      display: flex;
      flex: 0 0 auto;
      background-color: var(--color-background-2);
      justify-content: space-between;
      z-index: 100;

      .mat-mdc-button {
        width: 49%;

        &.is-single {
          width: 100% !important;
        }
      }
    }
  }

  om-dynamic-form-field {
    &:before {
      content: '';
      width: 36px;
    }
    &:after {
      content: '';
      width: 36px;
    }
  }
}
