om-square-logo-cell-renderer {
  background-color: inherit;

  img,
  .om-empty-image {
    width: var(--square-logo-height);
    height: var(--square-logo-height);
    display: inline-block;
    vertical-align: middle;
    margin-right: var(--spacing-medium);
    margin-top: -2px;
  }
}
