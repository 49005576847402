& {
  --color-primary: #82c4f8;
  --color-primary-hover: #95cdf9;
  --color-primary-contrast: black;

  --color-secondary: #79a8cc;

  --color-label: #6d889c;
  --color-label-background: #3e586e;
  --color-label-hover: #839aab;
  --color-label-contrast: white;

  --color-accent: #61ffd3;
  --color-accent-background: #33ffb7;
  --color-accent-background-ag-grid: #33ffb7;
  --color-accent-hover: #79ffda;
  --color-accent-contrast: white;

  --color-accent-soft: #3a82bc;
  --color-accent-soft-background: #185297;
  --color-accent-soft-hover: #5895c6;
  --color-accent-soft-contrast: white;

  --color-special: #cbe8ff;
  --color-special-hover: #d3ebff;

  --color-alert: #e99b00;
  --color-alert-background: #d96d00;
  --color-alert-background-ag-grid: #d96d00;
  --color-alert-hover: #ecaa26;
  --color-alert-contrast: white;

  --color-warning: #e45b5b;
  --color-warning-background: #d12f2f;
  --color-warning-background-ag-grid: #d12f2f;
  --color-warning-hover: #e87474;
  --color-warning-contrast: white;

  --color-background-1: #292530;
  --color-background-2: #29262e;
  --color-background-3: #211d28;
  --color-background-4: #211d28;
  --color-background-contrast: white;

  --color-navigation-1: #060c17;
  --color-navigation-2: #04192a;
  --color-navigation-3: #12283a;
  --color-navigation-contrast: white;

  --color-stroke: #404040;
  --color-greyed-out: #b6c4ce;

  --color-row-even: #191919;
  --color-row-odd: #323232;
}
