om-dealer-profile-toolbar {
  .mat-toolbar section > img {
    height: 56px;
    width: 56px;
    object-fit: contain;
  }

  .om-page-toolbar {
    div {
      align-items: center;
    }
  }
  section[middle] {
    &::before {
      display: none !important;
    }
  }

  .is-narrow {
    padding-right: var(--spacing-modal-side);
  }

  .om-profile-info {
    line-height: initial;
    display: grid;
    grid-template-columns: minmax(auto, max-content) 1fr 1fr;
    grid-template-rows: 28px 28px;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
      'title title action'
      'flag address action';

    align-items: center;

    h2 {
      font-size: var(--font-size-x-large);
    }
    img {
      height: 18px;
    }

    margin-left: var(--spacing-medium);
  }

  .om-profile-info__title {
    grid-area: title;
  }

  .om-profile-info__flag {
    grid-area: flag;
  }

  .om-profile-info__address {
    grid-area: address;
    margin-left: var(--spacing-medium);
  }

  .om-profile-info__action {
    grid-area: action;
    align-self: flex-start;
    margin-left: var(--spacing-medium);
  }
}
