@use 'sass:map';
@use 'sass:math';
@use '@angular/material' as mat;
@use 'ag-grid-community/styles' as ag;

$font-family: ProximaNova, monospace;

@import '../../../node_modules/mathlive/dist/mathlive-fonts.css';

@import 'tippy.js/dist/tippy.css';
@import 'tippy.js/themes/light-border.css';

@import 'environment';
@import './scss/builder/createPalette';
@import './scss/builder/font.OmIconFont.scss';
@import './scss/builder/font.ProximaNova.scss';
@import './scss/builder/theme.ag-grid';
@import './scss/builder/theme.material';
@import './scss/themes.scss';

html {
  overflow: hidden;
  background-color: #20364b;
}
html,
body {
  height: 100%;
  -webkit-font-smoothing: antialiased;
}

body {
  margin: 0;
  font-family: $font-family;
  line-height: 1.25em;
  font-weight: var(--font-weight-normal);
}

.om-morpho {
  @import './scss/builder/variables.scss';
  @import './scss/builder/imports';
}

// TODO: find a way to make it global and apply styles
@import './scss/_echarts.scss';
@import './scss/_intl-tel-input.scss';
