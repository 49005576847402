om-pricing-inbox {
  padding: var(--spacing-medium);

  ag-grid-angular {
    .ag-cell-wrapper.ag-row-group {
      align-items: center;
    }

    .ag-row-position-absolute:not(.ag-row-group) {
      .ag-cell:not(.ag-cell-empty-pricing) {
        .ag-cell-wrapper::before {
          content: '\21B3';
          margin-right: var(--spacing-medium);
        }
      }
    }

    .ag-row-even,
    .ag-row-odd {
      background-color: var(--color-alert-contrast);
    }

    .ag-row.ag-row-level-0 {
      background-color: var(--color-row-even);
      font-weight: bold !important;

      .ag-cell,
      .ag-cell-range-selected {
        background-color: var(--color-even-row) !important;
      }
    }

    .ag-row.ag-row-level-1 {
      background-color: var(--color-row-odd);

      .ag-cell,
      .ag-cell-range-selected {
        background-color: var(--color-odd-row) !important;
      }

      &.ag-row-group-expanded {
        font-weight: bold !important;
      }
    }
  }

  .ag-cell-empty-pricing {
    width: 100% !important;
    z-index: 2;
  }
}
