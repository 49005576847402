.mdc-dialog__container,
.mdc-dialog-container {
  border-radius: 4px;
  position: relative;
  .mat-mdc-dialog-title {
    padding: 0 var(--spacing-modal-side);
    font-size: var(--font-size-x-large);
    font-weight: var(--font-weight-normal);
    .mdc-icon-button {
      position: absolute;
      right: 4px;
      top: 4px;
    }
  }

  .mat-mdc-dialog-content {
    padding: var(--spacing-large) var(--spacing-modal-side) !important;

    > *:first-child {
      margin-top: 0;
    }

    .om-banner {
      width: calc(100% + (var(--spacing-modal-side) * 2));
      margin-left: var(--negative-spacing-modal-side);
      margin-top: var(--negative-spacing-large);
    }

    ol,
    ul {
      padding-left: var(--spacing-small);
      list-style-position: inside;
      font-size: var(--font-size-medium);
      li {
        margin: var(--spacing-medium) 0;
        &::marker {
          color: var(--color-primary);
          font-weight: var(--font-weight-bold);
        }
      }
    }

    > p {
      line-height: 22px;
      font-size: var(--font-size-medium);
    }

    > img {
      display: block;
      margin: var(--spacing-x-large) auto;
      max-width: 100%;
      max-height: 100px;
    }

    > form {
      padding: 8px 0 4px;
      overflow-x: hidden;
    }

    > aside {
      height: fit-content;

      &:not(.is-secondary) {
        section {
          > * {
            margin: var(--spacing-medium) 0;
          }
        }
      }
      section {
        + section {
          margin-top: var(--spacing-x-large);
        }
      }
      strong {
        display: block;
        color: var(--color-primary);
        font-weight: var(--font-weight-bold);
        font-size: var(--font-size-medium);
      }
      p {
        margin: 0;
      }

      &.is-secondary {
        color: var(--color-secondary);
        background: var(--color-background-3);
        border-top: 1px dashed var(--color-stroke);
        margin: 0px var(--negative-spacing-modal-side) -16px;
        padding: var(--spacing-large) var(--spacing-modal-side);

        strong {
          color: var(--color-secondary);
        }

        ol {
          margin: 0;
        }
        li {
          &::marker {
            color: var(--color-secondary);
          }
        }
      }
      &:not(.is-secondary) {
        padding-bottom: var(--spacing-large);
      }
    }
  }

  .mat-mdc-dialog-actions {
    display: flex;
    flex-direction: row;
    border-top: 0.5px solid var(--color-stroke);
    padding: 0;
    min-height: initial;
    .mat-mdc-button-touch-target {
      height: unset;
    }
    .mat-mdc-button-base.mdc-button,
    .mat-mdc-button-base.mdc-button:hover,
    > span {
      color: inherit;
      background: transparent;
      text-transform: uppercase;
      font-weight: var(--font-weight-bold);
      font-size: var(--font-size-small);
      border: none;
      border-radius: initial;
      width: 50%;
      height: 40px;
      margin: 0;
      transition: none;
      flex-grow: 1;

      &:last-child,
      &.is-accent {
        color: var(--color-accent-contrast);
        background: var(--color-accent);
      }
      &.is-alert {
        color: var(--color-alert-contrast);
        background: var(--color-alert);
      }
      &.is-warning {
        color: var(--color-warning-contrast);
        background: var(--color-warning);
      }
    }

    > span {
      .mat-mdc-button-base.mat-mdc-button,
      .mat-mdc-button-base.mat-mdc-button:hover {
        width: 100%;
      }
    }
  }
}

.is-modal {
  &.is-warning {
    .mdc-dialog__container,
    .mdc-dialog-container {
      &::before {
        border-bottom: 2px solid var(--color-warning) !important;
      }

      om-boolean-popup {
        .mat-mdc-dialog-content {
          > img {
            margin-bottom: 2px;
          }

          om-rich-text-editor {
            background-color: transparent;
            color: var(--color-warning);

            slate-editable {
              padding: 0;
              padding-bottom: 10px;
            }
          }
        }
      }
    }
  }

  .mdc-dialog__container,
  .mdc-dialog-container {
    padding-top: 0;
    border-radius: var(--mdc-dialog-container-shape, var(--mdc-shape-medium, 4px));

    &::before {
      content: ' ';
      position: absolute;
      width: 100%;
      left: 0;
      top: 0;
      border-top: 4px solid var(--color-primary);
      border-bottom: 2px solid var(--color-accent);
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    .mat-mdc-dialog-surface {
      background-color: unset;
      > * {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: hidden;

        ::-webkit-scrollbar {
          -webkit-appearance: none;
          width: $scrollbar-width;
          height: $scrollbar-width;
        }
        ::-webkit-scrollbar-thumb {
          border-radius: math.div($scrollbar-width, 2);
          background-color: var(--color-stroke) !important;
        }
      }

      ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: $scrollbar-width;
        height: $scrollbar-width;
      }
      ::-webkit-scrollbar-thumb {
        border-radius: math.div($scrollbar-width, 2);
        background-color: var(--color-stroke) !important;
      }
    }

    .mat-mdc-dialog-title {
      width: 100%;
      line-height: var(--line-height-large);
      box-shadow: var(--box-shadow-underline);
      text-align: center;
      font-size: var(--font-size-large);
      font-weight: var(--font-weight-bold);
      padding: 10px 0 var(--spacing-medium);
      margin: 0;

      &::before {
        content: unset;
      }

      .mdc-icon-button {
        top: 10px;
      }

      .mat-mdc-dialog-subtitle,
      .mat-mdc-dialog-supertitle {
        display: block;
        font-weight: var(--font-weight-normal);
      }
      .mat-mdc-dialog-supertitle {
        margin-bottom: -14px;
      }
      .mat-mdc-dialog-subtitle {
        margin-top: -14px;
      }
    }

    > p {
      text-align: center;
    }

    om-custom-levels-new-curve-modal {
      om-autocomplete-input {
        .mat-mdc-form-field-infix {
          margin-left: -10px;
        }
      }
    }
    .mat-mdc-dialog-content {
      .info-container {
        display: inline-block;
        text-align: center;

        .mat-mdc-button.is-text {
          .mdc-button__label {
            text-decoration: underline;
          }
        }
      }

      om-dynamic-form-field {
        .mat-mdc-chip-grid {
          padding: 0;
        }
        &:last-child {
          > .mat-mdc-form-field {
            margin-bottom: var(--spacing-x-large);
          }
        }
      }
    }

    form {
      > .mat-mdc-form-field:last-child {
        .mat-mdc-text-field-wrapper {
          padding-bottom: 0;
        }
      }

      + p {
        margin-top: 0;
      }
    }

    .mat-mdc-table {
      border: 1px solid var(--color-stroke);
    }
  }

  &.is-modal-fixed-height {
    .mdc-dialog__container,
    .mdc-dialog-container {
      > * {
        display: flex;
        flex-direction: column;
        height: 100%;
        .mat-mdc-dialog-content {
          max-height: unset;
          flex: auto;
          overflow-x: hidden;
        }
      }
    }
  }
}

om-dynamic-form-field,
om-maturities-table {
  .om-readonly-form-field {
    font-size: var(--font-size-medium);
    margin-bottom: var(--spacing-large);
    color: var(--color-primary);
  }
}
