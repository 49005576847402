.mat-mdc-select-panel {
  padding: 0 !important;
  .mat-mdc-option {
    &:last-child {
      &::after {
        content: none;
      }
    }
    label {
      padding-top: 15px;
      padding-bottom: 15px;
      display: flex;
      flex-direction: column;
      .select-cell-option-label {
        line-height: 25px;
      }
      .select-cell-option-description {
        color: var(--color-label);
        white-space: pre-wrap;
        line-height: 13px;
        font-size: var(--font-size-small);
      }
    }
    &::after {
      content: ' ';
      width: calc(100% - 32px);
      position: absolute;
      bottom: 0;
    }
  }
}

om-select-cell-editor {
  .mat-mdc-select {
    margin-top: -2px;
    max-width: 0px;
    &.is-selected-value-hidden {
      max-width: unset;
      .mat-mdc-select-value {
        padding-left: 12px;
        font-size: var(--font-size-small);
      }
      .mat-mdc-select-arrow-wrapper {
        padding-right: 12px;
      }
    }
    .mat-mdc-select-arrow-wrapper {
      margin-left: 4px;
      margin-top: -2px;
    }
  }
}
