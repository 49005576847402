om-s-buttons {
  main {
    display: flex;
    flex-direction: row;
    justify-content: center;
    & > div {
      height: fit-content;
      width: 50%;
      .mat-mdc-button-base {
        margin: var(--spacing-medium);
      }
      &.div-1 {
        background: var(--color-background-2);
        color: var(--color-primary);
      }
      &.div-2 {
        background: var(--color-navigation-2);
        color: var(--color-navigation-contrast);
        h3 {
          color: var(--color-navigation-contrast);
        }
      }
    }
  }
}
