om-trade-working-group-modal {
  mat-form-field {
    width: 100%;
  }

  .working-group__modal-title-members {
    color: var(--color-secondary);
  }

  .mat-mdc-dialog-content .working-group__intro {
    color: var(--color-primary);
    line-height: var(--line-height-x-small);
    font-size: var(--font-size-small);
    text-align: left;
    margin-bottom: 5px;
  }

  .working-group__header {
    display: flex;
    align-items: center;
    margin-bottom: var(--spacing-large);
    margin-top: 24px;

    .working-group__header-title {
      margin-left: var(--spacing-medium);
    }

    img {
      height: 30px;
      width: 30px;
      border-radius: 0;
      margin: 0;
    }

    h3,
    h4 {
      line-height: 1em;
    }
    h3 {
      font-size: var(--font-size-medium);
      margin: 0 0 6px 0;
    }
    h4 {
      font-size: var(--font-size-small);
      font-weight: var(--font-weight-normal);
      margin: 0;
    }

    button {
      margin-left: auto;
      display: inline-flex !important;
    }
  }

  .om-info-card {
    display: flex;
    justify-content: space-between;
    margin-bottom: var(--spacing-medium);

    &--is-selectable {
      cursor: pointer;
      &:hover {
        @include mat.elevation(3);
      }
    }
    &--is-emphasised {
      background-color: var(--color-background-3);
    }
    &--is-disabled {
      cursor: default !important;
    }
  }
}

// todo: where to put this...?
.om-info-card {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--spacing-medium);

  .om-info-card__content {
    h3 {
      font-size: var(--font-size-medium);
      font-weight: var(--font-weight-normal);
      color: var(--color-primary);
      margin: 0 0 6px 0;
    }
    h4 {
      font-size: var(--font-size-small);
      font-weight: var(--font-weight-normal);
      color: var(--color-label);
      margin: 0;
    }
    span {
      border-bottom: 1px dashed;
    }

    .om-info-card__team {
      display: flex;

      .mat-icon {
        margin-right: var(--spacing-small);
        background: var(--color-primary);
        color: var(--color-background-1);
        border-radius: 50%;
        height: 20px;
        width: 21px;

        > svg {
          width: 16px;
          height: 16px;
          margin-top: 2px;
          margin-left: 3px;
        }
      }
    }
  }

  &--is-selected {
    @include mat.elevation(3);
    .mdc-checkbox {
      opacity: 1 !important;
    }
    .om-info-card__content {
      h3 {
        font-weight: var(--font-weight-bold);
      }
    }
  }

  .om-info-card__extra {
    display: flex;
    align-items: center;
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-bold);
    color: var(--color-stroke);

    .mdc-checkbox--disabled {
      opacity: 0.5;
    }
  }
}
