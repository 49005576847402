om-document-generator {
  router-outlet + * {
    height: 1px;
  }
  om-document-generator-toolbar-secondary {
    width: 100%;
  }

  om-document-generator-page-inputs-container {
    display: flex;
    flex-direction: column;
    om-document-generator-page-inputs-edit,
    om-document-generator-page-inputs-readonly {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow-y: auto;
    }
  }
}

@import './components/document-generator-comments-container/document-generator-comments-container.component.scss';
@import './components/document-generator-page-inputs-edit/document-generator-page-inputs-edit.component.scss';
@import './components/document-generator-page-inputs-readonly/document-generator-page-inputs-readonly.component.scss';
@import './components/document-generator-page-document/document-generator-page-document.component.scss';
@import './components/document-generator-document-update/document-generator-document-update.component.scss';
@import './components/document-generator-toolbar-footer/document-generator-toolbar-footer.component.scss';
@import './components/document-generator-toolbar-secondary/document-generator-toolbar-secondary.component.scss';
