.tippy-box {
  .tippy-content {
    padding: var(--spacing-medium);
    border-radius: 4px;
    overflow: hidden;
    white-space: pre-line;
  }
}
.om-tooltip-header {
  padding: 0 16px var(--spacing-medium);
  font-weight: var(--font-weight-bold);
}
.om-tooltip-container {
  margin: var(--negative-spacing-medium);
  padding: 12px 0px;
  .om-tooltip-content {
    white-space: pre-line;
    padding: 0px 16px;
    max-height: 200px;
    overflow: auto;
  }
  .om-tooltip-close-button {
    visibility: hidden;
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    font-size: 12px;
    background: none;
    border: none;
    outline: none;
    padding: 1px 4px;
    color: darkgray;
    cursor: pointer;
    &::before {
      content: '\2715';
    }
  }
  &.om-tooltip-sticky .om-tooltip-close-button {
    visibility: visible;
  }
  &.om-tooltip-fill {
    padding: 0px;
    .om-tooltip-content {
      padding: 0px;
    }
  }
}
.om-tooltip-sources {
  h4 {
    margin: var(--spacing-large) 0 6px 0;
    &:first-child {
      margin-top: 0;
    }
  }
}
