om-ifr-feed-widget {
  display: flex;
  flex-direction: column;
  height: 100%;

  .mat-toolbar {
    justify-content: space-between;
    padding-top: var(--spacing-medium);
    section {
      flex-grow: unset !important;
    }
  }

  article {
    flex-direction: column;
    .mat-mdc-card {
      border: 0;
      display: flex;
      flex-direction: row;

      .mat-mdc-card-title {
        font-size: var(--font-size-medium) !important;
        div {
          text-transform: capitalize;

          a,
          span {
            font-weight: var(--font-weight-normal);
            font-size: var(--font-size-small);
          }
        }
      }

      .mat-mdc-card-subtitle {
        color: var(--color-primary) !important;
      }
    }

    .mat-mdc-card:nth-child(even) {
      background: var(--color-row-odd);
    }
  }

  .om-circle-bullet {
    height: $circle-bullet-height-medium;
    width: $circle-bullet-height-medium;
    min-width: $circle-bullet-height-medium;
    background-color: var(--color-working-group-3);
    border-radius: 50%;
    margin-right: var(--spacing-large);
    margin-top: var(--spacing-small);
  }

  .om-item-date {
    font-weight: var(--font-weight-normal);
    color: var(--color-label);
  }
}
