@use './mixins.scss' as mixin;

$sidebar-box-shadow: inset var(--spacing-small) 0 0 0;

.mat-sidenav,
.tippy-box[data-theme='navigation'] {
  color: var(--color-navigation-contrast);
  background-color: var(--color-navigation-1);

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: $scrollbar-width;
    height: $scrollbar-width;
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--color-secondary) !important;
    opacity: 0.8 !important;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent !important;
  }

  .mat-toolbar {
    color: var(--color-navigation-contrast);
    background: var(--color-navigation-1);
    padding: 0;
  }

  .mdc-list {
    padding: 0;
  }

  .om-section {
    .om-section-header,
    .om-section-item {
      cursor: pointer;
      font-size: var(--font-size-medium);
      .mat-mdc-icon-button {
        font-size: var(--font-size-large);
      }
      &:hover:not(.is-active) {
        background: var(--color-navigation-3) !important;
      }
      .is-suffix {
        margin-right: -6px;
      }
      .is-note {
        font-size: var(--font-size-x-small-uppercase);
        margin-right: var(--spacing-small);
        font-weight: var(--font-weight-bold);
      }
    }
    .om-section-header {
      font-weight: var(--font-weight-bold);
      height: var(--section-header-height);
      padding: var(--spacing-large);
      transition: padding $sidebar-animation-timing;
    }
    .om-section-item {
      padding: var(--spacing-medium) var(--spacing-large) var(--spacing-medium) 48px;
      height: var(--section-item-height);
      min-height: var(--section-item-height);
      max-height: 60px; // 2 lines of text on document generator
      font-weight: var(--font-weight-normal);
      line-height: var(--line-height-medium);
      transition:
        height $sidebar-animation-timing,
        min-height $sidebar-animation-timing,
        max-height $sidebar-animation-timing,
        margin-bottom $sidebar-animation-timing,
        opacity $sidebar-animation-timing,
        padding $sidebar-animation-timing;

      &.is-new {
        .mdc-list-item__primary-text {
          &::after {
            content: 'new';
            color: var(--color-background-2);
            font-size: var(--font-size-small);
            text-transform: uppercase;
            width: 38px;
            height: 20px;
            line-height: var(--line-height-small);
            background: var(--color-accent);
            border-radius: 100px;
            text-align: center;
            padding: 2px;
            margin-left: 12px;
          }
        }
      }

      &.is-complete:not(.is-active) {
        background: linear-gradient(90deg, rgba(67, 190, 156, 0.2) 0%, rgba(67, 190, 156, 0) 100%) !important;
      }

      &.is-create-trade {
        height: 46px;
        padding: 6px 20px;

        .mat-mdc-button {
          width: 100%;
        }
      }
    }
    &.is-active {
      background: linear-gradient(270deg, rgba(7, 36, 60, 0) 0%, #07243c 100%);
      &,
      .om-section-header,
      .om-section-item {
        box-shadow: $sidebar-box-shadow var(--color-accent);
      }
      .om-section-item {
        &.is-active {
          &,
          &:hover {
            background-color: var(--color-background-2) !important;
            .mdc-list-item__primary-text {
              color: var(--color-primary) !important;
            }
          }
        }
      }
    }
  }

  om-sidenav-toggle {
    .mat-mdc-list-item {
      padding-left: 20px;
      .mdc-list-item__content {
        .mdc-list-item__primary-text {
          display: flex;
          align-items: center;
        }
      }
    }
  }

  om-help-center,
  om-sidenav-toggle {
    .mdc-list-item__primary-text {
      white-space: nowrap !important;
    }
    .mat-icon {
      height: 24px;
      min-width: 24px;
    }
  }

  .mat-mdc-list-base,
  .om-section {
    padding-top: 0;
    .mat-mdc-list-item {
      white-space: nowrap;
      color: inherit;

      &:focus {
        &::before {
          all: unset;
        }
      }

      .mdc-list-item__content,
      .mdc-list-item__primary-text {
        color: var(--color-navigation-contrast);
        line-height: inherit;
        font-weight: inherit;

        .mat-mdc-icon-button {
          margin-left: 10px;
          justify-content: right;
          width: 28px;
          height: 28px;

          .mat-mdc-button-touch-target {
            width: inherit;
            height: inherit;
          }
        }
      }
      .mdc-list-item__primary-text {
        display: flex;
        align-items: center;
        justify-content: left;
        white-space: normal;
        line-height: var(--line-height-small);
        > span {
          flex-grow: 1;
        }
        > img {
          max-height: 38px;
          margin-left: 0 !important;
        }
      }
      &.mdc-list-item--disabled {
        background: none;
      }
      &.om-section-header {
        .mdc-list-item__content,
        .mdc-list-item__primary-text {
          .mat-icon {
            height: 24px;
            min-width: 24px;
          }
        }
      }
    }
  }

  .mat-icon:not(.is-alert, .is-warning) {
    color: inherit;
  }
  .mdc-list-item__primary-text,
  .mat-expansion-panel-header-title {
    & > .mat-icon {
      margin-right: 10px;
    }
  }

  .mat-expansion-panel-header-title {
    color: var(--color-navigation-contrast);
  }

  om-sidenav-toggle:hover {
    background: var(--color-navigation-3);
  }
}

.tippy-box[data-theme='navigation'] {
  background: var(--color-navigation-2);
  width: 250px;
  .tippy-arrow {
    color: var(--color-navigation-2);
  }
  .tippy-content {
    padding: 0;
  }

  .mat-mdc-nav-list {
    &.om-section.is-expanded {
      padding-bottom: 6px;
    }

    .mat-expansion-panel-header-title > .mat-icon {
      display: none;
    }
    .mdc-list-item__content,
    .mdc-list-item__primary-text {
      overflow: visible;
    }

    .om-section-header {
      padding-top: 10px !important;
      padding-bottom: 10px !important;
      border-bottom: 1px solid var(--color-primary);

      .mdc-list-item__content,
      .mdc-list-item__primary-text {
        .mat-icon {
          height: 17.5px !important;
          margin-left: 0 !important;
        }
      }
    }

    .om-section-header,
    .om-section-item {
      padding: 6px 6px 6px 40px;
    }
    .om-section-item {
      height: fit-content;
      &.is-active {
        background-color: var(--color-background-2);
        color: var(--color-accent);

        .mdc-list-item__content {
          font-weight: var(--font-weight-semi-bold);
          > span {
            color: var(--color-primary);
          }
        }
      }
      &::before {
        @include mixin.empty-badge();
        left: 20px;
      }
      &.is-alert::before {
        display: block;
        color: var(--color-alert);
      }
      &.is-warning::before {
        display: block;
        color: var(--color-warning);
      }

      &.is-complete {
        span.is-document-complete {
          position: absolute;
          left: 16px;
          top: 50%;
          transform: translate(0%, -50%);
          .mat-icon.is-complete {
            min-width: unset;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background: var(--color-accent);
            color: var(--color-primary);

            svg {
              width: 8px;
              margin-left: 2px;
            }
          }
        }

        &.is-active {
          .mat-icon.is-complete {
            color: var(--color-background-2) !important;
          }
        }
      }
    }
    > mat-list-item {
      pointer-events: none;
    }
  }
}

.mat-sidenav:not(.om-sidebar-mini) {
  om-sidenav-toggle:not(:hover) {
    background: var(--color-navigation-2);
  }
}

.mat-sidenav.om-sidebar-mini {
  .om-section.is-active {
    background: var(--color-navigation-2);
  }

  .om-section-item {
    height: 0 !important;
    min-height: 0;
    max-height: 0;
    margin-bottom: 0;
    opacity: 0;
    padding: 0 !important;

    .mdc-button {
      display: none;
    }
  }
}

.om-wizard-sidenav {
  .mat-toolbar {
    a {
      margin: 0 13px 0 11px;
      width: 100%;
      min-width: 0;
      overflow: hidden;
    }
  }
  &.om-sidebar-mini {
    .mat-toolbar {
      a {
        display: flex;
        justify-content: left;
        padding: 0 12px;
        .mat-icon {
          min-width: 24px;
        }
      }
    }

    .mat-expansion-panel-header {
      padding-left: 20px !important;
    }
  }

  .mat-expansion-panel {
    background-color: transparent;
    box-shadow: none !important;
    border-radius: 0 !important;

    &:not(:first-child) {
      margin: var(--spacing-large) 0;
    }

    transition:
      background-color $sidebar-animation-timing,
      margin $sidebar-animation-timing;

    .om-section-header,
    .om-section-item {
      padding: 0px;
    }
    .om-section-header {
      .om-status {
        font-weight: var(--font-weight-normal);
        .mat-icon {
          font-size: inherit;
        }
      }
    }
    .om-section-item {
      height: fit-content;
      .mdc-list-item__primary-text {
        width: $sidebar-width-open;
      }
    }

    .mat-expansion-panel-header {
      padding: var(--spacing-large) 6px var(--spacing-large) 12px;
      line-height: var(--line-height-medium);
      white-space: nowrap;

      &[aria-disabled='true'] {
        color: currentColor;
      }
      .mat-expansion-panel-header-title {
        color: var(--color-navigation-contrast);
        > .mat-icon {
          color: currentColor;
          padding: 0 2px;
          font-size: 18px;
          min-height: 22px;
        }
      }
      .mat-expansion-indicator {
        position: absolute;
        left: $sidebar-width-open - 24px;
        &::after {
          color: var(--color-secondary);
        }
      }
    }

    .mat-expansion-panel-body {
      padding: 0;

      .mat-mdc-list-base {
        .mat-mdc-list-item {
          position: relative;
          .mdc-list-item__primary-text {
            .mat-mdc-icon-button {
              padding-left: 0;
            }
          }
          .mdc-list-item__primary-text {
            white-space: normal;
            padding: 6px 6px 6px 44px;
            display: flex;
            align-items: center;
            line-height: var(--line-height-small);

            > .mat-icon {
              position: absolute;
              left: 12px;

              &.is-complete {
                background: var(--color-accent);
                color: var(--color-primary);
                border-radius: 50%;
                min-width: 10px;
                width: 12px;
                height: 12px;

                svg {
                  padding: 2px;
                }
              }
            }
          }

          &::before {
            @include mixin.empty-badge();
            left: 20px;
          }
          &.is-alert::before {
            display: block;
            color: var(--color-alert);
          }
          &.is-warning::before {
            display: block;
            color: var(--color-warning);
          }

          &.is-complete {
            span.is-document-complete {
              position: absolute;
              left: 18px;
              top: 50%;
              transform: translate(0%, -50%);
              .mat-icon.is-complete {
                min-width: unset;
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background: var(--color-accent);
                color: var(--color-primary);

                svg {
                  width: 8px;
                  margin-left: 2px;
                }
              }
            }

            &.is-active {
              .mat-icon.is-complete {
                color: var(--color-background-2) !important;
              }
            }
          }

          &.is-complete {
            box-shadow: $sidebar-box-shadow var(--color-accent) !important;
          }
        }
        .om-mat-list-action {
          .mat-mdc-list-item {
            .mdc-list-item__primary-text {
              justify-content: space-between;
              .mat-mdc-icon-button {
                display: flex;
                height: 28px;
                width: 28px;
                margin-left: 10px;

                .mat-mdc-button-touch-target {
                  width: inherit;
                  height: inherit;
                }

                .mat-icon {
                  align-self: center;
                  margin-left: 0;
                  margin-top: -2px;
                }
              }
            }
          }
        }
      }
    }

    &.is-active {
      .om-status {
        color: var(--color-accent);
      }
    }
    &.is-alert {
      &.is-active {
        &,
        .om-section-header,
        .om-section-item {
          box-shadow: $sidebar-box-shadow var(--color-alert);
        }
      }
      .om-status {
        color: var(--color-alert);
      }
    }
    &.is-warning {
      &.is-active {
        &,
        .om-section-header,
        .om-section-item {
          box-shadow: $sidebar-box-shadow var(--color-warning);
        }
      }
      .om-status {
        color: var(--color-warning);
      }
    }
  }
}

.mat-sidenav.om-sidebar-mini {
  .om-mat-list-action {
    display: flex;
  }
}
