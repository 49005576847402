body {
  // https://material.angular.io/guide/typography
  // default https://github.com/angular/components/blob/master/src/material/core/typography/_typography.scss
  @include mat.core();

  $typography: mat.m2-define-typography-config(
    $font-family: $font-family,
    $headline-5:
      mat.m2-define-typography-level(var(--font-size-x-large), var(--line-height-x-large), var(--font-weight-bold)),
    $headline-6:
      mat.m2-define-typography-level(var(--font-size-large), var(--line-height-large), var(--font-weight-bold)),
    $subtitle-1:
      mat.m2-define-typography-level(var(--font-size-large), var(--line-height-large), var(--font-weight-bold)),
    $body-1:
      mat.m2-define-typography-level(var(--font-size-medium), var(--line-height-medium), var(--font-weight-normal)),
    $body-2:
      mat.m2-define-typography-level(var(--font-size-medium), var(--line-height-medium), var(--font-weight-normal)),
    $button:
      mat.m2-define-typography-level(var(--font-size-medium), var(--line-height-medium), var(--font-weight-normal)),
    $caption:
      mat.m2-define-typography-level(var(--font-size-medium), var(--line-height-medium), var(--font-weight-normal)),
  );

  --primary-50: mat.mat-color($palette-primary, 50);
  --primary-100: mat.mat-color($palette-primary, 100);
  --primary-200: mat.mat-color($palette-primary, 200);
  --primary-300: mat.mat-color($palette-primary, 300);
  --primary-400: mat.mat-color($palette-primary, 400);
  --primary-500: mat.mat-color($palette-primary, 500);
  --primary-600: mat.mat-color($palette-primary, 600);
  --primary-700: mat.mat-color($palette-primary, 700);
  --primary-800: mat.mat-color($palette-primary, 800);
  --primary-900: mat.mat-color($palette-primary, 900);
  --primary-A100: mat.mat-color($palette-primary, A100);
  --primary-A200: mat.mat-color($palette-primary, A200);
  --primary-A400: mat.mat-color($palette-primary, A400);
  --primary-A700: mat.mat-color($palette-primary, A700);
  --primary-contrast-50: mat.mat-contrast($palette-primary, 50);
  --primary-contrast-100: mat.mat-contrast($palette-primary, 100);
  --primary-contrast-200: mat.mat-contrast($palette-primary, 200);
  --primary-contrast-300: mat.mat-contrast($palette-primary, 300);
  --primary-contrast-400: mat.mat-contrast($palette-primary, 400);
  --primary-contrast-500: mat.mat-contrast($palette-primary, 500);
  --primary-contrast-600: mat.mat-contrast($palette-primary, 600);
  --primary-contrast-700: mat.mat-contrast($palette-primary, 700);
  --primary-contrast-800: mat.mat-contrast($palette-primary, 800);
  --primary-contrast-900: mat.mat-contrast($palette-primary, 900);
  --primary-contrast-A100: mat.mat-contrast($palette-primary, A100);
  --primary-contrast-A200: mat.mat-contrast($palette-primary, A200);
  --primary-contrast-A400: mat.mat-contrast($palette-primary, A400);
  --primary-contrast-A700: mat.mat-contrast($palette-primary, A700);

  $mat-palette-text-primary: (
    50: var(--primary-50),
    100: var(--primary-100),
    200: var(--primary-200),
    300: var(--primary-300),
    400: var(--primary-400),
    500: var(--primary-500),
    600: var(--primary-600),
    700: var(--primary-700),
    800: var(--primary-800),
    900: var(--primary-900),
    A100: var(--primary-A100),
    A200: var(--primary-A200),
    A400: var(--primary-A400),
    A700: var(--primary-A700),
    contrast: (
      50: var(--primary-contrast-50),
      100: var(--primary-contrast-100),
      200: var(--primary-contrast-200),
      300: var(--primary-contrast-300),
      400: var(--primary-contrast-400),
      500: var(--primary-contrast-500),
      600: var(--primary-contrast-600),
      700: var(--primary-contrast-700),
      800: var(--primary-contrast-800),
      900: var(--primary-contrast-900),
      A100: var(--primary-contrast-A100),
      A200: var(--primary-contrast-A200),
      A400: var(--primary-contrast-A400),
      A700: var(--primary-contrast-A700),
    ),
  );

  --accent-50: mat.mat-color($palette-accent, 50);
  --accent-100: mat.mat-color($palette-accent, 100);
  --accent-200: mat.mat-color($palette-accent, 200);
  --accent-300: mat.mat-color($palette-accent, 300);
  --accent-400: mat.mat-color($palette-accent, 400);
  --accent-500: mat.mat-color($palette-accent, 500);
  --accent-600: mat.mat-color($palette-accent, 600);
  --accent-700: mat.mat-color($palette-accent, 700);
  --accent-800: mat.mat-color($palette-accent, 800);
  --accent-900: mat.mat-color($palette-accent, 900);
  --accent-A100: mat.mat-color($palette-accent, A100);
  --accent-A200: mat.mat-color($palette-accent, A200);
  --accent-A400: mat.mat-color($palette-accent, A400);
  --accent-A700: mat.mat-color($palette-accent, A700);
  --accent-contrast-50: mat.mat-contrast($palette-accent, 50);
  --accent-contrast-100: mat.mat-contrast($palette-accent, 100);
  --accent-contrast-200: mat.mat-contrast($palette-accent, 200);
  --accent-contrast-300: mat.mat-contrast($palette-accent, 300);
  --accent-contrast-400: mat.mat-contrast($palette-accent, 400);
  --accent-contrast-500: mat.mat-contrast($palette-accent, 500);
  --accent-contrast-600: mat.mat-contrast($palette-accent, 600);
  --accent-contrast-700: mat.mat-contrast($palette-accent, 700);
  --accent-contrast-800: mat.mat-contrast($palette-accent, 800);
  --accent-contrast-900: mat.mat-contrast($palette-accent, 900);
  --accent-contrast-A100: mat.mat-contrast($palette-accent, A100);
  --accent-contrast-A200: mat.mat-contrast($palette-accent, A200);
  --accent-contrast-A400: mat.mat-contrast($palette-accent, A400);
  --accent-contrast-A700: mat.mat-contrast($palette-accent, A700);

  $mat-palette-accent: (
    50: var(--accent-50),
    100: var(--accent-100),
    200: var(--accent-200),
    300: var(--accent-300),
    400: var(--accent-400),
    500: var(--accent-500),
    600: var(--accent-600),
    700: var(--accent-700),
    800: var(--accent-800),
    900: var(--accent-900),
    A100: var(--accent-A100),
    A200: var(--accent-A200),
    A400: var(--accent-A400),
    A700: var(--accent-A700),
    contrast: (
      50: var(--accent-contrast-50),
      100: var(--accent-contrast-100),
      200: var(--accent-contrast-200),
      300: var(--accent-contrast-300),
      400: var(--accent-contrast-400),
      500: var(--accent-contrast-500),
      600: var(--accent-contrast-600),
      700: var(--accent-contrast-700),
      800: var(--accent-contrast-800),
      900: var(--accent-contrast-900),
      A100: var(--accent-contrast-A100),
      A200: var(--accent-contrast-A200),
      A400: var(--accent-contrast-A400),
      A700: var(--accent-contrast-A700),
    ),
  );

  --warning-50: mat.mat-color($palette-warning, 50);
  --warning-100: mat.mat-color($palette-warning, 100);
  --warning-200: mat.mat-color($palette-warning, 200);
  --warning-300: mat.mat-color($palette-warning, 300);
  --warning-400: mat.mat-color($palette-warning, 400);
  --warning-500: mat.mat-color($palette-warning, 500);
  --warning-600: mat.mat-color($palette-warning, 600);
  --warning-700: mat.mat-color($palette-warning, 700);
  --warning-800: mat.mat-color($palette-warning, 800);
  --warning-900: mat.mat-color($palette-warning, 900);
  --warning-A100: mat.mat-color($palette-warning, A100);
  --warning-A200: mat.mat-color($palette-warning, A200);
  --warning-A400: mat.mat-color($palette-warning, A400);
  --warning-A700: mat.mat-color($palette-warning, A700);
  --warning-contrast-50: mat.mat-contrast($palette-warning, 50);
  --warning-contrast-100: mat.mat-contrast($palette-warning, 100);
  --warning-contrast-200: mat.mat-contrast($palette-warning, 200);
  --warning-contrast-300: mat.mat-contrast($palette-warning, 300);
  --warning-contrast-400: mat.mat-contrast($palette-warning, 400);
  --warning-contrast-500: mat.mat-contrast($palette-warning, 500);
  --warning-contrast-600: mat.mat-contrast($palette-warning, 600);
  --warning-contrast-700: mat.mat-contrast($palette-warning, 700);
  --warning-contrast-800: mat.mat-contrast($palette-warning, 800);
  --warning-contrast-900: mat.mat-contrast($palette-warning, 900);
  --warning-contrast-A100: mat.mat-contrast($palette-warning, A100);
  --warning-contrast-A200: mat.mat-contrast($palette-warning, A200);
  --warning-contrast-A400: mat.mat-contrast($palette-warning, A400);
  --warning-contrast-A700: mat.mat-contrast($palette-warning, A700);

  $mat-palette-warning: (
    50: var(--warning-50),
    100: var(--warning-100),
    200: var(--warning-200),
    300: var(--warning-300),
    400: var(--warning-400),
    500: var(--warning-500),
    600: var(--warning-600),
    700: var(--warning-700),
    800: var(--warning-800),
    900: var(--warning-900),
    A100: var(--warning-A100),
    A200: var(--warning-A200),
    A400: var(--warning-A400),
    A700: var(--warning-A700),
    contrast: (
      50: var(--warning-contrast-50),
      100: var(--warning-contrast-100),
      200: var(--warning-contrast-200),
      300: var(--warning-contrast-300),
      400: var(--warning-contrast-400),
      500: var(--warning-contrast-500),
      600: var(--warning-contrast-600),
      700: var(--warning-contrast-700),
      800: var(--warning-contrast-800),
      900: var(--warning-contrast-900),
      A100: var(--warning-contrast-A100),
      A200: var(--warning-contrast-A200),
      A400: var(--warning-contrast-A400),
      A700: var(--warning-contrast-A700),
    ),
  );

  $mat-theme: mat.m2-define-light-theme(
    (
      color: (
        primary: mat.m2-define-palette($mat-palette-text-primary),
        accent: mat.m2-define-palette($mat-palette-accent),
        warning: mat.m2-define-palette($mat-palette-warning),
      ),
      typography: $typography,
    )
  );

  $background: (
    border-colour: #{var(--color-stroke)},
  );

  $foreground: (
    divider: #{var(--color-stroke)},
    dividers: #{var(--color-stroke)},
    border-colour: #{var(--color-stroke)},
  );

  $mat-theme-color: map-get($mat-theme, color);
  $mat-theme-background: map-get($mat-theme-color, background);
  $mat-theme-background: map_merge($mat-theme-background, $background);

  $mat-theme-foreground: map-get($mat-theme-color, foreground);
  $mat-theme-foreground: map_merge($mat-theme-foreground, $foreground);

  $mat-theme-color: map-merge(
    $mat-theme-color,
    (
      background: $mat-theme-background,
      foreground: $mat-theme-foreground,
    )
  );
  $mat-theme: map-merge(
    $mat-theme,
    (
      color: $mat-theme-color,
      background: $mat-theme-background,
      foreground: $mat-theme-foreground,
    )
  );

  @include mat.all-component-themes($mat-theme);

  & {
    --mdc-theme-text-primary-on-background: var(--color-primary);
    --mat-toolbar-container-text-color: var(--color-primary);
    --mdc-typography-button-letter-spacing: 0.0125em;
    --mdc-shape-small: var(--border-radius);
    --mdc-list-list-item-one-line-container-height: var(--section-header-height);
    --mat-list-active-indicator-shape: 0;
    --mdc-dialog-subhead-color: var(--color-primary);
    --mdc-dialog-supporting-text-color: var(--color-primary);
    --mdc-dialog-container-shape: 4px;
    --mdc-switch-track-width: 36px;
    --mdc-switch-track-height: 20px;
    --mdc-switch-track-shape: 10px;
    --mdc-switch-handle-width: calc(var(--mdc-switch-track-width) - 16px); // 100% - value
    --mdc-switch-handle-height: 16px;
    --mdc-switch-handle-shape: 10px;
    --mdc-switch-selected-icon-color: var(--color-primary);
    --mdc-snackbar-container-color: inherit;
    --mdc-snackbar-container-shape: var(--border-radius);
    --mdc-snackbar-supporting-text-color: var(--color-primary);
    --mdc-linear-progress-track-height: 30px;
    --mdc-linear-progress-active-indicator-height: 30px;
  }
}
