om-issuer-selector {
  section,
  mat-form-field {
    width: 100%;
  }

  section {
    padding: 12px 2px;
  }

  om-autocomplete-input {
    .mat-mdc-form-field {
      .mat-mdc-form-field-infix {
        padding-top: 2px;
        padding-bottom: 2px;
      }
    }
  }
}
