om-issuer-profile-entities-overview-widget {
  om-issuer-profile-entities-overview-widget-slide {
    > div {
      display: flex;
      h4 {
        display: inline-block;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      img {
        margin-left: var(--spacing-medium);
        margin-top: -2px;
        height: 20px;
        vertical-align: sub;
        outline: var(--color-stroke) 1px solid;
      }
    }
    .om-basic-list {
      margin-bottom: var(--spacing-large);
    }
    om-issuer-profile-entities-ratings-widget {
      .mat-column-moodys,
      .mat-column-sp,
      .mat-column-fitch,
      .mat-column-scope {
        padding: var(--spacing-small);
        text-align: center;
        .om-tag {
          float: unset !important;
        }
      }
    }
  }
}
