om-document-download-panel {
  display: block;
  overflow: inherit !important;
  padding: 20px var(--spacing-xl-large);

  background-color: var(--color-row-odd);
  border: 1px solid var(--color-stroke);
  border-radius: 0 0 var(--border-radius-small) var(--border-radius-small);

  .om-document-download-panel__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .om-document-download-panel__heading {
    display: flex;
    align-items: center;
    margin-top: 4px;
    .mat-icon {
      margin-right: var(--spacing-medium);
    }
    h3 {
      margin: 0;
      font-weight: var(--font-weight-normal);
    }
  }
  .om-document-download-panel__actions {
    position: relative;
  }
}
