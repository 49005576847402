om-callable-search {
  > .mat-toolbar {
    section,
    mat-form-field {
      width: 100%;
    }
    section {
      padding-top: var(--spacing-large);
      padding-bottom: 12px;
      padding-right: var(--spacing-medium);
      display: flex;
      flex-basis: fit-content;
      > .mat-mdc-icon-button {
        font-size: var(--font-size-x-large);
        padding-bottom: var(--spacing-small);
      }
    }

    om-autocomplete-input {
      .mat-mdc-form-field {
        .mat-mdc-input-element {
          padding-top: var(--spacing-medium);
          padding-bottom: var(--spacing-medium);
        }
        .mat-mdc-form-field-infix {
          padding-top: var(--spacing-small);
          padding-bottom: var(--spacing-small);
        }
      }
    }
  }

  .empty-template {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h2,
    p {
      display: flex;
      justify-content: center;
    }

    p {
      width: 400px;
      text-align: center;
    }
    img {
      width: 400px;
    }
  }

  .ag-pinned-left-header,
  .ag-pinned-left-cols-container .ag-row .ag-cell:last-of-type {
    .ag-header-group-text {
      display: block !important;
    }
  }
}
