om-issuer-profile-about-tab {
  om-issuer-profile-trade-preferences-widget {
    flex-grow: 0 !important;
    flex-shrink: 0 !important;
    width: 280px !important;
  }
  om-issuer-profile-description-widget,
  om-issuer-profile-trade-preferences-widget {
    min-height: 100%;
  }
}
