om-rich-text-input {
  .rich-text-input-footer {
    display: flex;
    height: 24px;
    background-color: var(--color-row-odd);

    border-top: 1px solid var(--color-background-1);
    border-radius: 0 0 5px 5px;
    margin-bottom: -3px;

    om-select-input {
      margin: 0px;
    }

    .mat-mdc-select-arrow-wrapper {
      margin-top: -2px;
    }

    .mat-mdc-form-field {
      margin: 0;
      width: calc(100% + 8px);
      .mat-mdc-form-field-infix {
        border-top: 0;
        padding: 0px 9px !important;
        width: auto;
        .mat-mdc-select {
          padding: 0 !important;
        }
      }
    }
    .mdc-notched-outline .mat-mdc-form-field-flex {
      margin-top: 0;
    }
  }
  om-rich-text-editor {
    padding: 5px 8px;
    slate-editable {
      background: transparent;
      min-height: auto;
      max-height: 272px;
      overflow: auto;
    }
  }

  &.is-read-only {
    om-rich-text-editor {
      padding: 0;
    }
  }

  &.is-full {
    om-rich-text-editor {
      slate-editable {
        padding: var(--spacing-large);
        min-height: 100px;
      }
    }
  }
}
