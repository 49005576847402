om-button-toggle-input {
  .mat-button-toggle-group {
    display: flex;
    justify-content: space-between;
    margin: 4px 1px -2px 1px;
    padding: 2px 0px;
    border: none;
    .mat-button-toggle {
      width: 49%;
      height: 38px;
      font-size: var(--font-size-medium-uppercase);
      background-color: transparent;
      border: 1px solid var(--color-stroke);
      border-radius: var(--border-radius);
      &.mat-button-toggle-checked {
        border: 1px solid var(--color-accent);
        background: var(--color-accent-background);
      }
      &.mat-button-toggle-disabled {
        pointer-events: none;
        color: inherit;
        opacity: var(--disabled-opacity);
      }
      .mat-button-toggle-label-content {
        font-size: var(--font-size-medium);
        line-height: 38px;
        > .mat-pseudo-checkbox {
          display: none;
        }
      }
      .mat-ripple {
        border-radius: var(--border-radius-small);
      }
    }
  }
}

om-button-toggle-input.is-chip {
  &,
  * {
    height: var(--button-height);
    line-height: var(--button-height);
  }
  .mat-button-toggle-group {
    border: none;
    margin: -1px;
    padding: 0;
  }
  .mat-button-toggle {
    border: 1px solid var(--color-stroke);
    width: fit-content;
    height: var(--button-height);
    .mat-button-toggle-button {
      height: calc(var(--button-height) - 2px);
    }
    &:first-child {
      &,
      * {
        border-bottom-left-radius: var(--button-height);
        border-top-left-radius: var(--button-height);
      }
    }
    &:last-child {
      &,
      .mat-button-toggle-button {
        border-bottom-right-radius: var(--button-height);
        border-top-right-radius: var(--button-height);
      }
    }
    &.mat-button-toggle-checked {
      box-shadow: inset 0px 0px 0px 1px var(--color-accent);
      .mat-button-toggle-button {
        box-shadow: 0px 0px 0px 1px var(--color-accent);
        font-weight: var(--font-weight-bold);
      }
    }
    .mat-button-toggle-label-content {
      line-height: var(--button-height);
    }
  }
}

om-button-toggle-input.variant-2 {
  .mat-button-toggle-group {
    font-weight: var(--font-weight-normal);
    color: var(--color-primary);
    border: 2px solid var(--color-background-2);
    border-radius: 2px;
    display: block;
    height: 14px;
    background: var(--color-background-2);
    .mat-button-toggle {
      border: none;
      border-radius: 2px;
      box-shadow: none;
      padding-top: 1px;

      .mat-button-toggle-button {
        width: fit-content;
        height: 100%;
        padding: 0 4px;
        display: inline-block;
        cursor: pointer;
      }

      span {
        display: inline-block;
        line-height: var(--font-size-small-uppercase);
        font-size: var(--font-size-small-uppercase);
      }

      &.mat-button-toggle-checked {
        color: var(--color-background-2);
        background: var(--color-accent-soft);
      }
    }
  }
}

om-button-toggle-input.is-dynamic-modal-variant {
  .mat-button-toggle-group {
    .mat-button-toggle {
      height: 74px;
      padding: var(--spacing-medium) var(--spacing-large);
      .mat-button-toggle-label-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        line-height: var(--line-height-small);

        .mat-icon {
          height: 36px;
          width: 36px;
        }
      }
    }

    .beta-flag {
      position: absolute;
      top: 2px;
      right: 0;
      width: 58px;
      height: 35px;
      background-color: var(--color-accent);
      border-top-right-radius: 5px;
      clip-path: polygon(100% 0, 100% 100%, 0 0);
      box-shadow: inset 1px -1px 4px 0px #00000040;
    }

    .beta-flag-text {
      position: absolute;
      right: var(--spacing-small);
      top: 6px;
      color: var(--color-background-2);
      font-size: var(--font-size-small);
      font-weight: var(--font-weight-normal);
      line-height: 12px;
    }
  }
}
