om-user-widget {
  .mat-toolbar {
    padding: var(--spacing-xl-large);
    padding-bottom: 0;
    min-height: 65px;
    border-bottom: 0 !important;
    section {
      h3 {
        font-weight: var(--font-weight-normal);
        white-space: normal;
        font-size: var(--font-size-large);
        line-height: 20px;
      }
    }
  }

  article {
    min-height: 100px;
  }

  &.om-widget-available-licenses,
  &.om-widget-active-users {
    background-color: var(--color-background-2);
    flex-grow: 1;

    article {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: var(--font-size-medium);
      padding: var(--spacing-xl-large);
      padding-top: 0;

      > section:first-of-type {
        display: flex;
        align-items: center;
        padding-right: var(--spacing-xl-large);

        > mat-icon {
          margin-right: 10px;
          min-width: 50px;
          min-height: 50px;
        }

        > span {
          text-align: left;
        }
      }

      > section:last-of-type {
        display: flex;
        align-items: center;

        > span {
          font-weight: 500;

          &:first-child {
            font-size: 32px;
            margin-right: 10px;
          }

          &:last-child {
            font-size: var(--font-size-small);
          }
        }
      }
    }
  }

  &.om-widget-available-licenses {
    margin-right: 20px;
  }
}
