om-level-inputs-widget {
  .mat-toolbar {
    padding: 20px 20px 0 20px;
    min-height: 65px;
    border-bottom: 0 !important;
    section {
      h3 {
        font-weight: var(--font-weight-normal);
        white-space: normal;
        font-size: 16px;
        line-height: 20px;
      }
      img {
        height: 100%;
        min-height: 50px;
      }
      div {
        line-height: var(--font-size-medium);
      }
    }
  }

  div[ng-reflect-router-link] {
    cursor: pointer;
  }

  article {
    min-height: 100px;
  }

  @mixin articlePadded() {
    padding: 20px;
  }

  @mixin articleFlex() {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  @mixin articleCentered() {
    text-align: center;
  }

  &.om-widget-email {
    article {
      @include articleFlex();
      @include articlePadded();
      @include articleCentered();
      > * {
        flex-grow: 1;
      }
      > strong {
        flex-grow: 3;
        font-size: 60px;
        font-weight: 100;
        line-height: normal;
      }
      > p {
        margin: 0;
      }
      > p:last-child {
        margin-top: var(--spacing-small);
      }
    }
    button {
      margin: auto;
      margin-bottom: 20px;
    }
  }

  &.om-widget-biggest-fan,
  &.om-widget-fastest-email,
  &.om-widget-user-search {
    article {
      @include articleFlex();
      @include articlePadded();
      > div {
        flex-grow: 1;
        display: flex;
        align-items: center;
        &:not(:last-child) {
          border-bottom: 1px solid var(--color-stroke);
        }
        section:first-child {
          img {
            height: 40px;
            margin-right: 20px;
          }
          font-size: 14px;
          flex-grow: 1;
          display: flex;
          align-items: center;
        }
        section:last-child {
          font-size: var(--font-size-small);
          span {
            &:first-child {
              font-size: 32px;
            }
            &.is-label {
              font-weight: var(--font-weight-bold);
            }
          }
        }
      }
      > div:first-child section:last-child span:first-child {
        color: var(--color-accent);
      }
    }
  }

  &.om-widget-chart-base {
    article {
      @include articleFlex();
      > div {
        flex-grow: 1;
        height: 100%;
        min-height: 150px;
      }
    }
  }

  &.om-widget-dealer-access,
  &.om-widget-dealer-potential {
    article {
      display: flex;
      flex-grow: 1;
      flex-wrap: wrap-reverse;
      grid-gap: 1px 1px;
      > div {
        flex-grow: 1;
        min-width: 32%;
        position: relative;
        > span {
          position: absolute;
          background: linear-gradient(transparent, var(--color-primary) var(--spacing-large));
          width: 100%;
          bottom: 0;
          left: 0;
          padding: calc(var(--spacing-medium) * 2) var(--spacing-medium) var(--spacing-medium) var(--spacing-medium);
          color: var(--color-background-2);
          font-size: var(--font-size-large);
          text-overflow: ellipsis;
          visibility: hidden;
          opacity: 0;
          transition: visibility 0s, opacity 0.5s linear;
        }
        > img {
          position: absolute;
          max-height: 100%;
          max-width: 100%;
          margin-left: auto;
          margin-right: auto;
          left: 0;
          right: 0;
        }
      }
      > div:hover {
        > span {
          visibility: visible;
          opacity: 1;
        }
      }
    }
    > button {
      margin: auto;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .mat-toolbar:last-child {
      padding-bottom: 20px;
    }
  }

  &.om-widget-placeholder {
    article {
      @include articleFlex();
      @include articlePadded();
      @include articleCentered();

      font-size: var(--font-size-large);
    }
  }
}
