om-issuer-list-sidebar {
  padding-right: 0;
  width: 400px;
  height: 100%;
  h2,
  h3 {
    padding: 0;
    margin: 0;
  }
  > aside {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;

    span {
      text-align: center;
      font-size: var(--font-size-medium-uppercase);
    }

    > * {
      margin-bottom: 5px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .mat-mdc-card {
    .mat-mdc-card-content {
      font-size: var(--font-size-medium);
      margin-top: var(--spacing-large);
    }
  }
}
