om-issuer-list {
  .mat-drawer-content {
    padding-right: 0px;
  }
  .mat-toolbar {
    section[left] {
      width: 80%;
      padding: var(--spacing-medium);
    }
  }
}
