om-document-generator-document-update {
  .footer {
    padding: 16px;
    margin-top: auto;

    button {
      width: 100%;
    }
  }

  .control-panel {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: normal;
    align-content: normal;
    height: 100%;
    width: 100%;
  }

  .control-panel__content {
    display: block;
    flex-basis: auto;
    align-self: auto;
    order: 0;
    overflow: auto;
    flex-grow: 2;
    flex-shrink: 2;

    form {
      height: 100%;
    }
  }

  .control-panel__control {
    z-index: 5;
    display: flex;
    flex-direction: column;
    flex-basis: auto;
    flex-shrink: 0;
    flex-grow: 0;
    align-self: auto;
    order: 0;
    overflow: auto;

    padding: 16px;

    box-shadow: -4px -4px 40px 0px #00000014;

    button {
      width: 100%;

      .mat-mdc-button-touch-target {
        height: 100%;
      }
    }
  }

  .no-edits {
    margin: 83px auto 0 auto;
    text-align: center;
    font-size: var(--font-size-large);
  }
}
