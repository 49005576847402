om-email-viewer {
  iframe {
    width: 100%;
    height: 100%;
  }
  mat-chip {
    border: 1px solid var(--color-primary);
    background-color: inherit !important;
    a {
      color: var(--color-primary);
      font-weight: var(--font-weight-bold);
    }
  }
}
