@import '../../../node_modules/intl-tel-input/build/css/intlTelInput.min.css';

.om-morpho {
  --iti-path-flags-1x: url('#{$assets}intl-tel-input/flags.webp') !important;
  --iti-path-flags-2x: url('#{$assets}intl-tel-input/flags@2x.webp') !important;
  --iti-path-globe-1x: url('#{$assets}intl-tel-input/globe.webp') !important;
  --iti-path-globe-2x: url('#{$assets}intl-tel-input/globe@2x.webp') !important;
}

.iti__dropdown-content {
  position: absolute;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 256px;
  box-shadow: var(--mat-autocomplete-container-elevation-shadow);
  .iti__search-input:focus-visible {
    outline: none !important;
  }

  .iti__search-input {
    padding: 12px;
    border-bottom: 1px solid var(--color-stroke);
  }

  .iti__country-list {
    padding-left: 0 !important;

    .iti__country {
      padding: 10px 12px !important;
      border-bottom: 1px solid var(--color-stroke);
      margin: 0 !important;
    }
  }
}
