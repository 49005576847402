om-s-icons {
  section {
    max-width: 1280px;
    > * {
      margin: var(--spacing-small) var(--spacing-medium);
    }
    p {
      margin-bottom: var(--spacing-medium);
    }
  }
}
