om-line-chart {
  width: 100%;
  display: block;

  & > div {
    display: flex;
    height: inherit;
    > p {
      align-self: center;
      text-align: center;
      margin-top: -20px;
      width: 100%;
    }
    .om-chart-container {
      width: 100%;
    }

    .om-exportable-chart {
      width: 1000px;
      height: 600px;
      position: absolute;
      visibility: hidden;
    }

    om-chart-legend {
      min-width: 250px;
      width: 25%;
      height: 100%;
      overflow-y: auto;
    }
  }

  .om-chart-loading-container {
    height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: white;
    width: 100%;
    height: 100%;
    z-index: 1;

    p,
    strong {
      height: 30px;
      line-height: 30px;
      margin: var(--spacing-medium);
    }

    .mat-mdc-progress-bar {
      height: var(--spacing-xl-large);
      .mdc-linear-progress__bar-inner {
        border-color: var(--color-accent);
        border-width: var(--spacing-xl-large);
      }
      .mdc-linear-progress__buffer {
        background-color: var(--color-accent-soft-background);
      }
    }

    mat-spinner {
      margin: 0;
    }
  }

  .om-chart-hidden {
    visibility: hidden;
    height: 0;
  }
}
