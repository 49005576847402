om-trade-timeline {
  > .mat-toolbar {
    height: 100px;
    section {
      &::before {
        display: none !important;
      }

      &:nth-child(2) {
        align-items: center;
        flex-direction: row;
        height: fit-content;
        flex-wrap: wrap;
        padding-left: var(--spacing-medium);
        span {
          margin: 0;
          line-height: 1.5em;
          &:first-child {
            font-size: var(--font-size-x-large);
            font-weight: var(--font-weight-bold);
          }
          &:last-child {
            color: var(--color-label);
            font-weight: var(--font-weight-normal);
            width: 100%;
          }
        }
      }
    }
  }
  .mat-toolbar {
    .doc-vault-btn {
      .doc-count {
        background-color: rgb(209, 218, 221, 0.2);
        width: 25px;
        height: 25px;
        border-radius: 50%;
        margin-left: 10px;
        margin-right: -4px;

        font-size: 14px;

        display: inline-flex;
        align-items: center;
        justify-content: center;
      }

      &.is-active,
      &:hover {
        .doc-count {
          background-color: rgb(209, 218, 221, 0.8);
        }
      }
    }
  }
}
