@use './mixins.scss' as mixin;

.mat-expansion-panel-header-title,
.mat-expansion-panel-header-description {
  align-items: initial;
}

.mat-mdc-table.om-accordion-table {
  .mat-mdc-row {
    background: var(--color-background-2);
    &.is-odd {
      background: var(--color-row-odd);
    }
    &:hover {
      background: var(--color-background-3);
    }
    .mat-mdc-cell {
      &:not(:last-child) {
        padding-right: var(--spacing-large);
      }
    }
    &.om-panel-header {
      cursor: pointer;
      .mat-mdc-cell:first-child {
        padding-left: 46px;
        position: relative;
        &::before,
        &::after {
          content: '|';
          position: absolute;
          left: 0;
          width: 5px;
          height: 5px;
          line-height: 6px;
          padding: 4px 3px 4px 5px;
          border: 1px solid;
          border-radius: 4px;
          font-weight: var(--font-weight-bold);
          margin: 3px 0px 0px var(--spacing-large);
        }
        &::before {
          transform: rotate(0.25turn);
        }
        &::after {
          border-color: transparent;
          transition: transform $transition-timing;
        }
      }
      &.is-expanded {
        .mat-mdc-cell:first-child::after {
          transform: rotate(0.25turn);
        }
      }
    }
    &.om-panel-content {
      transition:
        line-height $transition-timing,
        height $transition-timing,
        opacity $transition-timing;
      line-height: 0;
      height: 0;
      opacity: 0;
      .mat-mdc-cell {
        transition: border-width $transition-timing;
        border-width: 0;
        &:first-child {
          padding-left: calc(46px + var(--spacing-large));
        }
        &:not(:first-child) {
          padding-left: var(--spacing-large);
        }
      }
      &.is-expanded {
        line-height: normal;
        height: 48px;
        opacity: 1;
        .mat-mdc-cell {
          border-width: 1px;
        }
      }
    }
  }
}

.mat-accordion {
  &:not(.is-expansion-toggle-disabled) {
    .mat-expansion-panel-header {
      &.cdk-keyboard-focused,
      &.cdk-program-focused,
      &:hover {
        &:not([aria-disabled='true']) {
          background: var(--color-background-3);
        }
      }
    }
  }

  .mat-expansion-panel-header-description {
    font-size: var(--font-size-medium);
  }

  &.is-expansion-toggle-disabled {
    .mat-expansion-panel-header {
      pointer-events: none;
      om-dynamic-action,
      .mat-mdc-button-base {
        pointer-events: initial;
      }
      .mat-expansion-indicator {
        display: none;
      }
    }
  }
  &:not(.is-expansion-toggle-disabled) {
    .mat-expansion-panel-header {
      .mat-content {
        > .mat-mdc-button:last-child,
        > om-dynamic-action:last-child {
          margin-right: calc(2 * var(--spacing-large));
          align-self: center;
          &::after {
            content: ' ';
            background: var(--color-stroke);
            position: absolute;
            width: 1px;
            right: -16px;
            top: 0;
            height: var(--button-height);
          }
        }
      }
    }
  }

  .mat-content.mat-content-hide-toggle {
    margin-right: 6px;
  }
}

.mat-mdc-autocomplete-panel {
  padding: 0 !important;

  &.om-version-history {
    width: 420px;
    max-height: 500px;
  }

  .mat-mdc-option.is-sticky {
    position: sticky;
    bottom: 0;
    opacity: 1;
    z-index: 99;
    background-color: var(--color-special);
    border-top: 1px solid var(--color-stroke);
    .mdc-list-item__primary-text {
      > div {
        :first-child {
          margin-right: var(--spacing-small);
          display: inline;
          font-weight: var(--font-weight-bold);
        }
        :last-child {
          display: inline;
          font-size: var(--font-size-medium);
          font-weight: var(--font-weight-normal);
          color: var(--color-primary);
        }
      }
    }
  }

  .mat-mdc-option {
    .mat-mdc-chip {
      min-height: unset;
      border-radius: 4px;
      padding: 5px 8px 3px 8px;
      margin-left: var(--spacing-large);
      .mdc-evolution-chip__action {
        padding: 0 !important;
        .mdc-evolution-chip__text-label {
          font-size: var(--font-size-small);
          font-weight: 500;
        }
      }
      &.is-alert {
        background: var(--color-alert-background) !important;
        .mdc-evolution-chip__text-label {
          color: var(--color-alert) !important;
        }
      }
      &.is-secondary {
        background: var(--color-accent-soft-background) !important;
        .mdc-evolution-chip__text-label {
          color: var(--color-label) !important;
        }
      }
    }
    .mat-mdc-checkbox {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      .mdc-form-field {
        width: inherit;
        height: inherit;
        .mdc-checkbox {
          position: absolute;
          width: inherit;
          height: inherit;
          > .mat-mdc-checkbox-touch-target,
          > input {
            height: 100% !important;
            width: 100% !important;
          }
          > .mdc-checkbox__background {
            position: absolute;
            top: 50% !important;
            left: unset !important;
            right: var(--spacing-large);
            transform: translateY(-50%);
          }
          > .mdc-checkbox__ripple {
            display: none;
          }
        }
      }
    }

    &.has-no-options {
      .mdc-list-item__primary-text {
        justify-content: center;
      }
    }

    &.has-nested-children.mdc-list-item--disabled {
      opacity: 1;

      .mat-mdc-checkbox {
        .mdc-checkbox {
          opacity: 0.4;
        }
      }
    }
  }
}

.mat-mdc-option {
  min-height: unset;
  height: auto !important;
  padding: 10px 16px;
  .mdc-list-item__primary-text {
    font-size: var(--font-size-medium);
    color: var(--color-primary);
    display: flex;
    align-items: center;
    flex-grow: 1;
    white-space: initial;
    line-height: 1.4em;
    img:not(.is-transparent) {
      margin-right: 10px;
      max-width: 48px;
      max-height: 48px;
    }
    .is-transparent {
      margin-left: var(--spacing-small);

      &:first-of-type {
        margin-left: 6px;
      }
    }

    .icon-tags-container {
      display: flex;
      justify-content: flex-end;
    }

    div {
      flex-grow: 1;
      span:nth-child(2) {
        display: block;
        color: var(--color-label);
        font-size: var(--font-size-small);
      }
    }

    &:hover {
      &,
      + .mat-mdc-option {
        .mdc-list-item__primary-text {
          margin: 0;
          padding: 0 var(--spacing-large);
        }
      }
    }
  }

  &:not(:first-child) {
    border-top: 1px solid var(--color-stroke);
    &.is-multi-select {
      border-top: none !important;
      .mat-mdc-checkbox {
        border-top: 1px solid var(--color-stroke);
      }
    }
  }

  .mat-mdc-chip-set {
    .mdc-evolution-chip-set__chips {
      justify-content: flex-end;
      .mat-mdc-chip {
        width: max-content;
        .mdc-evolution-chip__cell {
          justify-content: center;
        }
      }
    }
  }
}

.mat-mdc-optgroup {
  .mat-mdc-optgroup-label {
    background-color: var(--color-stroke);
    line-height: var(--line-height-small);
    color: var(--color-label);
    padding-top: 4px;
    min-height: 36px;
    border-bottom: 1px solid var(--color-stroke);
    .mdc-list-item__primary-text {
      color: var(--color-primary);
      font-size: var(--font-size-medium);
      font-weight: var(--font-weight-bold);
      .mat-mdc-checkbox {
        display: flex;
        align-items: center;
        position: absolute;
        right: 16px;
        top: 3px;
        width: 88px;
        height: 32px;
        .mdc-checkbox {
          position: absolute;
          right: 0;
        }
      }
    }
  }
  .mat-mdc-option {
    .mdc-list-item__primary-text {
      img + div > span {
        margin-left: 0;
        margin-right: var(--spacing-medium);
      }
    }
  }
  .mat-mdc-option:not(.mat-mdc-option-multiple) {
    padding-left: var(--spacing-large);
  }
}

.mat-badge {
  .mat-badge-content {
    font-size: var(--font-size-small);
    height: 18px;
    line-height: var(--line-height-x-small);
    min-width: 18px;
    width: fit-content;
    padding: 2px 4px;
    border-radius: 12px;
    left: auto !important;
    bottom: auto !important;
    right: 4px !important;
    top: 2px !important;

    color: var(--color-accent-contrast);
    background: var(--color-accent);
  }
  &.is-small .mat-badge-content {
    height: 15px;
    min-width: 15px;
    line-height: revert;
  }
  &.mat-mdc-icon-button .mat-badge-content {
    top: 6px !important;
    right: 10px !important;
    left: auto;
  }

  &.badge-is-alert .mat-badge-content {
    color: var(--color-alert-contrast);
    background: var(--color-alert);
  }
  &.badge-is-warning .mat-badge-content {
    color: var(--color-warning-contrast);
    background: var(--color-warning);
  }
  &.badge-is-resolved .mat-badge-content {
    color: transparent;
    &::after {
      content: '\c1';
      font-family: OmIconFont;
      position: absolute;
      left: 4px;
      color: var(--color-accent-contrast);
      font-size: 10px;
    }
  }
}

.mat-mdc-checkbox {
  .mat-pseudo-checkbox,
  .mdc-checkbox {
    width: 18px;
    height: 18px;
  }

  &.is-select-all-enabled {
    label {
      position: absolute;
      right: var(--spacing-x-large);
    }
  }

  .mdc-checkbox {
    padding: 0;
    flex: unset;
    & + label {
      white-space: nowrap;
    }

    .mat-mdc-checkbox-touch-target,
    .mdc-checkbox__native-control {
      width: 25px !important;
      height: 25px !important;
      margin: 0;
    }

    .mdc-checkbox__native-control {
      top: -5px;
      left: -5px;
      &:focus {
        + .mdc-checkbox__ripple {
          background: none;
        }
      }
      &:indeterminate,
      &.mdc-checkbox--selected {
        ~ .mdc-checkbox__background {
          background-color: var(--color-primary) !important;
          border-color: var(--color-primary) !important;
        }
      }
    }

    .mdc-checkbox__background {
      top: 0 !important;
      left: 0 !important;
      height: 18px;
      width: 18px;
      border: 2px solid var(--color-label) !important;
      .mdc-checkbox__checkmark {
        color: var(--color-background-2) !important;
      }
      .mdc-checkbox__mixedmark {
        border-color: var(--color-background-2) !important;
      }
    }
  }

  &.is-accent {
    .mdc-checkbox__native-control {
      &:indeterminate,
      &.mdc-checkbox--selected {
        ~ .mdc-checkbox__background {
          background-color: var(--color-accent) !important;
          border-color: var(--color-accent) !important;
        }
      }
    }
  }
  &.is-label {
    .mdc-checkbox__native-control {
      &:indeterminate,
      &.mdc-checkbox--selected {
        ~ .mdc-checkbox__background {
          background-color: var(--color-stroke) !important;
          border-color: var(--color-stroke) !important;
        }
      }
    }
  }

  &.mdc-checkbox--disabled {
    .mdc-checkbox {
      opacity: var(--disabled-opacity);
    }
    &.mat-mdc-checkbox-disabled--is-dummy {
      .mdc-checkbox {
        opacity: 1;
        cursor: pointer;
      }
    }
  }
}

.mat-pseudo-checkbox {
  margin-right: var(--spacing-medium) !important;
  border-color: var(--color-stroke);
  display: none;
  &.mat-pseudo-checkbox-checked {
    background-color: var(--color-primary) !important;
    border-color: var(--color-primary);
  }
  &.mat-pseudo-checkbox-disabled {
    opacity: var(--disabled-opacity);
  }
}

.mat-datepicker-popup {
  .mat-calendar-header .mat-calendar-controls {
    .mat-mdc-button {
      height: initial;
      line-height: initial;
      border: none;
      color: inherit;
      &:hover {
        .mat-calendar-arrow {
          fill: var(--color-accent-contrast) !important;
        }
      }
    }
    .mat-calendar-period-button {
      height: 34px;
    }
    .mat-calendar-previous-button,
    .mat-calendar-next-button {
      height: var(--button-height);
      width: var(--button-height);
    }
    .mat-calendar-arrow {
      padding-bottom: 2px;
    }
  }
  .mat-calendar-body-disabled {
    opacity: 0.4;
  }
}

.mat-mdc-form-field.is-alternative-styling {
  height: var(--button-height);
  .mat-mdc-text-field-wrapper {
    height: var(--button-height);
    .mat-mdc-form-field-flex {
      .mdc-notched-outline {
        color: var(--color-primary);
      }
      .mdc-floating-label {
        color: var(--color-background-2) !important;
      }
      .mat-mdc-form-field-infix {
        height: var(--button-height);
        background-color: var(--color-primary);
        padding: 0;
        .mat-mdc-select {
          .mat-mdc-select-min-line {
            color: var(--color-background-2);
          }
          .mat-mdc-select-arrow {
            color: var(--color-background-2);
          }
          .mat-mdc-select-placeholder {
            opacity: 0.5;
            color: var(--color-background-2);
          }
          mat-select-trigger > span {
            color: var(--color-background-2);
          }
          .mat-mdc-select-value {
            color: var(--color-background-2) !important;
          }
        }
        om-file-input {
          margin: 0;
          padding: 0px 5px 0px 10px;
          .om-filename {
            color: var(--color-background-2);
          }
          .mat-icon {
            color: var(--color-background-2);
          }

          .mat-mdc-icon-button.is-not-strong {
            background: var(--color-primary);
            color: var(--color-background-2);
          }
        }
      }
      //autocomplete
      .mat-mdc-form-field-icon-suffix {
        .mdc-icon-button {
          .mat-icon {
            color: var(--color-background-2);
          }
        }
      }
      .mat-mdc-chip-grid {
        .mat-mdc-chip-input {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: var(--color-background-2);
        }
        input::placeholder {
          color: var(--color-background-2);
        }
      }
    }
  }
  &.mat-form-field-disabled {
    om-select-input {
      opacity: 0.5 !important;
    }
  }

  &:not(.is-inline):not(.mat-form-field-disabled).mat-form-field-invalid {
    .mdc-notched-outline {
      color: var(--color-alert) !important;
    }
  }
}

.mat-mdc-form-field {
  padding: 0px;
  width: 100%;
  input::placeholder {
    color: var(--color-label) !important;
  }
  .mat-mdc-text-field-wrapper {
    padding: 0;
    z-index: unset;
    .mat-mdc-form-field-flex {
      align-items: center;
      height: 100%;
      .mdc-notched-outline {
        color: var(--color-stroke);
        transition: 0.15s ease-in-out;
        .mdc-notched-outline__leading,
        .mdc-notched-outline__notch,
        .mdc-notched-outline__trailing {
          border-color: inherit !important;
          border-width: thin;
        }
        .mdc-notched-outline__leading {
          width: 9px;
        }
        .mdc-notched-outline__notch {
          border-left: none !important;
          padding-right: 0;
          .mdc-floating-label {
            color: var(--color-primary);
            font-weight: var(--font-weight-bold);
            font-size: var(--font-size-large) !important;
            padding-top: 1px;
            mat-label {
              margin-left: -1px;
              display: flex;
              align-items: center;
              span:first-of-type {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin-right: var(--spacing-small);
              }
              .has-suggestion {
                display: inline-flex;
                max-width: 216px;
                > span {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  &::before {
                    content: ' "';
                    white-space: pre;
                  }
                }
                &::after {
                  content: '" ';
                  white-space: pre;
                }
              }
            }
            .mat-icon {
              pointer-events: auto;
              vertical-align: middle;
              margin: -1px var(--spacing-small) 0;
              width: 1.2em;
              height: 1.2em;
            }
          }
        }
      }
      .mat-mdc-form-field-infix {
        display: flex;
        align-items: center;
        padding-top: var(--spacing-small);
        padding-bottom: var(--spacing-small);
        min-height: unset;
        font-size: var(--font-size-medium);
        background: var(--color-background-2);
        border-radius: var(--border-radius);

        > *:not([matpreffix]):not([matsuffix]):not(.low-confidence-tooltip) {
          flex-grow: 1;
          font-size: inherit;
        }
        .mat-mdc-form-field {
          margin-bottom: 0;
          .mat-mdc-form-field-infix {
            background: unset;
          }
          .mat-mdc-form-field-subscript-wrapper,
          .mdc-notched-outline {
            display: none;
          }
        }
        .mat-mdc-form-field-textarea-control {
          margin: 9px 12px;
        }

        .low-confidence-button {
          margin: 0px var(--spacing-small);

          .mat-icon {
            width: 24px;
            height: 24px;
            &.is-check {
              display: none;
            }
            &.is-low-confidence {
              display: inline;
            }
          }

          &:hover {
            width: 30px;
            height: 30px;
            margin: 0px 6px;
            background-color: var(--color-accent);
            color: var(--color-background-2);
            .mat-icon {
              &.is-check {
                display: inline !important;
                color: var(--color-background-2);
              }
              &.is-low-confidence {
                display: none !important;
              }
            }
          }
        }
      }
    }
  }

  .mat-mdc-form-field-subscript-wrapper {
    position: relative;
    color: var(--color-label);
    line-height: var(--line-height-x-small);
    &::before {
      all: unset;
    }
    .mat-mdc-form-field-hint-wrapper {
      padding: 0 12px;
      position: unset;
      flex-direction: column;
      .mat-mdc-form-field-hint,
      .mat-mdc-form-field-error {
        font-size: var(--font-size-small);
      }
      .mat-mdc-form-field-hint {
        white-space: normal;
        &.is-alert {
          color: var(--color-alert);
        }
        &.is-warning {
          color: var(--color-warning);
        }
      }
      .mat-mdc-form-field-hint-spacer {
        display: none;
      }
    }
  }

  .mat-mdc-form-field-text-prefix {
    padding-left: 5px;
  }

  &.mat-form-field-disabled {
    .mdc-notched-outline {
      opacity: var(--disabled-opacity);
    }
  }

  .mat-mdc-form-field-icon-prefix {
    padding-left: var(--spacing-small);
  }

  &.is-inline {
    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
    &:not(.mat-mdc-form-field-has-icon-prefix) {
      .mat-mdc-input-element {
        padding: 12px;
      }
    }
    > .mat-mdc-text-field-wrapper {
      > .mat-mdc-form-field-flex {
        > .mat-mdc-form-field-infix {
          padding-top: 0 !important;
          padding-bottom: 0 !important;
          .mat-mdc-form-field {
            &.mat-mdc-form-field-has-icon-prefix:not(.is-chip) {
              .mat-mdc-form-field-icon-prefix {
                > .mat-icon {
                  padding: 12px 0;
                }
              }
            }
            .mat-mdc-text-field-wrapper {
              .mat-mdc-form-field-flex {
                height: 100%;
                .mat-mdc-form-field-infix {
                  padding: 0 !important;
                  height: 100%;
                  .mat-mdc-input-element {
                    padding-top: 12px 8px;
                    margin-left: 0px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &:not(.is-inline) {
    margin-bottom: var(--spacing-x-large);
    input[matinput][type='number'],
    .mat-mdc-select,
    .mat-mdc-radio-group {
      padding: 9px 12px;
    }
    .mat-mdc-form-field {
      .mat-mdc-form-field-infix {
        padding: 9px 12px;
        font-size: var(--font-size-medium-uppercase);
      }
    }

    &.mat-mdc-form-field-has-icon-prefix:not(.is-chip) {
      .mdc-notched-outline__leading {
        width: 30px !important;
      }
      .mat-mdc-form-field-icon-prefix {
        > .mat-icon {
          padding: var(--spacing-large) 0;
        }
      }
    }

    &:not(.mat-form-field-disabled) {
      &.mat-form-field-invalid,
      &.mat-focused,
      &.is-warning,
      &.is-alert,
      .mat-mdc-text-field-wrapper:hover {
        .mdc-notched-outline__leading,
        .mdc-notched-outline__notch,
        .mdc-notched-outline__trailing {
          border-width: 2px;
        }
        .mdc-notched-outline {
          color: var(--color-primary);
        }
      }

      &.is-alert {
        .mdc-notched-outline {
          color: var(--color-alert) !important;
        }
      }

      &.mat-form-field-invalid:not(.hide-outline),
      &.is-warning:not(.hide-outline) {
        .mdc-notched-outline {
          color: var(--color-warning) !important;
        }
      }
    }
  }

  .mat-mdc-input-element {
    color: initial;
    &:disabled {
      opacity: var(--disabled-opacity);
    }
  }
  textarea {
    min-height: 48px;
    position: relative;
    z-index: 1;
  }

  &.om-reset {
    &,
    * {
      all: unset;
    }
  }

  &.hide-outline {
    .mat-mdc-form-field-flex {
      .mdc-notched-outline {
        color: transparent !important;
      }
    }

    &.is-warning {
      om-button-toggle-input {
        .mat-button-toggle {
          border-color: var(--color-warning) !important;
        }
      }
    }
  }
}

.mdc-icon-button {
  padding: 0 5px !important;
  .mat-mdc-button-touch-target {
    height: var(--button-height);
    width: var(--button-height);
  }
  .mat-icon {
    display: flex;
  }
}

.mat-icon {
  height: 17.5px;
  min-width: 17.5px;
  line-height: 1.25em;
  color: var(--color-primary);
  padding: 0;
  fill: currentColor;
  stroke: currentColor;
  &.is-accent {
    color: var(--color-accent);
  }
  &.is-accent-soft {
    color: var(--color-accent-soft);
  }
  &.is-alert {
    color: var(--color-alert);
  }
  &.is-warning {
    color: var(--color-warning);
  }
  &.is-stroke {
    color: var(--color-stroke);
  }
  > svg {
    width: 100%;
    height: 100%;
  }
}
p,
span {
  > .mat-icon {
    vertical-align: middle;
  }
}

.mat-mdc-list-item {
  border: none;
  &:not(:hover) {
    background: none;
  }
  &.mat-mdc-list-item-disabled {
    opacity: var(--disabled-opacity);
  }
  .mdc-list-item__primary-text {
    font-size: var(--font-size-medium);
  }
}

.mat-mdc-progress-bar {
  .mat-mdc-progress-bar-buffer {
    background-color: var(--color-stroke);
  }
}

.mat-mdc-radio-group {
  display: flex;
  flex-direction: column;

  > .mat-mdc-radio-button {
    margin-right: 26px;
  }
}

.mat-mdc-radio-button {
  .mdc-radio {
    padding: 0px;
    input,
    .mat-mdc-radio-touch-target {
      width: 20px;
      height: 20px;
    }
    .mdc-radio__background {
      &::before {
        all: unset;
      }
    }
    .mdc-radio__outer-circle {
      border-color: var(--color-stroke) !important;
    }
  }
  label {
    padding-left: 9px;
    color: var(--color-primary) !important;
  }

  &.mat-mdc-radio-checked {
    .mdc-radio {
      .mdc-radio__outer-circle {
        border-color: var(--color-primary) !important;
      }
      .mdc-radio__inner-circle {
        border-color: var(--color-accent) !important;
      }
    }
  }

  &.mat-mdc-radio-disabled {
    .mdc-radio__background {
      opacity: var(--disabled-opacity);
    }
  }
}

.mat-mdc-select {
  .mat-mdc-select-trigger {
    flex-grow: 1;
  }
  .mat-mdc-select-arrow {
    color: var(--color-primary);
  }
}

.is-caret-hidden {
  .mat-mdc-select-arrow {
    display: none;
  }
}

.mat-mdc-select-panel {
  .mat-mdc-option {
    &.mat-mdc-option-multiple {
      .mat-pseudo-checkbox {
        display: unset;
      }
    }
  }
}

.mat-mdc-slide-toggle {
  .mdc-switch__track {
    &::before {
      background-color: var(--color-label) !important;
    }
    &::after {
      background-color: var(--color-accent) !important;
    }
  }
  .mdc-switch__handle-track {
    border-radius: var(--mdc-switch-handle-shape);
    background-color: var(--color-accent-contrast);
    height: calc(var(--mdc-switch-track-width) - var(--mdc-switch-handle-width));
    top: 2px;
    left: 2px;
    * {
      display: none;
    }
  }
}

.mat-mdc-card {
  border: 1px solid var(--color-stroke);
  &:not([class*='mat-elevation-z']) {
    box-shadow: none;
  }
  padding: var(--spacing-large);

  .mat-mdc-card-header {
    margin-bottom: var(--spacing-large);
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    margin: 0;
    .mat-mdc-card-avatar {
      padding: 2px;
      border-radius: 2px;
      border: 1px solid var(--color-stroke);
      margin-bottom: 0;
    }
    .mat-mdc-card-header-text {
      flex-grow: 1;
      flex-shrink: 1;
      margin: 0;

      .mat-mdc-card-title,
      .mat-mdc-card-subtitle {
        margin-bottom: 0;
        padding: 0;
        line-height: var(--line-height-small);
        + .mat-mdc-card-title,
        + .mat-mdc-card-subtitle {
          margin-top: var(--spacing-small);
        }
      }

      .mat-mdc-card-title {
        color: var(--color-primary);
        font-size: var(--font-size-small);
        font-weight: var(--font-weight-bold);
      }
      .mat-mdc-card-subtitle {
        font-size: var(--font-size-small);
        color: var(--color-label);
        &.is-alert {
          color: var(--color-warning);
        }
        &.is-warning {
          color: var(--color-warning);
        }
      }
    }

    > .mat-mdc-button {
      flex-shrink: 0;
    }
    > * {
      + .mat-mdc-card-avatar,
      + .mat-mdc-card-header-text,
      + .mat-mdc-button {
        margin-left: 12px;
      }
    }
    > .mat-mdc-button + .mat-button {
      margin-left: var(--spacing-medium);
    }
  }
  .mat-mdc-card-content {
    padding: 0;
  }
  &.om-info-card {
    flex-direction: row;
  }
}

.mat-mdc-table {
  &.om-grid {
    width: 100%;
    thead {
      background-color: var(--color-background-1);
      .mat-mdc-header-row:first-child {
        box-shadow: none;
        .mat-mdc-header-cell {
          border-top: 0;
        }
      }
    }
    tbody {
      tr:last-of-type {
        .mat-mdc-cell {
          border-bottom: none;
        }
      }
    }
    .mat-mdc-header-cell,
    .mat-mdc-cell,
    .mat-mdc-footer-cell {
      padding-left: 12px;
      padding-right: 12px;
      border-left: 1px solid;
      border-color: var(--color-stroke);
      &:first-of-type {
        border-left: none;
      }
    }

    .mat-mdc-header-row {
      box-shadow: inset 0 8px 4px -8px var(--color-stroke);
    }

    .mat-mdc-header-row,
    .mat-mdc-row {
      height: 40px;
    }

    .mat-mdc-header-cell {
      border-top: 1px solid var(--color-stroke);
      font-size: var(--font-size-small);
      font-weight: var(--font-weight-bold);
      color: #20364b;
    }

    tr:nth-child(even) {
      .mat-mdc-cell:not(.mat-column-actions) {
        background-color: var(--color-row-odd);
      }
    }
    .mat-mdc-cell {
      font-size: var(--font-size-medium);
    }

    .mat-column-actions {
      width: 62px;
      padding-right: 12px;
      text-align: center;
      background-color: var(--color-background-1);
    }

    .mat-column-email {
      word-break: break-word;
    }

    .mat-column-image {
      display: flex;
      align-items: center;
      grid-gap: var(--spacing-small);
    }
  }
  &.om-accordion-table {
    .mat-mdc-row {
      &.is-child:not(.is-expanded) {
        > .mat-mdc-cell {
          display: none;
        }
      }
    }
  }
}

.mat-mdc-menu-panel {
  max-width: 350px !important; // todo remove when search and notifications uses tooltip
  min-height: 48px;
  .mat-mdc-menu-content {
    padding-top: 0;
    padding-bottom: 0;
    > om-loading {
      padding: 15px;
    }
  }
  .mat-mdc-subheader {
    color: var(--color-label);
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-small);
    display: block;
    margin: var(--spacing-medium) var(--spacing-large) 0;
  }
  .mat-icon {
    color: var(--color-primary);
  }
  .mat-mdc-menu-item {
    .mat-mdc-menu-item-text {
      font-size: var(--font-size-medium);
      color: var(--color-primary);
    }
    .mat-icon {
      height: 18px;
    }
    &[disabled] {
      opacity: 1;
      &,
      .mat-icon,
      .mat-mdc-menu-item-text {
        color: var(--color-label);
      }
    }
    &.is-alert {
      &,
      .mat-icon {
        color: var(--color-alert);
      }
      .mat-mdc-menu-item-text {
        color: var(--color-alert);
      }
    }
    &.is-warning {
      &,
      .mat-icon,
      .mat-mdc-menu-item-text {
        color: var(--color-warning);
      }
    }

    &.is-selected {
      background: var(--color-background-3);
      border-left: 4px solid var(--color-accent);
    }

    &.om-menu-list-item {
      color: var(--color-label);
    }

    &.is-tab-active {
      color: var(--color-primary);
      font-weight: var(--font-weight-bold);
      border-left: 3px solid var(--color-primary);
    }
  }
}

.mat-calendar-body-cell {
  &,
  &:hover {
    &.mat-calendar-body-active,
    &.mat-calendar-body-in-range {
      .mat-calendar-body-cell-content {
        color: var(--color-accent-contrast);
        background-color: var(--color-primary) !important;
      }
      &:not(.mat-calendar-body-range-start):not(.mat-calendar-body-range-end):not(.mat-calendar-body-active) {
        opacity: var(--disabled-opacity);
      }
    }
  }
  .mat-calendar-body-today {
    border: 1px solid var(--color-primary);
  }
}

.mat-toolbar {
  background: var(--color-background-2);

  &--is-menu {
    background: var(--color-background-1) !important;
  }
  display: flex;
  flex-direction: row;
  font-size: var(--font-size-medium);
  height: var(--toolbar-height);
  min-height: var(--toolbar-height);
  &.has-close {
    padding-right: 45px;
  }

  img {
    max-height: 100px;
    height: 80%;
  }

  > .is-narrow {
    max-width: $width-narrow-content;
    width: 100%;
    display: flex;
    margin: auto;
  }
  > h1,
  > h2,
  > h3,
  > p {
    flex-grow: 1;
    flex-shrink: 1;
    overflow: hidden;
  }
  section {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    &:not([left]):not([right]) {
      flex-grow: 1;
      flex-shrink: 1;
      overflow: hidden;
    }
    h1,
    h2 {
      display: inline-block;
      vertical-align: middle;
      + h2 {
        margin-left: 0;
        font-weight: var(--font-weight-normal);
      }
    }
    > span {
      font-weight: var(--font-weight-bold);
    }
    > .mat-mdc-form-field:not(.is-inline) {
      border-radius: 6px;
      height: 34px;
      color: var(--color-primary);
      &.mat-mdc-form-field-has-icon-prefix {
        .mat-icon {
          padding: 9px;
        }
      }
    }
    > * {
      + * {
        margin-left: var(--spacing-medium);
      }
    }
    &[left]:first-child {
      padding-left: 0px;
      padding-right: var(--spacing-medium);
    }
    &[right]:last-child {
      padding-left: var(--spacing-medium);
      padding-right: 0px;
      margin-left: auto;
    }
    .mat-mdc-chip-set {
      margin: 2px 0;
      padding: 0 !important;
    }
    a {
      display: flex;
      align-items: center;
      height: 100%;
    }
  }
  &.om-top-toolbar {
    color: var(--color-navigation-contrast);
    background-color: var(--color-navigation-1);
  }
  &.is-accent {
    background: var(--color-accent-background);
    border-color: var(--color-accent);
    border-style: solid;
    border-width: 1px 0px;
  }
  &.is-ag-grid-toolbar {
    border-color: var(--color-accent);
    border: 1px solid var(--color-stroke);
    background: var(--color-background-3);
  }
  &.is-expanding {
    height: auto;
    p {
      white-space: pre-line;
    }
  }

  &.om-notification-toolbar {
    color: var(--color-background-contrast) !important;
    justify-content: center;
    &.is-alert {
      background: var(--color-alert-background);
    }
    &.is-label {
      background: var(--color-label-background);
    }
    p {
      flex-grow: 0;
      margin-right: 20px;
    }
  }

  &.om-can-minimise {
    flex-wrap: wrap;
    padding-top: var(--spacing-small);
    padding-bottom: var(--spacing-small);

    section {
      flex: 1 0;
    }

    section[right] {
      justify-content: flex-end;
    }

    section:not(:empty) {
      padding-top: 5px;
      padding-bottom: 5px;
    }

    section[left] + section:not([right]) {
      overflow: visible;
      &::before {
        background: none !important;
      }
    }
  }
}
&.is-impersonation {
  .mat-toolbar.om-top-toolbar {
    background-color: var(--color-accent-soft);
    color: var(--color-accent-soft-contrast);
  }
}

.mat-mdc-card.om-narrow-card {
  margin: var(--spacing-large);
  padding: 0 var(--spacing-medium);
}
.mat-toolbar,
.mat-mdc-card.om-narrow-card {
  .mat-mdc-list {
    display: flex;
    flex-direction: row;
    padding: 0;
    .mat-mdc-list-item {
      height: 48px;
      .mdc-list-item__primary-text {
        padding: 0 var(--spacing-medium);
      }
      .mat-line {
        font-size: var(--font-size-medium);
        line-height: var(--line-height-medium);
      }
      b.mat-line {
        font-weight: var(--font-weight-normal);
      }
    }
  }

  .mat-mdc-form-field {
    font-size: var(--font-size-medium);
    line-height: var(--font-size-medium);
    .mat-mdc-text-field-wrapper {
      padding: 0;
      margin: 0;
    }
  }
}

.om-page-toolbar {
  &,
  .mat-toolbar {
    color: var(--color-navigation-contrast);
    background-color: var(--color-navigation-2);
  }

  section[left] + section:not([right]) {
    &::before {
      content: ' ';
      display: block;
      margin-right: var(--spacing-medium);
      height: 26px;
      width: 1px;
      background: var(--color-accent);
    }
  }
}

.mat-mdc-snack-bar-container {
  min-width: 50px;
  background-color: var(--color-accent-background);
  border: 2px solid;
  border-color: var(--color-accent);
  border-radius: var(--border-radius);
  font-size: var(--font-size-medium);

  .mdc-snackbar__surface {
    background-color: transparent;
    padding: 0;
    .mat-mdc-simple-snack-bar {
      font-size: inherit;
      .mat-mdc-snack-bar-label {
        padding: 12px;
        text-align: center;
        .mat-mdc-simple-snack-bar {
          font-size: inherit;
        }
      }
    }
  }

  &.is-alert {
    background-color: var(--color-alert-background);
    border-color: var(--color-alert);
  }
  &.is-warning {
    background-color: var(--color-warning-background);
    border-color: var(--color-warning);
  }
}

.mat-mdc-button-base {
  .mat-mdc-progress-spinner {
    color: currentColor !important;
    display: inline-block;
    vertical-align: sub;
    &,
    svg {
      height: calc(var(--button-height) - var(--spacing-medium)) !important;
      width: calc(var(--button-height) - var(--spacing-medium)) !important;
    }
    &.is-small,
    &.is-small svg {
      height: calc(var(--button-height) - var(--spacing-large)) !important;
      width: calc(var(--button-height) - var(--spacing-large)) !important;
    }

    &:first-child {
      margin-right: var(--spacing-medium);
    }
    &:last-child {
      margin-left: var(--spacing-medium);
    }
  }
}
.mat-mdc-progress-spinner {
  color: var(--color-accent);
  margin: auto;
  circle {
    stroke: currentColor;
  }
}

.cdk-overlay-pane {
  min-width: min-content;
  transform: none !important;
}

.mat-mdc-form-field-text-suffix {
  align-self: center;
}

.mat-stepper-horizontal,
.mat-stepper-vertical {
  .mat-step-label,
  .mat-step-label-selected {
    font-size: small;
  }

  .mat-step-icon .mat-icon {
    color: var(--color-background-2);
    height: unset;
  }
}

.mat-mdc-chip {
  min-height: 32px;
  height: fit-content;
  padding: 2px 0px;
  .mat-mdc-chip-action {
    padding: 0 12px !important;
    > .mdc-evolution-chip__graphic {
      padding: 0 !important;
    }
  }
  .mat-mdc-chip-focus-overlay {
    background: unset;
  }
  .mdc-evolution-chip__text-label {
    display: flex;
    align-items: center;
    color: inherit !important;
    font-size: var(--font-size-small);
    word-break: break-word;
    white-space: normal;
    line-height: 14px;
    .mat-icon {
      margin-top: -2px;
      padding-left: 6px !important;
      padding-right: 0 !important;
    }
  }
  &.is-suggestion {
    background-color: transparent;
    border: 1px solid var(--color-stroke);
    color: var(--color-label);

    &:focus::after {
      opacity: 0;
    }
    .mat-icon {
      color: inherit;
    }
    &:not(.mat-mdc-chip-disabled) {
      cursor: pointer;
      &:hover {
        color: var(--color-primary);
      }
    }
  }
}

.mat-mdc-tab-header {
  .mdc-tab {
    min-width: 160px;
    flex-grow: 0 !important;
  }
}

.mat-drawer {
  section {
    color: var(--color-primary);
    strong {
      font-size: var(--font-size-medium-uppercase);
    }
  }
}

mat-tab-nav-panel#tapPanel {
  display: none;
}

.om-tab-toggle {
  margin: auto;
  height: var(--button-height);
  border-radius: 100px;
  background-color: var(--color-primary);
  outline: 2px solid var(--color-primary);

  .mat-mdc-button.mat-mdc-button-base {
    display: flex;
    line-height: 20px;
    height: var(--button-height);
    align-items: center;
    padding: 6px 24px;
    background-color: var(--color-primary) !important;
    border-radius: 100px;
    border: none;

    .mdc-button__label {
      font-size: var(--font-size-medium);
      font-weight: 600;
      user-select: none;
      color: var(--color-background-2) !important;
    }

    &.is-tab-active {
      background-color: var(--color-background-2) !important;

      .mdc-button__label {
        color: var(--color-primary) !important;
      }
    }

    &.is-alert:not(.is-tab-active) {
      &::before {
        @include mixin.empty-badge();
        display: block;
        color: var(--color-alert);
        right: 12px;
      }
    }
  }
}
.mdc-tab {
  padding-right: 16px;
  padding-left: 16px;
}
