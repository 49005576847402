om-activity-card {
  display: block;
  .mat-mdc-card {
    padding-bottom: var(--spacing-small);
    .mat-mdc-card-header {
      margin-bottom: var(--spacing-small);
      .mat-mdc-card-avatar {
        margin-bottom: 0;
      }
    }
    .mat-mdc-card-content {
      color: var(--color-primary);

      .has-content {
        .content-label {
          &::before {
            content: 'Text: "';
          }
        }

        &.is-overflowing {
          word-break: break-all;
          &::after {
            content: '"';
          }
        }

        .content-title-clamp {
          overflow: hidden;
          vertical-align: bottom;
          max-width: calc(100% - 8px); /* Space for overflow end quote character */
          display: -webkit-inline-box;
          line-clamp: var(--max-lines); /* Can specify number of lines to clamp to */
          -webkit-line-clamp: var(--max-lines); /* Can specify number of lines to clamp to */
          -webkit-box-orient: vertical;

          &::after {
            content: '"';
          }
        }
      }

      slate-editable {
        word-wrap: break-word;
        min-height: 0;
        max-height: none;
      }

      p {
        margin-top: 0;
        margin-bottom: 0;
      }
      ul:not(om-rich-text-input ul) {
        list-style-type: none;
        margin: 0;
        padding: 0;
        li:not(om-rich-text-input li) {
          border-top: 1px solid var(--color-stroke);
          font-size: var(--font-size-medium);
          span:not(om-rich-text-input span) {
            white-space: pre-line;
          }
        }
      }
      p,
      li {
        line-height: 22px;
        padding: var(--spacing-medium) 0;
      }

      ul.is-redirect {
        li {
          border-top: none !important;
          &:first-of-type {
            border-top: 1px solid var(--color-stroke) !important;
          }

          &:not(:last-of-type) {
            padding-bottom: 0;
          }

          .is-text {
            .mdc-button__label {
              text-decoration: underline;
            }
            &.visited {
              .mdc-button__label {
                color: var(--color-accent-soft);
              }
            }
          }
        }
      }
    }
  }
  + om-activity-card {
    margin-top: var(--spacing-large);
  }
}
