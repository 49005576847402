om-email-page {
  .mat-drawer.mat-drawer-side {
    width: 500px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    z-index: 3 !important;
  }

  .mat-toolbar > section > span {
    padding-right: 4px;
  }

  om-email-list {
    overflow-y: auto;
  }

  om-email-viewer {
    overflow: auto;
    width: 100%;
    height: 100%;
    om-empty-search {
      margin-top: 240px;
    }
  }
}

.om-email-search-form {
  width: 350px;
  .mat-mdc-dialog-actions {
    margin: 0;
  }

  om-dynamic-form-field {
    padding: 0 5px !important;
  }
}
