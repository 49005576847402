om-date-range-input {
  padding: 4px 12px;
  > .mat-mdc-form-field {
    .mat-mdc-form-field-infix {
      display: flex;
      cursor: pointer;
      padding: 0 !important;
      > span {
        width: 100%;
        display: inline-flex;
        justify-content: left;
        align-items: center;
        cursor: pointer;
        p {
          margin: 0;
        }
      }
      .mat-mdc-icon-button {
        color: var(--color-primary);
        margin-top: -6px !important;
      }

      .mat-date-range-input.mat-mdc-form-field-input-control {
        display: none;
      }
    }
  }
}
