om-issuer-profile-entities-ratings-widget {
  display: block;

  th.mat-mdc-header-cell:last-of-type,
  td.mat-mdc-cell:last-of-type,
  td.mat-mdc-footer-cell:last-of-type {
    padding-right: 0px;
  }

  .om-grid {
    border: 1px solid var(--color-stroke);
  }
}
