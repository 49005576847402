om-api-usage {
  .mat-mdc-form-field {
    width: unset !important ;
  }
  .mat-mdc-table {
    display: block;
    overflow: scroll;
  }
  .mat-mdc-paginator {
    font-size: var(--font-size-small);
    color: var(--color-primary);
  }
}
