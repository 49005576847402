om-pdf-viewer-controller {
  position: absolute;
  z-index: 99;
  bottom: 0;
  right: 0;
  margin-right: 30px;
  margin-bottom: 30px;
  ul,
  li {
    display: flex;
    flex-direction: column;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  ul {
    width: var(--button-height);
    background-color: var(--color-background-2);
    border-radius: 6px;
    outline: 1px solid var(--color-stroke);
    box-shadow: 0px 6px 10px 4px rgba(0, 0, 0, 0.15);
  }
  li {
    &:not(:first-child) {
      border-top: 1px solid var(--color-stroke);
    }
    &:first-child {
      &:hover {
        .mat-mdc-icon-button {
          border-radius: 6px 6px 0 0 !important;
        }
      }
      .mat-mdc-button-base {
        border-radius: 6px 6px 0 0 !important;
      }
    }
    &:last-child {
      &:hover {
        .mat-mdc-icon-button {
          border-radius: 0 0 6px 6px !important;
        }
      }
      .mat-mdc-button-base {
        border-radius: 0 0 6px 6px !important;
      }
    }
    .mat-mdc-icon-button {
      &:hover {
        border-radius: 0px !important;
      }
    }
    .mat-mdc-button-base {
      border-radius: 0px !important;
    }
  }
  .is-active {
    background-color: var(--color-primary);
    .mat-icon {
      color: var(--color-background-2) !important;
    }
  }
}
