om-phone-input {
  padding: 9px 12px;

  .om-placeholder {
    position: absolute;
    z-index: 1;
    left: 10%;
  }
  .iti {
    width: 100%;
    .iti__country-container {
      .iti__selected-country-primary {
        background-color: var(--color-background-1);
        border-radius: 2px;
        padding: 4px;
      }
      .iti__selected-country-primary:hover {
        background-color: var(--color-background-1) !important;
      }
      .iti__selected-dial-code {
        margin-left: var(--spacing-medium);
      }
    }
    .iti__tel-input {
      width: 100%;
      border: none;
    }
    .iti__tel-input:focus-visible {
      outline: none !important;
    }
  }
}
