om-trade-timeline-stage {
  .title-description {
    margin-top: 8px;
  }
  .panel-title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .mat-content {
    overflow: inherit;
  }
  .mat-accordion {
    display: block;
    border-top-left-radius: var(--border-radius-small);
    border-top-right-radius: var(--border-radius-small);
    &:last-child {
      border-bottom-left-radius: var(--border-radius);
    }

    .mat-expansion-panel:not([class*='mat-elevation-z']) {
      border: 1px solid var(--color-stroke);
      border-radius: var(--border-radius-small);
      position: relative;

      &.has-docs {
        border-bottom: none;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }

      box-shadow: none;
      .mat-expansion-panel-header {
        padding: 20px var(--spacing-xl-large) 20px var(--spacing-xl-large);
        height: unset;

        .mat-expansion-panel-header-title {
          flex-direction: column;
          span:not(.mdc-button__label) {
            font-size: var(--font-size-small);

            text-transform: uppercase;
            line-height: normal;
            > span {
              color: var(--color-secondary);
              padding: 5px 0 3px 0;
              margin-right: 4px;
            }
            > span + span {
              display: inline-block;
              color: var(--color-label);
              padding-right: 8px;
              padding-left: 8px;

              &.is-active {
                background-color: color-mix(in srgb, var(--color-background-4), transparent 50%);
                color: var(--color-accent);
              }

              &.is-blocked {
                background: var(--color-alert-background);
                color: var(--color-alert);
              }
            }
          }
          h3 {
            font-size: var(--font-size-x-large);
            line-height: normal;
            margin: 6px 0 0;
            color: var(--color-secondary);
          }
        }
        om-dynamic-action {
          margin-left: var(--spacing-medium);
        }
      }

      .mat-expansion-panel-body {
        padding-right: var(--spacing-xl-large);
        padding-left: var(--spacing-xl-large);
        padding-bottom: 0;
        > *:last-child {
          margin-bottom: 20px;
        }
      }

      .om-timeline-stage-step {
        padding: 0px 0px 20px 20px;
        color: var(--color-primary);
        position: relative;
        display: flex;
        flex-direction: row;

        a {
          text-decoration: underline;
        }
        .is-alert {
          a {
            color: var(--color-alert);
          }
        }

        > div {
          flex-grow: 1;
          margin-left: 8px;
          margin-top: 2px;
        }

        img {
          max-height: 50px;
          max-width: 50px;
        }
        strong {
          display: block;
          font-size: var(--font-size-medium);

          + .om-timeline-stage-step-event:last-child {
            margin-bottom: -8px;
            margin-top: -8px;
          }
        }

        .om-step-header {
          display: flex;
          align-items: center;
          line-height: normal;
          h4 {
            flex-shrink: 0;
            padding: 0;
            margin: 0;
            margin-right: 6px;
          }
          span {
            color: var(--color-label);
            &.is-alert {
              color: var(--color-alert);
            }
          }

          .mat-icon {
            color: var(--color-label);
          }
        }

        &::after {
          content: ' ';
          background: currentColor;
          border: 3px solid currentColor;
          border-radius: 50%;
          box-shadow: 0px 0px 0px 1px currentColor;
          height: 12px;
          width: 12px;
          position: absolute;
          left: 1px;
          top: 2px;
        }

        &.is-sequential {
          &::before {
            content: ' ';
            background: none;
            border-left: 1px dashed var(--color-stroke);

            height: 100%;
            width: 0;
            position: absolute;
            left: 9.5px;
            top: 10px;
          }
          &.is-completed {
            &::before {
              border-left: 1px solid var(--color-secondary);
            }
          }
        }

        &.is-completed {
          &::after {
            font-family: OmIconFont;
            content: '\c1';
            background: var(--color-secondary);
            color: var(--color-background-2);
            border: none;
            box-shadow: none;
            width: 16px;
            height: 16px;
            line-height: var(--line-height-x-small);
            font-size: 10px;
            text-align: center;
            left: 2px;
          }
        }
        &.is-active,
        &.is-blocked {
          strong {
            color: var(--color-primary);
          }
          &::before {
            background: none;
          }
        }
        &.is-active {
          &::after {
            background: var(--color-accent);
            border-color: var(--color-background-2);
            top: 1px;
          }
        }
        &.is-blocked {
          &::after {
            background: var(--color-alert);
            border-color: var(--color-background-2);
          }
        }
        &.is-pending,
        &.is-disabled,
        &.is-skipped {
          color: var(--color-label);
          &::after {
            margin: 2px 2px;
            box-shadow: none;
            border: transparent;
            background: var(--color-background-emphasis);
            height: 16px;
            width: 16px;
            left: 0;
            top: 0;
          }

          .mat-icon {
            color: var(--color-label);
          }
        }
        &.is-skipped {
          color: var(--color-primary);
          &::after {
            content: '\c4';
            font-family: OmIconFont;
            text-align: center;
            line-height: 18px;
            letter-spacing: -2px;
            background: var(--color-secondary);
            color: var(--color-background-2);
          }
        }
        &.is-skipped ~ .is-skipped {
          color: var(--color-label);
          &::after {
            content: '';
            background: var(--color-background-emphasis);
          }
        }
        &:last-child {
          padding-bottom: 0;
          &::before {
            display: none;
          }
        }
        &.is-third_party_activity {
          border: 1px solid var(--color-stroke);
          border-radius: var(--border-radius);
          padding: 13px var(--spacing-large);
          align-items: center;
          line-height: var(--line-height-x-small);
          &::before,
          &::after {
            display: none;
          }
          + * {
            margin-top: var(--spacing-large);
          }

          .om-step-header {
            h4 {
              font-size: var(--font-size-medium);
              margin-right: 2px;
            }

            span {
              font-size: var(--font-size-small);
            }
          }
          .om-timeline-stage-step-event {
            line-height: normal;
            margin-top: unset;
          }

          > div:first-of-type {
            margin-left: 0;
            margin-top: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 4px;
          }

          > img + div {
            margin-left: var(--spacing-large) !important;
          }

          > div + div {
            display: flex;
            flex-grow: 0;
            padding: 0;
            align-items: center;
          }
        }

        .om-timeline-stage-step-event {
          line-height: var(--font-size-medium);
          margin-top: 10px;
          .is-label {
            font-size: var(--font-size-small);
          }

          .om-timeline-stage-step-nested-event {
            strong {
              font-size: var(--font-size-small);
            }
            article {
              margin-top: -5px;
            }
            .mat-icon {
              height: 12px;
            }
          }

          .mat-mdc-button.is-text {
            .mdc-button__label {
              text-decoration: underline;
            }
          }

          &.is-disabled {
            opacity: 0.5;
          }
        }

        .om-timeline-stage-step-identifiers-section {
          display: flex;
          gap: var(--spacing-large);
        }
      }
    }
    &.is-current-stage {
      margin-top: var(--spacing-small);
      .mat-expansion-panel:not([class*='mat-elevation-z']) {
        border-top: none;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        overflow: inherit;

        &::before {
          content: '';
          border-top: var(--spacing-small) solid var(--color-accent);
          height: var(--spacing-small);
          display: block;
          position: absolute;
          top: calc(var(--spacing-small) * -1); // note: to convert var to minus
          z-index: 10;
          left: -1px;
          right: -1px;
          border-top-right-radius: 4px;
          border-top-left-radius: 4px;
        }

        .mat-expansion-panel-header {
          padding-bottom: 30px;
        }
      }

      &.is-alert {
        .mat-expansion-panel:not([class*='mat-elevation-z']) {
          &::before {
            border-top-color: var(--color-alert);
          }
        }
      }

      &.is-unavailable {
        .mat-expansion-panel:not([class*='mat-elevation-z']) {
          &::before {
            border-top-color: var(--color-accent-soft);
          }
        }
      }
    }

    &.is-pending,
    &.is-completed {
      .mat-expansion-panel:not([class*='mat-elevation-z']) {
        .mat-expansion-panel-header {
          .mat-expansion-panel-header-title {
            h3 {
              color: var(--color-label);
            }
          }
        }
      }
    }
    &.has-third-party-activity {
      .mat-expansion-panel:not([class*='mat-elevation-z']) {
        &.mat-expanded {
          .mat-expansion-panel-header {
            padding-bottom: var(--spacing-x-large);
          }
        }
      }
    }
  }
  > div {
    background: var(--color-background-3);
    border: 1px dashed var(--color-stroke);
    border-bottom-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
    color: var(--color-primary);
    padding: calc(var(--spacing-large) * 0.75);
    > a {
      margin: calc(var(--spacing-large) * 0.25);
    }
  }

  .as-link {
    display: inline-block;
    margin-left: 2px;
    border-bottom: 1px dashed;
  }

  .om-step-is-progressed {
    background: var(--color-background-emphasis);
    display: flex;
    padding: 10px 8px;
    line-height: initial;
    margin-top: 10px;
    align-items: center;
    border-radius: var(--border-radius-small);
    color: var(--color-primary);

    span {
      &:nth-child(3) {
        color: var(--color-label);
      }
    }
  }
}

.om-timeline-step__info {
  background-color: var(--color-row-odd);
  border-radius: 8px;
  padding: 10px 20px;
  margin-bottom: 10px;

  &:first-child {
    margin-top: 10px;
  }

  ul,
  li {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  > ul > li {
    &:not(:first-of-type) {
      margin-top: 12px;
    }
  }

  ul {
    li {
      &:last-child {
        ul {
          li {
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    &.is-pending {
      &:not(:last-of-type) {
        margin-bottom: 12px;
        border-bottom: 1px solid var(--color-stroke);
        padding-bottom: 12px;
      }
    }
  }

  h4 {
    margin: 0;
    font-size: var(--font-size-medium);
    line-height: var(--font-size-large);
    text-transform: uppercase;
    font-weight: var(--font-weight-normal);
    margin-bottom: 6px;
  }
  h5 {
    padding: 0;
    margin: 0;
    font-size: var(--font-size-medium);
    font-weight: var(--font-weight-normal);
    line-height: var(--line-height-x-small);
    text-transform: uppercase;
    color: var(--color-label);
    margin-bottom: 6px;

    span {
      font-style: italic;
      text-transform: none;
      color: var(--color-label);
      font-weight: var(--font-weight-normal);
    }
  }

  .om-list-item {
    display: flex;
    align-items: center;
    line-height: normal;
    margin-bottom: 6px;

    .mat-icon {
      height: 12px;
      width: 12px;
      color: var(--color-label);
      line-height: normal;
      &.is-completed,
      &.is-granted {
        color: var(--color-accent);
      }
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &--tooltip {
    background: none;
    margin: 0;
    padding: 0;
    h5 {
      font-size: var(--font-size-small);
      font-weight: var(--font-weight-semi-bold);
      color: var(--color-primary);

      span {
        font-style: italic;
        text-transform: none;
        color: var(--color-label);
        font-weight: var(--font-weight-normal);
      }
    }
  }

  .om-timeline-step__reviewer {
    margin-left: 2px;
    span {
      color: var(--color-label);
    }
  }
}

.tippy-box[data-theme~='tooltip-style-2'] {
  border-radius: var(--border-radius-medium);
  .tippy-content {
    padding: 10px 20px;
    background-color: var(--color-row-even);
    border-radius: var(--border-radius-medium);
    .om-timeline-step__info {
      border-radius: 0;
    }
    .om-timeline-step__info:first-child {
      margin-top: 0;
    }
    .om-list-item {
      &:last-child {
        margin-bottom: 8px;
      }
    }

    ul {
      li {
        &:last-child {
          ul {
            li {
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }

  .tippy-arrow {
    display: none !important;
  }
}
