om-curve-analysis-grid {
  .ag-center-cols-viewport {
    min-height: unset !important;
  }

  ag-grid-angular {
    height: auto;
    .ag-root-wrapper {
      min-height: 400px;
    }
  }
}
