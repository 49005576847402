om-issuer-profile-toolbar {
  .mat-toolbar section > img {
    height: 56px;
    width: 56px;
    object-fit: contain;
  }

  .om-page-toolbar {
    div {
      align-items: center;
    }
  }

  section[middle] {
    &::before {
      display: none !important;
    }
    table {
      display: block;
      flex-shrink: 1;
      flex-grow: 1;
      width: 100px;
      tr {
        display: flex;
        flex-direction: row;
        line-height: 1em;
        td {
          flex-grow: 1;
          flex-shrink: 1;
          &:last-child {
            text-align: right;
          }
          &:first-child {
            text-align: left;
          }
        }
      }
    }
  }

  .om-company-name-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    overflow: hidden;
    h2 {
      overflow: hidden;
      text-overflow: ellipsis;
      flex-grow: 0;
      flex-shrink: 1;
      > span {
        padding-right: var(--spacing-medium);
      }
      > .mat-mdc-icon-button {
        margin-bottom: 5px;
      }
    }
    .mat-mdc-button {
      flex-grow: 0 !important;
      flex-shrink: 0 !important;
      display: inline-block !important;
      margin: 0 var(--spacing-large);
    }
  }

  .om-trade-status-container {
    flex-shrink: 0 !important;
    height: 25px;
    line-height: 25px;
    > span {
      display: inline-block;
      font-weight: var(--font-weight-bold);
      vertical-align: sub;
    }
    img {
      height: 100%;
      vertical-align: middle;
    }
  }

  .om-issuer-details-container {
    img {
      height: 18px;
    }

    > div {
      * {
        line-height: 18px;
        vertical-align: middle;
      }
      &:first-child {
        margin-bottom: var(--spacing-medium);
        img {
          margin-top: -3px;
        }
      }
    }
  }

  .om-issuer-actions-container {
    .om-multi-action {
      margin-left: var(--spacing-medium);
    }
  }
}
