$font-colour: var(--color-navigation-contrast);
$background-colour: var(--color-navigation-2);
$font-colour-inverse: var(--color-navigation-2);
$background-colour-inverse: var(--color-navigation-contrast);
$border-colour: var(--color-navigation-3);

// todo: add to 'shared' file...?
@mixin descending-z-index($count: 30) {
  // @include on UL class
  position: relative;
  li {
    position: relative;
    $target: 0;
    $index: $count;
    @while $index > 0 {
      &:nth-child(#{$target}) {
        z-index: #{$index};
      }
      $target: $target + 1;
      $index: $index - 1;
    }
  }
}

om-trade-working-group {
  display: flex;
  cursor: pointer;

  ul,
  li {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  ul {
    @include descending-z-index;
    display: flex;
  }

  li {
    margin-left: -4px;
  }

  .om-circle-contents {
    background-color: $background-colour;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    color: $font-colour;

    font-size: 14px;
    font-weight: var(--font-weight-bold);

    border: 1px $border-colour solid;

    display: flex;
    align-items: center;
    justify-content: center;

    .mat-icon {
      color: $font-colour;
    }

    &[class*='style'] {
      font-weight: var(--font-weight-normal);
    }

    &--style-0 {
      background-color: var(--color-working-group-1);
    }
    &--style-1 {
      background-color: var(--color-working-group-2);
    }
    &--style-2 {
      background-color: var(--color-working-group-3);
    }
  }

  &:hover {
    .om-circle-contents {
      &:not([class*='style']) {
        background-color: $background-colour-inverse;
        color: $font-colour-inverse;
        .mat-icon {
          color: $font-colour-inverse;
        }
      }
    }
  }
}
