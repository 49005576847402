om-version-history-sidenav {
  background: var(--color-navigation-1);

  om-sidenav-toggle {
    background: var(--color-navigation-1) !important;
  }

  .mdc-icon-button.is-chip {
    align-self: center;
    background: var(--color-navigation-1) !important;
    border-color: var(--color-background-2) !important;
    color: var(--color-background-2) !important;
    .mat-icon {
      transform: rotate(90deg);
    }

    &:hover {
      background: var(--color-background-2) !important;
      color: var(--color-navigation-1) !important;
    }
  }
}
