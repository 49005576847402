om-issuer-profile-document-list {
  display: block;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    li {
      margin-right: var(--spacing-medium);
      margin-top: var(--spacing-small) / 2;
      margin-bottom: var(--spacing-medium);
    }
  }
}

.om-table + om-issuer-profile-document-list {
  background: var(--color-background-3);
  border-top: 1px dashed var(--color-stroke);
  padding: var(--spacing-large);
}

.om-table {
  * + om-issuer-profile-document-list {
    margin-top: var(--spacing-medium);
  }
}
