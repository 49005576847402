om-activity-feed-widget {
  display: flex;
  flex-direction: column;
  height: 100%;

  article {
    flex-direction: column;
    .mat-mdc-card {
      border: 0;
      cursor: pointer;

      .mat-mdc-card-avatar img {
        width: 100%;
      }

      .mat-mdc-card-title {
        display: flex;
        justify-content: space-between;
        font-size: var(--font-size-medium) !important;

        div:nth-child(2) {
          color: var(--color-label);
          font-weight: var(--font-weight-normal);
          font-size: var(--font-size-small);
        }
      }

      .mat-mdc-card-subtitle {
        color: var(--color-primary) !important;
      }
    }

    .mat-mdc-card:nth-child(even) {
      background: var(--color-row-odd);
    }

    .is-loading {
      display: flex;
      flex: 0 0 auto;
      height: 80px;
      width: 100%;
      align-items: center;
      justify-content: center;

      span {
        display: block;
        margin-left: 10px;
      }

      .mat-progress-spinner-indeterminate-animation {
        margin: 0;
      }
    }
  }
}
