om-staff-import-dashboard-page {
  .mat-mdc-tab-group {
    background: transparent !important;
  }
  om-staff-import-dashboard-grid {
    display: contents;
  }
  main {
    > section {
      display: flex;

      &:nth-child(2) {
        font-weight: var(--font-weight-bold);
        justify-content: center;
      }

      ag-grid-angular {
        height: 600px;
      }
      flex: 0 1 0;

      .mat-mdc-card {
        flex: 1 1 0;
        max-height: 220px;
        overflow-y: scroll !important;

        .mat-mdc-form-field {
          width: 100%;
          max-width: 400px;
        }
      }

      om-data-table-input {
        ag-grid-angular {
          height: 150px;
          .ag-header {
            display: none;
          }
        }
        > div {
          display: none;
        }
      }
    }
  }
}
