om-bankangle {
  height: 100vh;
  display: flex;
  flex-direction: column;
  // display: contents;
  > *:not(.om-banner):not(router-outlet),
  router-outlet + * {
    height: 1px;
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
  }

  // autohide IE scrollbars when not scrolling
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.mat-drawer-container {
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: var(--color-background-1);
  .mat-drawer {
    border-right: none;
    position: absolute;
    .mat-drawer-inner-container {
      display: flex;
      flex-direction: column;
      overflow-x: hidden;
      > *:first-child:last-child {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
      }
    }
    &.mat-drawer-end {
      .mat-toolbar {
        font-weight: var(--font-weight-bold);
        color: var(--color-primary);
        > .mat-mdc-icon-button {
          position: absolute;
          right: 5px;
        }
      }
    }
    &.mat-drawer-side {
      z-index: var(--z-index-layer-1);
      &.mat-drawer-end {
        border-left: none;
        box-shadow: 6px 0px 6px 6px var(--color-stroke);
      }
    }
    &.mat-drawer-over {
      z-index: 3;
    }
  }
  .mat-drawer-content {
    z-index: 2;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    > *:not(router-outlet):not(.mat-toolbar):not(.om-banner):not([header]):not([footer]) {
      flex-grow: 1;
      flex-shrink: 1;
      display: flex;
      flex-direction: column;
    }
  }
}

.om-main-view-router-outlet + *,
.om-main-view-router-outlet + * .mat-drawer-content {
  > .mat-toolbar {
    p {
      flex-shrink: 1;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .mat-mdc-tab-group:not(.is-inside-container) {
    flex-grow: 1;
    margin: var(--spacing-large);
    .mat-mdc-tab-body-wrapper {
      flex-grow: 1;
      border-radius: var(--border-radius);
      @include mat.elevation($elevation-value);
      .mat-mdc-tab-body-content {
        display: flex;
        flex-direction: column;
        > * > main,
        > main {
          margin: 0;
          width: 100%;
          box-shadow: none;
        }
      }
    }
  }
}

.mat-mdc-tab-header {
  background: var(--color-background-2);
  box-shadow: var(--box-shadow-underline);
  border: none;
  .mdc-tab,
  .mat-mdc-tab-link {
    opacity: 1;
    height: var(--toolbar-height);
    .mdc-tab__text-label {
      color: var(--color-primary);
      transition-delay: unset;
      transition: none;
    }
    &.mdc-tab--active {
      color: inherit;
      font-weight: var(--font-weight-bold);
      border-bottom: 3px solid var(--color-primary);
    }
  }
  .mdc-tab-indicator {
    display: none;
  }
  .mat-mdc-tab-header-pagination {
    height: var(--toolbar-height) !important;
    &.mat-mdc-tab-header-pagination-before,
    &.mat-mdc-tab-header-pagination-after {
      box-shadow:
        0 2px 4px -1px rgba(0, 0, 0, 0.2),
        0 4px 5px rgba(0, 0, 0, 0.14),
        0 1px 10px rgba(0, 0, 0, 0.12);
    }
  }
  .mat-mdc-button-base {
    display: block;
    margin: calc(((var(--toolbar-height) - var(--button-height)) / 2) - 1) var(--spacing-large) 0px var(--spacing-large);
  }
  &.is-accent {
    .mdc-tab--active,
    .mdc-tab-indicator--active {
      .mdc-tab__text-label {
        color: var(--color-accent);
      }

      border-bottom-color: var(--color-accent);
    }
  }
}

.mat-toolbar {
  .mat-mdc-tab-header {
    box-shadow: none;
  }
}

.om-alternate-tabs {
  .mat-mdc-tab-header {
    background: transparent;
    border: none;
    box-shadow: none;
    padding: 0 var(--spacing-large);
    .mat-mdc-tab-labels {
      justify-content: left;
      .mdc-tab {
        background: var(--color-background-2);
        color: var(--color-primary);
        border-top-left-radius: var(--border-radius);
        border-top-right-radius: var(--border-radius);
        border: 1px solid var(--color-stroke);
        border-bottom: none;
        height: 36px;
        opacity: 1;
        flex-grow: 0;
        + .mdc-tab {
          margin-left: -1px;
        }
        &.mdc-tab--active {
          color: var(--color-primary);
          background: var(--color-background-3);
          .mdc-tab-indicator {
            top: 0;
            display: block;
            border-top-left-radius: var(--border-radius);
            border-top-right-radius: var(--border-radius);
            background-color: var(--color-accent);
            height: 4px;
            transition: none;
          }
        }
      }
    }
  }
}

.om-widget-wrapper {
  .mdc-tab {
    min-width: fit-content !important;
  }
}

.om-toolbar-header {
  h2 {
    margin: 0;
    font-size: var(--font-size-x-large);
    font-weight: normal;
  }

  section[right] {
    .om-toolbar-header__note {
      font-size: var(--font-size-small);
      display: flex;
      align-items: center;
      font-weight: 400;
      color: var(--color-label);

      span {
        padding-left: var(--spacing-small);
        padding-right: var(--spacing-large);
      }
    }
  }

  section:not([right]) {
    .om-toolbar-header__note {
      background-color: var(--color-background-1);
      padding: var(--spacing-small) var(--spacing-medium);
      border-radius: 2px;
      font-size: 10px;
      line-height: 12px;
      font-weight: 500;
    }
  }

  .om-toolbar-header__nav {
    min-height: 24px;
    height: 24px;
    padding-top: var(--spacing-medium);
  }

  .om-toolbar-header__tabs {
    --toolbar-height: 48px;

    padding-right: 0;
    padding-left: 0;

    .mat-mdc-icon-button {
      margin-right: calc(-1 * var(--spacing-large));
    }

    .om-tab-secondary-info {
      color: var(--color-secondary);
      font-size: var(--font-size-small);
      font-weight: 400;
    }
  }
}
