$esg-breakpoint-small: 550px;
$esg-breakpoint: 740px;
$esg-breakpoint-large: 1000px;
$esg-offset-for-background: 10px;
$esg-border-radius: 6px;
$esg-border-radius-small: 4px;

$esg-green-gradient-start: rgba(107, 165, 72, 1) 0%;
$esg-green-gradient-end: rgba(26, 50, 12, 1) 100%;
$esg-social-gradient-start: rgba(233, 155, 0, 1) 0%;
$esg-social-gradient-end: rgba(32, 23, 4, 1) 100%;
$esg-sustainable-gradient-start: rgba(58, 130, 188, 1) 0%;
$esg-sustainable-gradient-end: rgba(14, 38, 57, 1) 100%;

om-issuer-profile-esg-tab {
  .mat-mdc-tab-body {
    &:first-child {
      .om-table {
        th {
          width: 250px;
        }
      }
    }
  }

  h4 {
    margin: var(--spacing-large);
  }

  $esg-image-size: 32px;
  $esg-image-scale: 5;
  $esg-image-transition: 250ms ease-in-out;
  .om-esg-images-container {
    display: inline-block;
    height: $esg-image-size + var(--spacing-medium) + var(--spacing-medium);
    margin: 0;
    padding: 0;
    white-space: nowrap;
    li {
      height: $esg-image-size + var(--spacing-medium) + var(--spacing-medium);
      width: $esg-image-size + var(--spacing-small);
      padding: var(--spacing-medium) var(--spacing-small) var(--spacing-medium) 0;

      overflow: visible;
      display: inline-block;
      position: relative;
      img {
        position: absolute;
        width: $esg-image-size;
        left: 0;
        top: var(--spacing-medium);
        pointer-events: none;
        transition: left $esg-image-transition, transform $esg-image-transition;
      }
    }

    &:hover {
      img {
        left: -#{$esg-image-size * (($esg-image-scale - 1) / 2)};
      }
      li:hover {
        img {
          z-index: 1;
          transform: scale($esg-image-scale);
          left: 0;
        }
        ~ * {
          img {
            left: #{$esg-image-size * (($esg-image-scale - 1) / 2)};
          }
        }
      }
    }
  }
}

.om-esg-container {
  display: block;
  @media (min-width: $esg-breakpoint-small) {
    display: grid;
  }
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas: '. .';

  a {
    cursor: pointer;
  }
}

.om-esg-header {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: var(--spacing-large);

  @media (min-width: $esg-breakpoint-small) {
    margin-right: -$esg-offset-for-background;
    padding-right: calc(var(--spacing-large) + $esg-offset-for-background);
    border-radius: $esg-border-radius !important;
  }

  &.is-green {
    background: linear-gradient(180deg, $esg-green-gradient-start, $esg-green-gradient-end) !important;
    @media (min-width: $esg-breakpoint-small) {
      // using a png - as the svg version is massive!
      background: url($assets + 'img/esg/bond_background_green.png') !important;
      background-size: 380px auto !important;
      background-position: center center !important;
    }
    @media (min-width: $esg-breakpoint) {
      background-size: 590px auto !important;
    }
    @media (min-width: $esg-breakpoint-large) {
      background-size: 360px auto !important;
    }
  }
  &.is-social {
    background: linear-gradient(180deg, $esg-social-gradient-start, $esg-social-gradient-end) !important;
    @media (min-width: $esg-breakpoint-small) {
      background: url($assets + 'img/esg/bond_background_social.svg') !important;
      background-size: 280px auto !important;
      background-position: center center !important;
    }
    @media (min-width: $esg-breakpoint) {
      background-size: 585px auto !important;
    }
    @media (min-width: $esg-breakpoint-large) {
      background-size: 340px auto !important;
    }
  }
  &.is-sustainable {
    background: linear-gradient(180deg, $esg-sustainable-gradient-start, $esg-sustainable-gradient-end) !important;
    @media (min-width: $esg-breakpoint-small) {
      background: url($assets + 'img/esg/bond_background_sustainable.svg') !important;
      background-size: 295px auto !important;
      background-position: center center !important;
    }
    @media (min-width: $esg-breakpoint) {
      background-size: 585px auto !important;
    }
    @media (min-width: $esg-breakpoint-large) {
      background-size: 350px auto !important;
    }
  }

  button {
    position: absolute;
  }

  &__title {
    margin: 0 auto;
    text-align: center;
    width: 100%;
    h2 {
      color: white;
      font-size: var(--font-size-x-large);
      line-height: 26px;
      margin: -10px 0 var(--spacing-medium) 0;
      @media (min-width: $esg-breakpoint) {
        margin: -10px 10px var(--spacing-medium) 10px;
      }
    }
    img {
      width: 82px;
      margin-top: 0;
    }
  }

  dl {
    color: white;
    margin: auto 0 var(--spacing-medium);

    dt {
      font-size: 10px;
      font-weight: var(--font-weight-bold);
      line-height: 14px;
      text-transform: uppercase;
      margin-top: 10px;
    }

    dd {
      font-size: var(--font-size-medium);
      line-height: 14px;
      margin-left: var(--spacing-medium);
      word-break: break-all;
      display: block;

      a {
        display: block;
      }
    }
  }

  a {
    color: white;
    text-decoration: underline;
  }
}

.om-esg-overview {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: minmax(auto, auto) 1fr minmax(auto, auto);
  gap: var(--spacing-medium) var(--spacing-medium);
  grid-auto-flow: row;
  grid-template-areas:
    'standards categories'
    'proceeds categories'
    'actions actions';

  z-index: 10;
  background-color: var(--color-background-3);
  padding: var(--spacing-medium);
  border-top-left-radius: $esg-border-radius;
  border-top-right-radius: $esg-border-radius;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: var(--font-size-small);
    line-height: 14px;
  }
}

.om-overview-section {
  border: 1px solid var(--color-stroke);
  border-radius: $esg-border-radius-small;
  background-color: white;

  padding: var(--spacing-large);
  h3 {
    font-size: var(--font-size-medium);
    margin: 0 0 var(--spacing-medium) 0;
  }
}
.om-esg-standards {
  grid-area: standards;
  img {
    width: 100%;
    max-width: 175px;
  }
}

.om-esg-proceeds {
  grid-area: proceeds;
  li {
    margin-top: var(--spacing-medium);
  }
  .om-display-more {
    margin-bottom: -13px;
  }
}

.om-esg-categories {
  grid-area: categories;
  .om-display-more {
    margin-top: 4px;
    margin-bottom: -12px;
  }
}

.om-esg-actions {
  grid-area: actions;
  overflow: auto;
  ul {
    display: flex;
  }
}

.om-esg-categories-grid {
  @include omImageGrid();
  @media (min-width: $esg-breakpoint) {
    grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
  }
}
