om-list-input {
  display: block;
  margin-top: -10px;

  .mat-mdc-list {
    width: 100%;
    margin-bottom: -12px;
    p {
      flex-grow: 1;
      word-break: break-all;
    }
    .mat-mdc-list-item {
      font-size: 14px;
      border-top: 1px solid var(--color-stroke);
      padding: 12px;

      om-date-input,
      .mat-mdc-form-field-infix,
      .mat-mdc-list-item__primary-text {
        padding: 0px !important;
      }

      om-select-input {
        padding-right: 10px;
      }
    }
    .mat-mdc-list-item:first-child {
      border-top: none;
    }
    .mat-form-field-disabled {
      om-list-input {
        .mat-mdc-list {
          opacity: var(--disabled-opacity);
        }
      }
    }
  }
}
