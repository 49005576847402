$tag-color-text: var(--color-label);
$tag-color-field: var(--color-accent);
$tag-color-edited: var(--color-alert); // TODO: correct colour?

om-pdf-viewer {
  position: relative;
  height: 100%;
  width: 100%;
  margin: auto;
  display: contents;

  .pdf-viewer-wrapper {
    height: 100vh;
    text-align: center;
  }

  pdf-viewer {
    display: block;
    height: 100%;
    max-width: 100%;
    .annotationLayer {
      .linkAnnotation {
        overflow: hidden;
        > a,
        > a:hover {
          opacity: 0;
        }
        > a {
          position: absolute;
          font-size: 1em;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;

          cursor: pointer;
          opacity: 1;
          &[class^='om-pdf-content-'] {
            border-bottom: 0.1em solid $tag-color-text;
          }
          &[class^='om-pdf-input-'] {
            border-bottom: 0.1em solid $tag-color-field;
          }
          &.is-edited {
            border-bottom: 0.1em solid $tag-color-edited;
          }

          transition:
            border-bottom-width 250ms ease-in-out,
            opacity 250ms ease-in,
            background-color 1s ease-in-out;
          &.is-alert {
            border-bottom-color: var(--color-alert);
          }
          &:hover,
          &.is-active {
            background: none;
            opacity: 0.4;
            border-bottom-width: 1em;
            transition:
              border-bottom-width 250ms ease-in-out,
              opacity 250ms ease-out;
          }
          &.om-highlight-pulse {
            background-color: var(--color-alert);
          }

          &::after {
            content: '';
            display: none;
          }

          &:not([class]) {
            cursor: default;
          }
        }
      }
    }

    .page[data-page-number~='1'] {
      margin-top: var(--spacing-large) !important;
    }

    .page .canvasWrapper {
      height: 100%;
      @include mat.elevation($elevation-value);

      canvas {
        height: inherit;
        width: 100%;
      }
    }

    &.has-sidebar:not(.fit-to-page) {
      margin-right: 0;
    }
  }

  &:not(.om-has-flexit-tag-content) {
    pdf-viewer {
      .annotationLayer {
        .linkAnnotation {
          > a {
            &:not(.is-alert) {
              &[class^='om-pdf-content-'] {
                opacity: 0;
              }
            }
          }
        }
      }
    }
  }
  &:not(.om-has-flexit-tag-input) {
    pdf-viewer {
      .annotationLayer {
        .linkAnnotation {
          > a {
            &:not(.is-alert) {
              &[class^='om-pdf-input-'] {
                opacity: 0;
              }
            }
          }
        }
      }
    }
  }

  &.has-controls {
    pdf-viewer {
      margin-top: 0;
    }
    om-pdf-viewer-controller {
      margin: var(--spacing-medium);
    }
  }

  > h1 {
    margin-top: 80px;
    text-align: center;
  }
}
