om-notification-card {
  .mat-mdc-card {
    box-shadow: none;
    border-radius: 0;
    border: 1px solid var(--color-stroke);
    // flex-direction: column;
    .mat-mdc-card-header {
      .mat-mdc-card-header-text {
        margin: 0;
      }
      .mat-mdc-card-title {
        color: var(--color-primary);
        font-size: var(--font-size-medium);
        font-weight: var(--font-weight-bold);
      }
      .mat-mdc-card-subtitle {
        font-size: var(--font-size-small);
        margin-bottom: 12px;
      }
      .mat-mdc-card-title,
      .mat-mdc-card-subtitle {
        line-height: normal;
      }
    }
    .mat-mdc-card-content {
      color: var(--color-primary);
      display: flex;

      p {
        flex-grow: 1;
        margin-top: var(--spacing-large);
      }
      .mat-mdc-card-actions {
        flex-shrink: 0;
        padding: 0;
        margin-top: -9px;
        margin-left: 8px;
      }
    }
  }

  &.is-notification {
    .mat-mdc-card {
      padding-left: 32px;
      &::before {
        content: '-';
        display: block;
        width: 2px;
        height: 2px;
        position: absolute;
        left: 14px;
        top: 22px;
        color: transparent;
        border-radius: 50%;
        border: 2px solid var(--color-stroke);
      }
    }
    &.is-notification-unread {
      .mat-mdc-card {
        background-color: var(--color-accent-soft-background);
        &::before {
          background-color: var(--color-alert);
          border-color: var(--color-alert);
        }
      }
    }
  }

  + om-notification-card {
    .mat-mdc-card {
      margin-top: -1px;
    }
  }
}
