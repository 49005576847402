om-issuer-profile {
  .mat-mdc-tab-nav-bar {
    .mat-mdc-tab-links {
      justify-content: center;
    }
  }

  .mat-mdc-tab-group {
    margin: 0 !important;
  }

  h4 {
    color: var(--color-primary);
    margin-top: 0;
  }
  main,
  om-carousel-slide {
    > * {
      display: block;
    }
  }

  .is-empty-state {
    padding: calc(3 * var(--spacing-large)) var(--spacing-large);
    h3 {
      font-size: var(--font-size-x-large);
      margin: 0;
    }
    p {
      margin: var(--spacing-large) 0 0;
    }
    .mat-mdc-button {
      width: fit-content;
    }
    > * + * {
      margin-top: var(--spacing-large);
    }
  }
}

@import './components/issuer-profile-about-tab/issuer-profile-about-tab.component.scss';
@import './components/issuer-profile-dates-overview-widget/issuer-profile-dates-overview-widget.component.scss';
@import './components/issuer-profile-document-list/issuer-profile-document-list.component.scss';
@import './components/issuer-profile-entities-widget/issuer-profile-entities-widget.component.scss';
@import './components/issuer-profile-entities-overview-widget/issuer-profile-entities-overview-widget.component.scss';
@import './components/issuer-profile-esg-tab/issuer-profile-esg-tab.component.scss';
@import './components/issuer-profile-esg-sdg-modal/issuer-profile-esg-sdg-modal.component';
@import './components/issuer-profile-levels-overview-widget/issuer-profile-levels-overview-widget.component.scss';
@import './components/issuer-profile-notes-overview-widget/issuer-profile-notes-overview-widget.component.scss';
@import './components/issuer-profile-overview-tab/issuer-profile-overview-tab.component.scss';
@import './components/issuer-profile-programmes-tab/issuer-profile-programmes-tab.component.scss';
@import './components/issuer-profile-supports-widget/issuer-profile-supports-widget.component.scss';
@import './components/issuer-profile-toolbar/issuer-profile-toolbar.component.scss';
@import './components/issuer-profile-fallback-provisions-widget/issuer-profile-fallback-provisions-widget.component.scss';
@import './components/issuer-profile-entities-ratings-widget/issuer-profile-entities-ratings-widget.component.scss';
