om-version-history-content {
  om-document-compare-viewer {
    margin-top: 0;
    overflow: scroll;
  }
  .no-documents {
    color: var(--color-primary);
    display: flex;
    flex-direction: column;
    margin: auto;
    align-items: center;
    justify-content: center;
    padding: 56px 32px;
    font-size: var(--font-size-large);
    font-weight: var(--font-weight-normal);
    .mat-icon {
      height: 50px;
      width: 60px;
      opacity: 0.5;
      margin-bottom: 10px;
    }
  }
}
