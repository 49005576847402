$internal-element-height: 25px;
$widget-height: 33px;

$hover-internal-padding: 4px;
$spacer-padding: 10px;

$icon-view-container-width: 32px;
$icon-download-container-width: 27px;
$icon-transitions:
  visibility 0s,
  opacity 0.5s linear;

$hover-background-color: var(--color-background-4);

om-document-list {
  ul,
  li {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    width: calc(
      100% - 20px
    ); // * 20px space for last widget to be able to extend on hover without overflowing container
  }
}

.document-download-wrapper {
  margin-right: 12px;
  margin-top: 12px;
}

.document-download {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: $widget-height;
  border: 1px solid var(--color-stroke);
  border-radius: calc($widget-height / 2);
  background: var(--color-row-odd);
  transition: width 0.25s;

  .spacer {
    position: relative;
    display: flex;
    align-items: center;
    visibility: hidden;

    padding: 0 10px;
    height: $internal-element-height;

    font-size: var(--font-size-medium);
    font-weight: 600;
  }

  .doc-info {
    position: absolute;
    left: 3px;
    right: -2px;
    font-size: var(--font-size-medium);

    display: flex;
    align-items: center;

    padding: 0 7px;
    margin: 0;

    height: $internal-element-height;

    white-space: nowrap;
    border-radius: calc($internal-element-height / 1) 0 0 calc($internal-element-height / 1);
    color: var(--color-secondary);

    cursor: pointer;
    &--no-link {
      cursor: initial;
    }

    .icon {
      position: absolute;
      right: 9px;
      opacity: 0;
      visibility: hidden;
      transition: $icon-transitions;

      > * {
        width: 18px;
        stroke-width: 0;
      }
    }
  }

  .icon-action {
    position: absolute;
    right: $hover-internal-padding;
    opacity: 0;
    visibility: hidden;

    display: flex;
    justify-content: center;
    align-items: center;

    height: $internal-element-height;
    width: $icon-download-container-width;

    transition: $icon-transitions;

    color: var(--color-secondary);
    border: none;
    cursor: pointer;
    border-radius: 0 calc($internal-element-height / 2) calc($internal-element-height / 2) 0;
    background-color: transparent;

    > .mat-mdc-icon-button {
      right: 0;
      &:hover {
        background: $hover-background-color !important;
        color: var(--color-secondary) !important;
      }
    }
  }

  &.document-download--is-focussed,
  &:hover {
    width: calc(
      100% + $icon-view-container-width + $icon-download-container-width + $hover-internal-padding - $spacer-padding
    );
    z-index: 10;
    border-color: var(--color-navigation-3);

    .doc-info {
      font-weight: 600;
      right: calc($icon-download-container-width + $hover-internal-padding);
      padding-right: 0;

      .icon {
        opacity: 1;
        visibility: visible;
      }

      &--no-link {
        .icon {
          opacity: 0;
          visibility: hidden;
        }
      }

      &:hover {
        background-color: $hover-background-color;
      }

      &--no-link {
        background-color: transparent;
        &:hover {
          background-color: transparent;
        }
      }
    }
    .icon-action {
      opacity: 1;
      visibility: visible;
    }
  }
}

.document-download__title {
  padding: 0 10px;
}
