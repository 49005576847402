om-acronym-icon-cell-renderer {
  display: flex;
  align-items: center;
  height: 100%;
  div {
    div {
      display: inline-block;
      width: 22px;
      height: 22px;
      border-radius: 4px;
      text-align: center;
      line-height: 24px;
      font-size: 9px;
      font-weight: 600;
      letter-spacing: 1.1px;
      margin-right: 4px;
      color: var(--color-acronym-icon-contrast);
      background: var(--color-acronym-icon-1);
      &.variation-2 {
        background: var(--color-acronym-icon-2);
      }
      &.variation-3 {
        background: var(--color-acronym-icon-3);
      }
    }
    &.is-single-value {
      display: flex;
      justify-content: center;
      width: 100%;
    }
  }
}
