om-search {
  .mat-mdc-menu-panel.om-advanced-menu {
    max-width: 100% !important;
  }

  om-shared-search {
    .mat-mdc-input-element,
    .mat-icon {
      color: currentColor !important;
    }

    input::placeholder {
      color: currentColor !important;
    }
  }
}

.om-advanced-menu {
  width: 246px;
  max-height: 500px;
  margin: 6px 32px 0 0px;

  * {
    font-size: var(--font-size-small);
    font-weight: 700;
  }
  .mat-mdc-menu-item {
    height: 100%;
    padding: 0;
  }
  .mat-mdc-menu-content {
    padding: 0;
  }
  .mat-mdc-subheader {
    height: 30px;
    margin: 0;
    padding: 0 var(--spacing-large);
  }
  .mat-mdc-card {
    padding: var(--spacing-small);
    border: none;
    border-radius: 0;
    border-bottom: 1px solid var(--color-stroke);
    .mat-mdc-card-avatar {
      height: 20px;
      width: 20px;
      margin: 0;
    }
    .mat-mdc-card-header-text {
      margin-left: var(--spacing-medium);
      margin-right: 0;
      .mat-mdc-card-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .mat-icon {
          svg {
            width: 15px;
          }
        }
      }
    }
    .mat-mdc-card-subtitle {
      margin-bottom: 0;
      font-weight: 200;
    }
  }

  .mat-toolbar {
    padding: 0;
    p {
      padding-left: var(--spacing-medium);
      font-size: var(--font-size-small);
      font-weight: 700;
    }
  }
}
