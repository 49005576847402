om-issuer-profile-supports-widget {
  .mat-mdc-table {
    overflow: hidden;
    width: 100%;
    border-bottom-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
    .issuer-about-supports-table-label {
      width: 200px;
    }
    .mat-mdc-row {
      .mat-mdc-cell {
        border-bottom: none;
        padding: 2px;
      }
    }
  }
}
