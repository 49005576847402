om-level-inputs-bullet {
  mat-form-field {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 27px;
  }

  section[right] {
    .mat-mdc-button {
      margin-right: 20px;
    }
  }

  ag-grid-angular {
    height: unset;
  }

  .om-widget-wrapper {
    > * {
      min-height: 100%;
      max-width: 33%;
    }
  }

  main > div {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-bottom: 50px !important;
    > div {
      > .mat-toolbar {
        height: 66px;
        p {
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 80%;
        }
        span:last-child {
          cursor: pointer;
        }
        .mat-icon {
          margin-bottom: 5px;
        }
      }
    }
    > div:last-child {
      flex-grow: 1;
      > ag-grid-angular {
        height: 100%;
      }
    }
  }
}

&.is-large-screen {
  om-level-inputs-bullet {
    .om-widget-wrapper {
      om-level-inputs-widget {
        min-height: 100%;
      }
    }
  }
}
