om-document-generator-page-document {
  om-action-menu {
    position: fixed;
    visibility: hidden;
    display: block;
    overflow: hidden;
    height: 0;
    width: 0;
    line-height: 0;
    .mat-mdc-menu-trigger {
      height: 0 !important;
      width: 0 !important;
      line-height: 0 !important;
      .mdc-button__label {
        display: none;
      }
    }
  }

  .om-notification-toolbar {
    position: absolute;
    top: 52px;
    z-index: 2;
  }

  om-pdf-viewer {
    z-index: 1;
  }
}
