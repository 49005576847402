om-s-ui-kit {
  .mat-mdc-card {
    display: inline-block;
    width: fit-content;
    margin: 16px;
    .mat-mdc-card-header-text {
      text-align: center;
    }

    .mat-mdc-radio-group {
      display: block;
    }
    .mat-mdc-checkbox,
    .mat-mdc-radio-button,
    .mat-mdc-slide-toggle {
      display: block;
      margin: 8px;
    }
    .mat-mdc-slider {
      width: 100%;
    }

    &.buttons-card {
      td.header {
        padding: 16px 0px 4px;
      }
    }
    &.badges-card {
      .mat-mdc-card-content > * {
        margin: 12px 12px;
        display: inline-block;
      }
    }
  }

  .om-color-chart {
    width: 100%;
    font-size: var(--font-size-medium);
    th {
      padding: 16px;
    }
    &.navigation {
      color: var(--color-navigation-contrast);
      tr {
        &:nth-child(1) {
          background: var(--color-navigation-1);
        }
        &:nth-child(2) {
          background: var(--color-navigation-2);
        }
        &:nth-child(3) {
          background: var(--color-navigation-3);
        }
      }
    }
    &.background {
      color: var(--color-background-contrast);
      tr {
        &:nth-child(1) {
          background: var(--color-background-1);
        }
        &:nth-child(2) {
          background: var(--color-background-2);
        }
        &:nth-child(3) {
          background: var(--color-background-3);
        }
      }
      th {
        &:nth-child(1) {
          color: var(--color-primary);
        }
        &:nth-child(2) {
          color: var(--color-secondary);
        }
        &:nth-child(3) {
          color: var(--color-label);
        }
      }
    }
    &.rows {
      tr {
        &:nth-child(odd) {
          background: var(--color-background-2);
        }
        &:nth-child(even) {
          background: var(--color-row-odd);
        }
      }
      th {
        &:nth-child(1) {
          color: var(--color-primary);
        }
        &:nth-child(2) {
          color: var(--color-secondary);
        }
        &:nth-child(3) {
          color: var(--color-label);
        }
      }
    }
    &.colors {
      color: var(--color-navigation-contrast);
      tr {
        th:nth-child(1) {
          background: var(--color-navigation-2);
        }
        th:nth-child(2) {
          background: var(--color-background-2);
        }
        &.label {
          th {
            color: var(--color-label);
            &:nth-child(3),
            &:nth-child(4) {
              color: var(--color-label-contrast);
            }
            &:nth-child(3) {
              background: var(--color-label);
            }
            &:nth-child(3):hover,
            &:nth-child(4) {
              background: var(--color-label-hover);
            }
            &:nth-child(5) {
              background: var(--color-label-background);
              color: var(--color-primary);
            }
          }
        }
        &.accent {
          th {
            color: var(--color-accent);
            &:nth-child(3),
            &:nth-child(4) {
              color: var(--color-accent-contrast);
            }
            &:nth-child(3) {
              background: var(--color-accent);
            }
            &:nth-child(3):hover,
            &:nth-child(4) {
              background: var(--color-accent-hover);
            }
            &:nth-child(5) {
              background: var(--color-accent-background);
              color: var(--color-primary);
            }
          }
        }
        &.accent-soft {
          th {
            color: var(--color-accent-soft);
            &:nth-child(3),
            &:nth-child(4) {
              color: var(--color-accent-soft-contrast);
            }
            &:nth-child(3) {
              background: var(--color-accent-soft);
            }
            &:nth-child(3):hover,
            &:nth-child(4) {
              background: var(--color-accent-soft-hover);
            }
            &:nth-child(5) {
              background: var(--color-accent-soft-background);
              color: var(--color-primary);
            }
          }
        }
        &.alert {
          th {
            color: var(--color-alert);
            &:nth-child(3),
            &:nth-child(4) {
              color: var(--color-alert-contrast);
            }
            &:nth-child(3) {
              background: var(--color-alert);
            }
            &:nth-child(3):hover,
            &:nth-child(4) {
              background: var(--color-alert-hover);
            }
            &:nth-child(5) {
              background: var(--color-alert-background);
              color: var(--color-primary);
            }
          }
        }
        &.warning {
          th {
            color: var(--color-warning);
            &:nth-child(3),
            &:nth-child(4) {
              color: var(--color-warning-contrast);
            }
            &:nth-child(3) {
              background: var(--color-warning);
            }
            &:nth-child(3):hover,
            &:nth-child(4) {
              background: var(--color-warning-hover);
            }
            &:nth-child(5) {
              background: var(--color-warning-background);
              color: var(--color-primary);
            }
          }
        }
      }
    }
    &.stroke {
      tr {
        color: var(--color-stroke);
        th {
          div {
            border: 1px solid var(--color-stroke);
            padding: 8px;
          }
          &:nth-child(1) {
            background: var(--color-background-2);
          }
          &:nth-child(2) {
            background: var(--color-navigation-2);
          }
        }
      }
    }
  }
}
