om-pricer-debug {
  .mat-mdc-tab-nav-bar {
    .mdc-tab--active {
      border-color: var(--color-accent) !important;
      .mdc-tab__text-label {
        color: var(--color-accent) !important;
      }
    }
  }
  .pricer-debug-container {
    margin: var(--spacing-large);
    padding: 40px 20px 16px 20px;
    border-radius: var(--border-radius);
    border: 1px solid var(--color-stroke);
    background: var(--color-background-emphasis);
    box-shadow:
      0px 2px 6px 2px rgba(0, 0, 0, 0.15),
      0px 1px 2px 0px rgba(0, 0, 0, 0.3);

    form {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      gap: 20px;
      margin-left: 32px;
      margin-bottom: var(--spacing-large);

      section,
      .swap-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
        gap: 8px;

        > span {
          width: 80px;
          text-align: right;
          white-space: nowrap;
          flex-shrink: 0;
          font-size: var(--font-size-small);
          font-weight: var(--font-weight-semi-bold);
        }

        .mat-mdc-form-field {
          width: 180px;
          * .mat-mdc-form-field-infix {
            height: 38px;
          }
          margin-bottom: 0 !important;
        }

        .mdc-icon-button {
          position: absolute;
          right: -40px;
        }
      }

      section[centre] {
        flex-direction: column;
        align-items: flex-start;
        margin-right: 20px;

        .swap-row {
          margin-bottom: 10px;

          &:last-of-type {
            margin-bottom: var(--spacing-medium);
          }
        }

        .mdc-button {
          margin-left: 72px;
          .mat-icon {
            height: 12px;
            line-height: 12px;
            margin-right: 2px;
          }
        }
      }

      section[right] {
        width: 100%;
      }
    }

    > section {
      margin-top: var(--spacing-large) !important;
      display: flex;
      justify-content: center;
      gap: 8px;

      .mdc-button {
        width: 120px;
      }
    }
  }

  .grid-container {
    .grid-actions {
      display: flex;
      justify-content: flex-end;
      .mat-icon {
        height: 12px;
        line-height: 12px;
        margin-right: 2px;
      }
    }

    ag-grid-angular {
      height: 100%;
      .ag-root-wrapper {
        width: fit-content;
        max-width: 100%;
        .ag-root-wrapper-body {
          width: fit-content;
          max-width: 100%;
          .ag-root {
            background: var(--color-background-emphasis);
            width: fit-content;
            max-width: 100%;
          }
        }
      }

      .ag-header {
        display: none;
      }

      .ag-body-horizontal-scroll {
        height: 8px !important;
        max-height: 8px !important;
        min-height: 8px !important;
        visibility: visible !important;
        opacity: 1 !important;
      }

      .ag-center-cols-viewport {
        border: 1px solid var(--color-stroke);
        border-radius: var(--border-radius-small);
        min-height: fit-content !important;
        max-width: 100%;
        .ag-center-cols-container {
          min-height: fit-content !important;
          .ag-row {
            border: none !important;
            background: var(--color-background-2);
            &.priced-swap-row {
              background: var(--color-background-emphasis);
              font-weight: var(--font-weight-semi-bold);
            }
            &:not(:last-child) {
              border-bottom: 1px solid var(--color-stroke) !important;
            }
            .ag-cell {
              &:not(.ag-cell-range-right, .ag-cell-range-single-cell) {
                border-right: 1px solid var(--color-stroke) !important;
              }
            }
          }
        }
      }

      .ag-pinned-left-cols-container {
        .ag-row {
          background: var(--color-background-emphasis);
          text-align: right;
          line-height: 22px !important;
          font-size: var(--font-size-medium);
          font-weight: var(--font-weight-semi-bold);
          border: none !important;
          .ag-cell {
            position: relative;
            border-right: none !important;
            &.ag-cell-focus,
            &.ag-cell-range-selected {
              border: none !important;
              background: var(--color-background-emphasis) !important;
            }

            &.om-units-percentage {
              &::after {
                content: '(%)';
                position: absolute;
                top: 12px;
                right: 12px;
                font-size: var(--font-size-small);
                font-weight: var(--font-weight-normal);
                color: var(--color-label);
              }
            }
            &.om-units-bps {
              &::after {
                content: '(bps)';
                position: absolute;
                top: 12px;
                right: 12px;
                font-size: var(--font-size-small);
                font-weight: var(--font-weight-normal);
                color: var(--color-label);
              }
            }
          }
        }
      }
    }
  }

  .reference-rates-container {
    height: 100%;
    margin: var(--spacing-large);
    border-radius: var(--border-radius);
    border: 1px solid var(--color-stroke);
    background: var(--color-background-emphasis);
    box-shadow:
      0px 2px 6px 2px rgba(0, 0, 0, 0.15),
      0px 1px 2px 0px rgba(0, 0, 0, 0.3);

    ag-grid-angular {
      height: 100% !important;
      .ag-header-cell-label {
        justify-content: center;
      }
    }

    > div {
      justify-self: center;
      margin-top: 10%;
    }
  }
}
