$ag-grid-header-height: 36px;
$ag-grid-padding: 12px;

@import 'apps/bankangle/src/app/shared-ag-grid/cell-renderers/acronym-icon-cell-renderer/acronym-icon-cell-renderer.component.scss';
@import 'apps/bankangle/src/app/shared-ag-grid/cell-renderers/identity-cell-renderer/identity-cell-renderer.component.scss';

@import 'apps/bankangle/src/app/shared-ag-grid/filters/boolean-filter/boolean-filter.component.scss';
@import 'apps/bankangle/src/app/shared-ag-grid/filters/no-filter/no-filter.component.scss';
@import 'apps/bankangle/src/app/shared-ag-grid/filters/set-filter/set-filter.component.scss';
@import 'apps/bankangle/src/app/shared-ag-grid/filters/set-filter-lazy-load/set-filter-lazy-load.component.scss';
@import 'apps/bankangle/src/app/shared-ag-grid/filters/time-period-filter/time-period-filter.component.scss';

@mixin agCellIconAlignment {
  font-weight: 900;
  margin: 0 0 3px 6px;
  display: inline-block;
  vertical-align: middle;
  font-family: OmIconFont;
}

ag-grid-angular {
  flex-grow: 1;
  height: 100px;

  .ag-icon {
    font-family: 'agGridAlpine' !important;
  }

  &.is-auto-height {
    height: auto !important;
    .ag-body-viewport {
      min-height: 100px;
    }

    .ag-center-cols-viewport {
      min-height: unset !important;
    }
  }

  &.is-grouped-by-row {
    .ag-row {
      border: none;
    }
    .ag-row-group {
      font-weight: var(--font-weight-bold);
    }
  }

  .is-header-hidden {
    &.ag-header-cell {
      display: none;
    }
    &.ag-cell {
      &.is-header-hidden {
        padding: 0 !important;
        border-color: transparent !important;
        .ag-cell-wrapper {
          margin-left: 12px;
        }
      }
    }
  }

  .ag-root-wrapper {
    background-color: var(--color-background-2);
    border: none;
  }

  & * {
    scroll-behavior: unset;
  }

  .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected) {
    border: 0 !important;
  }

  .ag-column-drop-cell > span {
    color: var(--color-background-2);
  }

  .ag-horizontal-left-spacer,
  .ag-horizontal-right-spacer {
    visibility: hidden;
  }

  .ag-pinned-left-cols-container .is-changed .ag-cell:first-of-type {
    &::before {
      content: '';
      position: absolute;
      height: 100%;
      left: 0;
      width: 6px;
      z-index: 2;
      background-color: var(--color-alert);
    }
  }

  .ag-header {
    .ag-pinned-left-header,
    .ag-pinned-left-cols-container .ag-row .ag-cell:last-of-type {
      border-right-width: 1px;

      .ag-header-group-text {
        display: none;
      }
    }

    .hide-sort-order {
      .ag-sort-order {
        display: none !important;
      }
    }

    .ag-pinned-right-header {
      .ag-header-cell {
        width: fit-content;
        &::before {
          width: 0;
        }
      }
    }

    .ag-header-cell,
    .ag-header-icon {
      color: var(--color-primary);
    }
    .ag-header-cell {
      font-size: var(--font-size-small);
      padding: 0 $ag-grid-padding;

      .ag-header-select-all {
        margin-right: 0 !important;
      }

      &-moving {
        background-color: var(--color-secondary);
        color: var(--color-background-2);
      }

      .ag-header-cell-label {
        padding-left: 1px;
      }

      .ag-header-cell-text {
        white-space: pre-line;
        line-height: 1.2em;
      }

      &.ag-header-cell-tenor {
        .ag-header-cell-text {
          width: 100%;
          text-align: center;
        }
      }

      &:not(:last-of-type) {
        &::before {
          content: '';
          position: absolute;
          height: calc(100% - 20px);
          top: 10px;
          right: 0;
          width: 1px;
          z-index: 2;
          pointer-events: none;
          background-color: var(--color-stroke);
        }
      }

      .ag-sort-ascending-icon,
      .ag-sort-descending-icon {
        position: absolute;
        right: 10px;
        top: 10px;
        padding: 0;
      }

      .ag-header-cell-menu-button {
        z-index: 1;
        cursor: pointer !important;
      }

      .ag-header-cell-sorted-asc,
      .ag-header-cell-sorted-desc {
        .ag-header-cell-menu-button,
        .ag-filter-icon {
          padding-right: 0;
          margin-right: 20px;
        }
      }
      &.om-center {
        padding: 0;
        .ag-header-cell-text {
          margin: auto;
          text-align: center;
        }
        .ag-cell-label-container {
          padding: 0 16px;
        }

        .ag-header-cell-menu-button {
          margin-right: 12px;
        }

        .ag-header-cell-menu-button,
        .ag-sort-indicator-icon {
          position: absolute;
          right: 0;
        }

        &.om-center-action-column {
          .ag-cell-label-container {
            padding: 0;
          }
        }
      }
    }
    .ag-icon {
      .ag-icon-asc::before {
        content: '\f103';
      }
      .ag-icon-desc::before {
        content: '\f10e';
      }
    }

    .ag-header-cell-resize {
      right: -5px;
      &::after {
        width: 1px;
      }
    }
  }

  .ag-row {
    border-width: 1px;

    &.is-header {
      background: var(--color-background-3) !important;
      font-weight: var(--font-weight-bold);
      color: transparent;
      .is-header-content {
        color: var(--color-primary);
      }
    }

    &.is-changed {
      box-sizing: border-box;
      > .ag-cell {
        margin-left: -1px;
      }
    }
    &.is-alert {
      .ag-cell {
        background-color: var(--color-alert-background);
      }
    }
    &.is-error {
      .ag-cell {
        background-color: var(--color-warning-background);
      }
    }
    &.ag-row-selected {
      &.ag-row-odd {
        background: var(--color-row-odd);
      }
      &.ag-row-even {
        background: var(--color-row-even);
      }
      &::before {
        background: inherit;
      }
    }
    &.ag-row-group-expanded {
      .ag-cell.is-hidden-row-expanded {
        font-size: 0;
      }
    }
    &:not(.ag-row-hover) {
      &.is-row-greyed-out {
        background-color: var(--color-greyed-out);
      }
      .is-header {
        background-color: var(--color-background-3);
      }
    }
  }

  .ag-cell {
    font-size: var(--font-size-small);
    line-height: inherit;
    background-color: inherit;

    &:not(.ag-cell-inline-editing) {
      padding: 0 $ag-grid-padding;
    }

    &:not(.ag-cell-range-selected):not(.ag-cell-last-left-pinned) {
      border-right: 0;
    }

    &.is-alert:not(.ag-cell-inline-editing) {
      background-color: var(--color-alert-background);
      &::before {
        content: 'Required';
        color: var(--color-alert);
        position: absolute;
        left: 10px;
      }
    }

    &.is-error {
      background-color: var(--color-warning-background);
      border: 1px solid var(--color-warning);
      box-sizing: border-box;
    }

    &.is-uppercase {
      text-transform: capitalize;
    }

    &.is-bold {
      font-weight: var(--font-weight-bold);
    }

    &.allow-overflow {
      z-index: 1;
      overflow: visible;
      .ag-cell-wrapper {
        width: fit-content;
      }
    }

    &.indent-1 {
      padding-left: 46px !important;
    }
    &.indent-2 {
      padding-left: 72px !important;
    }
    &.indent-3 {
      padding-left: 100px !important;
    }
    &.is-child {
      &::before {
        content: '\21B3';
        padding-right: var(--spacing-medium);
      }
    }

    &.has-bloomberg-link {
      padding: 0;
      a {
        display: block;
        height: 100%;
        width: 100%;
        padding: 0 var(--spacing-medium);
      }
      &:hover::after {
        content: ' ';
        background-image: url($assets + 'img/icons/bloomberg-terminal-icon-black.png');
        pointer-events: none;
        display: inline-block;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        right: var(--spacing-small);
        top: 0;
        height: 100%;
        width: 18px;
      }
    }

    &.is-increase::after {
      @include agCellIconAlignment();
      content: '\ea1';
      color: var(--color-accent);
    }
    &.is-decrease::after {
      @include agCellIconAlignment();
      content: '\ead';
      color: var(--color-warning);
    }

    &.is-negative-increase::after {
      @include agCellIconAlignment();
      content: '\ea1';
      color: var(--color-warning);
    }
    &.is-positive-decrease::after {
      @include agCellIconAlignment();
      content: '\ead';
      color: var(--color-accent);
    }

    .ag-cell-wrapper {
      height: 100%;
      line-height: 100%;
      align-items: center;
    }

    .is-block-subtitle {
      color: var(--color-label);
      font-weight: var(--font-weight-normal);
      display: block;
      margin-top: var(--spacing-small);
    }

    .mat-mdc-icon-button.mat-mdc-button-base {
      &:hover {
        background: transparent !important;
        color: var(--color-primary);
        &.is-accent {
          color: var(--color-accent);
        }
        &.is-alert {
          color: var(--color-alert);
        }
        &.is-warning {
          color: var(--color-warning);
        }
        &.is-stroke {
          color: var(--color-stroke);
        }
      }
    }
    .mdc-evolution-chip-set__chips {
      justify-content: center;
      margin: unset;
      .mat-mdc-chip {
        height: 15px;
        min-height: unset;
        &.is-accent {
          color: var(--color-accent);
          background-color: var(--color-accent-background-ag-grid);
        }
        &.is-alert {
          color: var(--color-alert);
          background-color: var(--color-alert-background-ag-grid);
        }
        &.is-warning {
          color: var(--color-warning);
          background-color: var(--color-warning-background-ag-grid);
        }
      }
      .mat-mdc-standard-chip {
        margin: 2px;
      }
    }

    .mat-icon {
      height: 1em;
      line-height: 1em;
      vertical-align: text-bottom;
      width: 18px;
    }

    .mat-mdc-icon-button {
      .mat-icon {
        font-size: 1.3em;
        .mat-badge-content {
          line-height: 1em;
          top: 10px !important;
          right: 0px !important;
        }
      }
    }

    &.om-center {
      text-align: center;
    }
    &.is-read-only {
      cursor: not-allowed;
    }
    &.ag-cell-inline-editing {
      height: 100%;
    }

    &:not(.ag-cell-range-selected) {
      &.om-requested-cell-border-left {
        border-left: 1px solid var(--color-alert);
      }
      &.om-requested-cell-border-right {
        border-right: 1px solid var(--color-alert) !important;
      }

      &.om-requested-cell-center {
        border-top: 1px solid var(--color-alert);
        border-bottom: 1px solid var(--color-alert);
      }
    }

    &.ag-cell-range-selected {
      &.om-requested-cell-center:not(.ag-cell-range-single-cell) {
        &:not(.ag-cell-range-top):not(.ag-cell-range-bottom) {
          border-top: 1px solid var(--color-alert);
          border-bottom: 1px solid var(--color-alert);
        }
        &.ag-cell-range-top:not(.ag-cell-range-bottom) {
          border-bottom: 1px solid var(--color-alert);
        }
        &.ag-cell-range-bottom:not(.ag-cell-range-top) {
          border-top: 1px solid var(--color-alert);
        }
      }

      &:not(.ag-cell-range-single-cell) {
        &.om-requested-cell-border-left {
          &:not(.ag-cell-range-left) {
            border-left: 1px solid var(--color-alert);
          }
        }
        &.om-requested-cell-border-right {
          &:not(.ag-cell-range-right) {
            border-right: 1px solid var(--color-alert) !important;
          }
        }
      }
    }

    &:not(.ag-cell-inline-editing) {
      &.om-editable-cell {
        background-color: var(--color-alert-background) !important;
      }
    }
  }

  .ag-side-bar {
    .ag-side-buttons {
      width: 28px;
      padding-top: $ag-grid-header-height;
      .ag-side-button {
        .ag-side-button-button,
        .ag-side-button-button:hover {
          color: var(--color-primary);
          font-weight: var(--font-weight-bold);
        }
      }
    }

    .ag-tool-panel-wrapper {
      width: 431px;
      min-height: 520px;

      &:not(.ag-hidden) {
        > :not(.ag-tool-panel-horizontal-resize) {
          width: 100%;
          display: flex;
          flex-direction: column;
          overflow: hidden;
          flex: 1 1 0;
          height: 100%;

          .om-ag-grid-sidebar-wrapper {
            display: flex;
            flex-direction: column;
            overflow: hidden;
            flex: 1 1 0;
            height: 100%;
            background: white;
            > mat-toolbar {
              border-radius: 0;
            }

            > section {
              padding: var(--spacing-large);
              display: flex;
              flex-direction: column;
              overflow: auto;
              background: var(--color-accent-contrast);
              min-width: 100%;
              > p {
                margin-top: 0;
                color: var(--color-label);
              }

              > h3 {
                margin: 0;
                margin-bottom: var(--spacing-large) !important;
                &:not(:first-of-type) {
                  margin-top: 20px;
                }
              }
            }
          }

          mat-toolbar {
            background: var(--color-row-even);
            border-bottom: 1px solid var(--color-stroke);
            display: flex;
            justify-content: space-between;
          }

          > span + section {
            padding-top: 14px;
          }

          footer {
            padding: 20px 20px 10px 20px;
            width: 100%;
            height: 100px;
            button {
              width: 100%;
              .mdc-button__ripple {
                border-radius: var(--border-radius-large);
              }
              .mat-mdc-button-touch-target {
                height: 100%;
              }
            }

            .mat-flat-button {
              background: transparent;
            }
          }
        }
      }
    }

    .ag-column-panel {
      color: var(--color-primary);
      .ag-column-select {
        border: none;
      }
      .ag-column-select-header {
        height: $ag-grid-header-height + 1px;
        padding: 0 $ag-grid-padding;
      }
      .ag-virtual-list-viewport {
        padding: 8px $ag-grid-padding;
      }
      .ag-column-select-column-drag-handle {
        display: none;
      }
      .ag-column-select-virtual-list-item {
        .ag-disabled {
          opacity: 0;
          pointer-events: none;
        }
      }
    }
  }

  .ag-cell[col-id='fe__checkbox'],
  .ag-header-cell[col-id='fe__checkbox'] {
    display: flex;
    justify-content: center;
  }
  .ag-checkbox-input-wrapper {
    box-shadow: none !important;
    .ag-checkbox-input {
      cursor: pointer;
    }
    &::after {
      color: var(--color-stroke);
      font-family: OmIconFont;
      content: '\c1';
      color: transparent;
      border: 2px solid var(--color-primary);
      border-radius: 2px;
      transform: scale(0.8);
      margin: -1.6px 0px 0px -1.6px;
    }
    &.ag-indeterminate::after {
      content: '\c2'; // dash
      color: var(--color-background-2);
      background-color: var(--color-primary);
      font-weight: var(--font-weight-bold);
    }
    &.ag-checked::after {
      content: '\c1'; // check mark
      color: var(--color-background-2);
      background-color: var(--color-primary);
    }
  }

  .ag-icon-contracted::before,
  .ag-icon-expanded::before {
    font-family: OmIconFont;
    border: 1px solid;
    border-radius: 4px;
    position: absolute;
  }

  .ag-icon-contracted::before {
    content: '\c3';
    font-size: 14px;
    padding-left: 2.5px;
    padding-right: 2.5px;
  }

  .ag-icon-expanded::before {
    content: '\c2';
    font-size: 12px;
    padding-left: 2px;
    padding-right: 2px;
  }

  .ag-body-horizontal-scroll {
    display: flex !important;
    position: relative !important;
  }
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: $scrollbar-width;
    height: $scrollbar-width;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: math.div($scrollbar-width, 2);
    background: var(--color-primary);
  }
  .ag-popup-editor {
    visibility: hidden;
  }

  .om-pinned-column {
    background-color: var(--color-background-3) !important;
  }

  om-action-header-renderer {
    .mat-mdc-button {
      font-size: 12px !important;
      height: 20px !important;
      line-height: 20px !important;
      padding: 0 5px !important;
      margin-left: 10px !important;
    }
  }

  .ag-center-cols-container {
    .om-row-style {
      border-bottom: 1px solid var(--color-stroke) !important;
    }
  }
  .om-row-style {
    background-color: var(--color-background-2);

    &.ag-row-level-2.ag-row-group-expanded,
    &.ag-row-level-2.ag-row-group-contracted,
    &.ag-row-level-1.ag-row-group-expanded,
    &.ag-row-level-1.ag-row-group-contracted {
      border-bottom: 0px;
    }

    &.ag-row-level-0.ag-row-group-expanded,
    &.ag-row-level-0.ag-row-group-contracted,
    &.ag-row-level-1.ag-row-group-expanded,
    &.ag-row-level-1.ag-row-group-contracted {
      border-bottom: 1px solid var(--color-stroke);
      border-top: 1px solid var(--color-stroke);
    }

    &.ag-row-level-0.ag-row-group-expanded,
    &.ag-row-level-0.ag-row-group-contracted {
      background: var(--color-background-1);
    }

    &.ag-row-level-1.ag-row-group-expanded,
    &.ag-row-level-1.ag-row-group-contracted {
      background-color: var(--color-background-3);
    }
  }

  .om-row-style-alt {
    &.ag-row-group {
      background-color: var(--color-background-1) !important;
      .ag-group-value,
      .ag-group-child-count {
        font-weight: var(--font-weight-bold);
      }
    }
    &.ag-row-even:not(.ag-row-group) {
      background-color: var(--color-background-2) !important;
    }
  }

  .om-units-bps {
    .ag-group-value::after {
      content: 'bps';
      color: var(--color-label);
      margin-left: var(--spacing-small);
    }
  }

  .om-units-percentage {
    .ag-group-value::after {
      content: '%';
      color: var(--color-label);
      margin-left: var(--spacing-small);
    }
  }

  .ag-popup {
    z-index: var(--z-index-layer-2);
  }

  &.is-sortable-false {
    .ag-header-cell {
      .ag-sort-indicator-container {
        display: none;
      }
    }
  }
}

.om-grid-bottom-bar {
  width: 100%;
  background-color: var(--color-background-3) !important;
  border-top: 1px solid var(--color-stroke);

  button {
    padding: 0 16px;
    height: unset;
    justify-content: flex-start;
    background-color: var(--color-background-3) !important;
    font-size: var(--font-size-small);
    mat-icon {
      height: 16px;
      margin-top: -2px;
    }
    .mat-mdc-button-touch-target {
      height: unset;
    }
    .mat-ripple {
      display: none;
    }
  }
}
