om-version-history-container {
  mat-sidenav {
    &.om-sidebar-mini {
      om-version-history-sidenav {
        padding: 0;

        om-version-history-sidenav-form {
          justify-content: center;
          mat-toolbar {
            display: flex;
            justify-content: center;
            .document-icon-container {
              margin-right: 0;
            }
            .sidenav-form-header {
              display: none;
            }
          }
          form {
            display: none;
          }
        }
      }
    }
  }
}
