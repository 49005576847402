om-time-period-filter {
  overflow: hidden;
  .mat-mdc-radio-group {
    display: block;
    overflow: hidden;
    .mat-mdc-radio-button {
      display: block;
      margin: var(--spacing-medium);
    }
  }
}
