@import 'components/password-strength-meter/password-strength-meter.scss';

.is-slim:not(.is-logo-hidden) {
  &::before {
    content: '';
    display: block;
    background-image: url($assets + 'img/origin-markets/logo-square-white.svg');
    background-size: contain;
    background-repeat: no-repeat;
    height: 150px;
    width: 150px;
    margin: var(--spacing-large) auto 0;
    flex-shrink: 0;
  }
}

.is-slim {
  height: 100%;

  > section {
    max-width: 420px;
    padding: var(--spacing-large) var(--spacing-modal-side);

    > .mat-icon {
      cursor: pointer;
      margin-top: var(--spacing-large);
    }

    > .mat-mdc-button {
      width: 100%;
      margin-top: var(--spacing-large);
    }

    h1 {
      font-size: var(--font-size-x-large);
      text-align: center;
      line-height: 1em;
      margin: var(--spacing-x-large) 0;
    }

    form {
      .mat-mdc-form-field,
      .mat-mdc-checkbox {
        display: block;

        .mat-icon {
          height: 100%;
        }
      }

      .mat-mdc-checkbox {
        text-align: center;
        margin-bottom: var(--spacing-large);
      }

      .mat-mdc-button {
        width: 100%;
      }

      article {
        margin-bottom: var(--spacing-x-large);
      }

      p {
        text-align: center;
      }
    }

    section {
      margin: var(--spacing-large) 0 var(--spacing-large) 0;
      text-align: center;
      font-size: var(--font-size-large);
    }
  }
}
