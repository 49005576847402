om-progress-indicator {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  .om-progress-indicator-background,
  .om-progress-indicator-foreground {
    position: absolute;
  }

  .om-progress-indicator-background circle {
    stroke: var(--color-stroke);
  }

  .om-progress-indicator-foreground circle {
    stroke: var(--color-alert);
  }

  .om-progress-indicator-value-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    span {
      display: block;
      font-size: 0.3em;
      font-weight: var(--font-weight-bold);
      text-align: center;
      flex-grow: 1;
    }
  }

  .mat-icon {
    height: 100%;
    width: 50%;
    min-width: unset;
    color: var(--color-accent-contrast);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &.om-progress-indicator-100 {
    .om-progress-indicator-foreground circle {
      fill: var(--color-accent);
      stroke: var(--color-accent);
    }
    .om-progress-indicator-background {
      display: none;
    }
  }
}
