om-template-testing-content-form {
  main {
    > strong {
      color: var(--color-primary);
      font-size: var(--font-size-large);
      display: block;
      padding: var(--spacing-large);
      text-align: center;
      * {
        vertical-align: middle;
      }
      .mat-icon {
        font-size: 1em;
        line-height: 1em;
        height: 1em;
      }
    }
  }
}
