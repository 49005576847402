$dropzone-timing: 1s;

.om-dropzone,
.om-file-dropzone {
  &::before {
    content: 'Drop file here to upload';
    border: 1px dashed;
    position: absolute;
    width: calc(100% - 16px);
    height: calc(100% - 16px);
    top: 8px;
    left: 8px;
    background: var(--color-accent-background);
    box-shadow: 0px 0px 7px 5px var(--color-accent-background);
    line-height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.om-drag-file-dropzone {
  &::before {
    display: none;
  }
}

.om-drag-file-modal-dropzone__dropper {
  background-color: var(--color-accent-background);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;

  padding: var(--spacing-xl-large);

  width: 100%;
  height: 100%;

  height: calc(100% - 100px);
  top: 50px;

  transition: top $dropzone-timing, left $dropzone-timing, width $dropzone-timing, height $dropzone-timing,
    opacity $dropzone-timing;

  .om-drag-file-modal-dropzone__dropzone {
    border: 1px dashed var(--color-accent);
    border-radius: var(--border-radius);
    width: 100%;
    height: 100%;

    font-weight: var(--font-weight-bold);

    display: flex;
    align-content: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
      font-size: 18px !important; //😢
    }
  }
}

.om-drag-file-modal-dropzone {
  .om-drag-file-modal-dropzone__dropper {
    opacity: 1;

    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 30px;
  }
}
