om-document-generator-page-inputs-readonly {
  .mat-mdc-card {
    border: none;
    border-radius: var(--border-radius);
    padding: 0;
    .mat-mdc-card-title {
      span {
        font-size: var(--font-size-large);
        margin-left: var(--spacing-large);
      }
    }
    .mat-mdc-card-header {
      position: sticky;
      z-index: var(--z-index-layer-1);
      top: 0px;
      padding: 20px 0;
      margin: 0;
      background: var(--color-background-2);
      box-shadow: var(--box-shadow-underline);
      border-radius: var(--border-radius) var(--border-radius) 0 0;
      .mat-mdc-card-header-text {
        margin: 0 var(--spacing-large);
        h2 {
          margin: 0;
        }
      }
    }

    .mat-mdc-card-content > table {
      display: block;
      width: 100%;
      > tr {
        display: flex;
        flex-direction: row;
        transition: background-color 1s ease-in-out;
        &:nth-child(2n) {
          background: var(--color-row-odd);
        }
        &:last-child {
          border-radius: 0 0 var(--border-radius) var(--border-radius);
        }

        &.om-highlight-pulse {
          background-color: var(--color-alert);
        }

        > td {
          font-size: var(--font-size-medium);
          &:first-child,
          &:last-child {
            width: 42px;
            flex-grow: 0;
            flex-shrink: 0;
            display: flex;
            align-items: center;
          }
          &:first-child {
            padding-left: var(--spacing-small);
            .mat-icon {
              margin: auto;
            }
          }
          &:last-child {
            text-align: left;
          }
          &:nth-child(2) {
            width: 300px;
            flex-grow: 0;
            flex-shrink: 1;
            display: flex;
            align-items: center;
            font-weight: var(--font-weight-bold);
            font-size: var(--font-size-small);
            color: var(--color-primary);
            padding: 12px 8px 12px 0;
            line-height: 20px;
            .mat-icon {
              color: var(--color-label);
            }

            .is-optional {
              color: var(--color-label);
              font-style: italic;
            }
          }
          &:nth-child(3) {
            width: 250px;
            flex-grow: 1;
            flex-shrink: 2;
            padding: 12px 0;
            > span {
              color: var(--color-label);
              display: block;
              font-size: var(--font-size-small);
              margin-top: 2px;
            }
          }
        }
      }
    }
  }
}
