om-comment-card {
  margin: var(--spacing-large);
  display: block;
  > .mat-mdc-card {
    padding: 0;
    overflow: hidden;
    &.is_resolving,
    &.is_reopening {
      .mat-mdc-card {
        display: none;
      }
      .mat-mdc-card-content {
        display: none;
      }
      .mat-mdc-card-footer {
        display: none;
      }
      .mat-mdc-button {
        display: none;
      }

      .mat-mdc-card-header {
        min-height: unset;
        .mat-mdc-card-subtitle {
          display: none;
        }
        background-color: var(--color-accent-background);
        -webkit-transition: background-color 1000ms linear;
        -ms-transition: background-color 1000ms linear;
        transition: background-color 1000ms linear;
      }

      .mat-mdc-card-header::after {
        content: 'Resolved';
        color: var(--color-accent);
        font-weight: var(--font-weight-bold);
        font-size: 15px;
      }
    }

    &.is_resolving {
      .mat-mdc-card-header::after {
        content: 'Resolved';
        color: var(--color-accent);
        font-weight: var(--font-weight-bold);
        font-size: var(--font-size-large);
      }
    }

    &.is_reopening {
      .mat-mdc-card-header::after {
        content: 'Reopened';
        color: var(--color-accent);
        font-weight: var(--font-weight-bold);
        font-size: var(--font-size-large);
      }
    }

    > .mat-mdc-card-header {
      min-height: 68px;
      background: var(--color-background-3);
      border-bottom: 1px solid var(--color-stroke);
      padding: 16px 6px 10px 16px;
      align-items: unset;
      margin-bottom: 0;
      .mat-mdc-card-header-text {
        padding-right: 10px;
        max-width: 100%;
        .mat-mdc-card-title {
          &.has-info {
            width: 100%;
            display: inline-flex;
            margin-bottom: 0;
            &::after {
              content: '"';
            }
            .title-info {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              &::before {
                content: ' "';
                white-space: pre;
              }
            }
          }

          &.recipient-info {
            margin-top: 0;

            &.has-recipients::before {
              content: 'For: ';
              font-weight: var(--font-weight-normal);
            }

            span {
              &::before {
                content: '@';
              }
              &:not(:last-child)::after {
                content: ', ';
              }
            }
          }
        }
        .mat-mdc-card-subtitle {
          font-size: 10px;
          text-transform: uppercase;
          display: flex;

          .mat-icon {
            width: 16px;
            height: 16px;
          }

          .is-private {
            margin-left: var(--spacing-small);
          }
        }
      }
      div:nth-child(2) {
        position: absolute;
        right: 0;
        top: 0;
        padding: 6px 6px;
        span:first-child {
          margin-right: 6px;
        }
      }
      .mat-mdc-icon-button {
        height: 28px;
        width: 28px;
        background: var(--color-background-emphasis);
        .mat-icon {
          color: var(--color-primary);
        }
        &:hover {
          .mat-icon {
            color: var(--color-background-2);
          }
        }
      }
    }
    &.is_resolved {
      div:nth-child(2) {
        span:first-child {
          margin-right: 0;
        }
      }
    }

    > .mat-mdc-card-content {
      margin: 0;
    }

    > .mat-mdc-card-footer {
      margin: 0;
      border-top: 1px solid var(--color-stroke);
      padding-left: 5px;
    }

    .mat-mdc-card {
      border: none;
      border-radius: 0;
      .mat-mdc-card-header {
        .mat-mdc-card-header-text {
          overflow: hidden;
          white-space: nowrap;
          .mat-mdc-card-title {
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        > span:last-of-type {
            .mat-mdc-button {
              margin-left: 6px;
            }
          }

      }

      .mat-mdc-card-content {
        p {
          white-space: pre-line;
          line-height: 20px;
        }
        b {
          display: block;
          margin-bottom: 4px;
        }

        &.is-recipient-input {
          padding-bottom: var(--spacing-large);
        }

        &.is-private-draft {
          margin: 0 calc(-1 * (var(--spacing-large))) var(--spacing-large);
          border-top: 1px solid var(--color-stroke);
          border-bottom: 1px solid var(--color-stroke);

          .mat-mdc-card {
            background: var(--color-background-3);
          }
        }

        om-rich-text-editor {
          p {
            white-space: unset;
          }
        }
      }

      &.is-secondary {
        background: var(--color-background-3);

        &:not(.is-suggestion) {
          .mat-mdc-card-content {
            font-weight: var(--font-weight-bold);
          }
        }
      }

      &.is-alert {
        background: var(--color-alert-background);
        box-shadow: inset var(--spacing-small) 0 0 0 var(--color-alert);
      }

      + .mat-mdc-card {
        border-top: 1px solid var(--color-stroke);
      }
    }

    .om-user-input-container {
      background: var(--color-background-3);

      &.is-first-comment {
        background: var(--color-background-2);

        om-dynamic-form-field {
          background-color: var(--color-background-2) !important;
        }
      }

      .mat-mdc-card-content {
        om-dynamic-form {
          form {
            > div {
              padding: 0;
              background-color: var(--color-background-3);
            }
          }
        }

        om-dynamic-form-field {
          padding: 0 !important;
          background-color: var(--color-background-3);
          .mat-mdc-form-field {
            width: 100%;
            .mat-mdc-form-field-flex {
              background-color: var(--color-background-2);
              border-radius: var(--border-radius);
            }
          }
          > .mat-mdc-form-field {
            margin-bottom: var(--spacing-x-large) !important;
          }
        }
        > section {
          margin-top: -10px;
          text-align: right;
          .mat-mdc-button {
            margin-left: var(--spacing-small);
          }
        }
      }

      .mat-mdc-card-footer {
        padding-top: var(--spacing-large);
        span {
          font-size: var(--font-size-small);
          color: var(--color-label);
          text-transform: uppercase;
        }
      }
    }
  }
}
