om-issuer-profile-programmes-tab {
  .mat-mdc-tab-body {
    &:first-child {
      .om-table {
        th {
          width: 250px;
        }
      }
    }
  }
  .mat-mdc-snack-bar-container {
    margin: var(--spacing-medium);
    padding-top: var(--spacing-large);
    padding-bottom: var(--spacing-large);
    text-align: center;
    box-shadow: none;
    max-width: 100%;
  }

  .mdc-tab {
    max-width: unset !important;
  }

  section[right] {
    img:not(:first-child) {
      margin-left: var(--spacing-small);
    }
  }
}
