@mixin dot {
  display: inline-block;
  content: '';
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: var(--color-accent);
}
om-date-input {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 4px 12px;
  input {
    display: none !important;
  }
  > span {
    width: 100%;
    display: inline-flex;
    justify-content: left;
    align-items: center;
    cursor: pointer;
    p {
      margin: 0;
    }
  }

  .mat-mdc-icon-button {
    color: var(--color-primary);
  }

  .om-calendar-toggle {
    pointer-events: none;
  }
}

.mat-datepicker-content {
  .mat-calendar {
    width: 329px;
    height: auto;
    .mat-calendar-previous-button::after,
    .mat-calendar-next-button::after {
      margin: 12px;
    }
    .mat-calendar-body-cell.mat-calendar-body-active {
      &:hover,
      &:focus {
        > .mat-calendar-body-cell-content.mat-calendar-body-today {
          color: var(--color-accent-contrast);
          background-color: var(--color-primary);
        }
      }
    }
    .mat-calendar-arrow {
      fill: var(--color-primary) !important;
      margin-top: -2px;
    }

    &.om-datepicker-no-year {
      .mat-calendar-period-button,
      mat-year-view .mat-calendar-body-label {
        display: none;
      }
    }

    .highlight-date {
      &::after {
        @include dot();
        position: absolute;
        top: 30px;
        left: calc(50% - 3px);
        z-index: 1;
      }
    }
  }

  .datepicker-legend {
    padding: 0 var(--spacing-x-large) var(--spacing-x-large);
    max-width: 328px;
    display: flex;
    flex-direction: column;
    gap: 12px;

    .legend-item {
      margin: 0;
      color: var(--color-secondary);
      font-size: var(--font-size-small);
      line-height: 17px;
    }
    .dot {
      @include dot();
      margin: 0 0.3em;
    }
    .mat-icon {
      color: var(--color-accent-soft);
      height: 28px;
      min-width: 28px;
      line-height: 28px;
      padding: var(--spacing-small);
    }
    .info-notification {
      display: flex;
      gap: var(--spacing-medium);
      align-items: center;
      padding: var(--spacing-medium);
      border-radius: var(--border-radius);
      background-color: var(--color-accent-soft-background);
      color: var(--color-primary);
      font-size: var(--font-size-small);
      line-height: 18px;
      letter-spacing: -0.011em;
    }
  }
}
