.mdc-dialog__container {
  om-document-extractor-upload-modal {
    text-align: center;
    .mat-mdc-dialog-content {
      padding: 30px 30px 15px;
      &.has-error {
        padding: 0 0 60px;
        .upload-area {
          border: none;
        }
        .button {
          .mat-icon {
            height: 1em;
          }
        }
      }
      &.is-loading {
        padding: 30px;
      }
    }
    .upload-area {
      height: 282px;
      border: 1px dashed var(--color-label);
      border-radius: 4px;
      padding: 0 60px;
      &__header {
        padding-top: 64px;
      }
      &__text {
        font-size: 18px;
        font-weight: var(--font-weight-bold);
        margin-bottom: 8px;
        em {
          color: var(--color-accent);
        }
      }
      &__subtext {
        font-size: var(--font-size-medium);
        margin-top: var(--spacing-medium);
      }

      .upload-area__subtext + .upload-area__subtext {
        margin-top: calc(-1 * var(--spacing-medium));
      }
      .om-progress {
        margin-top: 18px;
      }
    }
    a {
      cursor: pointer;
      color: var(--color-accent);
    }
  }
}
