om-identity-cell-renderer {
  background-color: inherit;

  img,
  .om-empty-image {
    width: var(--square-logo-height);
    height: var(--square-logo-height);
    display: inline-block;
    vertical-align: middle;
    margin-right: var(--spacing-medium);
    margin-top: -2px;
  }

  .om-icon-container {
    display: inline-block;
    position: absolute;
    right: 0;
    background-color: inherit;
    padding: 0px 5px 0 2px;
    .om-kyc {
      background: var(--color-accent-background);
      padding: 2px 4px 1px;
      border-radius: 8px;
    }
  }
}
