.om-profile {
  .mat-toolbar {
    // todo: sort out where/when toolbars get cursor (when clickable)
    // cursor: pointer;
  }

  .om-table {
    th {
      width: 250px;
    }
    td {
      span {
        margin-left: -3px;
      }
    }
  }

  .mat-expansion-panel-body {
    padding-top: 0;
    padding-right: 0;
    padding-left: 0;
    padding-bottom: var(--spacing-large);
  }

  // note: only used if we care about hiding the 'selected' style of a tab, when the panel is collapsed
  // (do we...?)
  mat-tab-group {
    &.is-collapsed {
      mat-ink-bar {
        visibility: hidden !important;
      }
      .mdc-tab.mdc-tab--active {
        font-weight: var(--font-weight-normal);
        color: var(--color-primary);
      }
    }
  }
}
