@import 'apps/bankangle/src/app/shared-echats/components/line-chart/line-chart.component.scss';
@import 'apps/bankangle/src/app/shared-echats/components/legend-renderers/curve-renderer/curve-renderer.component.scss';

.om-chart-tooltip-value {
  font-weight: var(--font-weight-bold);
  text-align: right;
  padding-left: var(--spacing-medium);
}

.om-chart-tooltip-diff {
  color: var(--color-chart-attention);
  padding-left: var(--spacing-medium);
}
