.om-document-generator-toolbar-footer {
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-normal);
  h2 {
    font-weight: var(--font-weight-normal);
  }

  .mat-mdc-checkbox {
    .mdc-form-field {
      > label {
        color: var(--color-label-contrast);
      }
    }
  }
}
