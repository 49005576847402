@import './components/activity-feed-widget/activity-feed-widget.component.scss';
@import './components/trades-widget/trades-widget.component.scss';
@import './components/syndicate-pricer-widget/syndicate-pricer-widget.component.scss';
@import './components/syndicate-pricer-modal/syndicate-pricer-modal.component.scss';
@import './components/issuer-list-widget/issuer-list-widget.component.scss';
@import './components/ifr-feed-widget/ifr-feed-widget.component.scss';

.om-dashboard-widget {
  display: flex;
  flex-direction: column;
  height: calc(100% - var(--toolbar-height));

  &.is-vertically-scrollable {
    display: flex;
    flex: 1 1 auto;
    overflow-y: auto;
  }

  &.is-carousel-layout {
    border-radius: var(--border-radius);
    mat-tab-group {
      justify-content: center;
      height: calc(100% - var(--button-height));
      padding: var(--spacing-small);
      mat-tab-body > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: var(--spacing-large);
        height: auto;
        min-height: 100%;
        background: var(--color-row-odd);
        cursor: pointer;

        div:nth-child(1) {
          padding-right: var(--spacing-large);

          div:nth-child(1) {
            font-size: var(--font-size-large);
          }
        }

        div:nth-child(2) {
          img {
            height: 56px;
            width: 56px;
            object-fit: contain;
          }
        }
      }
    }

    .mat-mdc-tab-header {
      display: none;
    }

    .is-label {
      background: var(--color-alert-background);
      display: inline-block;
      padding: var(--spacing-small) var(--spacing-medium);
      color: black;
      font-size: var(--font-size-medium-uppercase);
      font-weight: var(--font-weight-bold);
      border-radius: var(--border-radius-large);
      margin-top: var(--spacing-medium);
    }
  }

  &.is-coming-soon-widget {
    background-color: var(--color-accent-background);
  }

  &.is-coming-soon-widget,
  &.is-empty-layout {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;

    img {
      margin-bottom: var(--spacing-medium);
      height: 100px;
    }

    h3 {
      font-size: var(--font-size-x-large);
      margin: 0;
      margin-bottom: var(--spacing-medium);
    }

    p {
      font-size: var(--font-size-large);
      margin: 0;
      margin-bottom: var(--spacing-medium);
      text-align: center;
    }
  }

  &.is-coming-soon-modal {
    display: flex;
    flex-direction: column;
    padding-top: var(--spacing-x-large);

    h3 {
      font-size: var(--font-size-large);
      font-weight: var(--font-weight-bold);
      margin-bottom: var(--spacing-medium);
      position: relative;
      text-align: center;

      & + p {
        margin-bottom: var(--spacing-x-large);
        font-size: var(--font-size-large);
        font-style: italic;
        color: var(--color-chart-line-disabled);
      }
    }

    &:nth-child(2) {
      font-size: var(--font-size-medium);
      margin-bottom: var(--spacing-small);
      font-weight: var(--font-weight-bold);
      white-space: nowrap;
    }

    img {
      width: 100%;
    }

    .mat-mdc-icon-button {
      position: absolute;
      right: var(--spacing-small);
      top: -9px;
    }

    .is-chip {
      margin-top: var(--spacing-medium);
      margin-bottom: var(--spacing-x-large);
      margin-right: auto;
      margin-left: auto;
    }
  }

  .om-widget-action {
    color: var(--color-accent);
    text-transform: uppercase;
    font-size: var(--font-size-medium-uppercase);
    font-weight: var(--font-weight-bold);
    margin-top: var(--spacing-medium);
    cursor: pointer;
  }
}
