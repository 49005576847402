om-document-extractor-container {
  section {
    h2 {
      margin-left: var(--spacing-large) !important;
    }
  }
  mat-drawer-container {
    mat-drawer {
      width: 45%;
      max-width: 780px;
      min-width: 600px;
    }

    mat-drawer-content {
      margin-right: auto;
    }
  }
}
