& {
  --disabled-opacity: 0.54;

  --line-height-x-small: 16px;
  --line-height-small: 20px;
  --line-height-medium: 24px;
  --line-height-large: 32px;
  --line-height-x-large: 48px;

  --font-size-x-small: 9px;
  --font-size-x-small-uppercase: 8px;
  --font-size-small: 12px;
  --font-size-medium: 14px;
  --font-size-medium-uppercase: 13px;
  --font-size-large: 16px;
  --font-size-large-uppercase: 15px;
  --font-size-x-large: 24px;

  $spacing-small: 4px;
  $spacing-medium: 8px;
  $spacing-large: 16px;
  $spacing-x-large: 24px;
  $spacing-xl-large: 30px;
  $spacing-modal-side: 40px;

  --spacing-small: #{$spacing-small};
  --spacing-medium: #{$spacing-medium};
  --spacing-large: #{$spacing-large};
  --spacing-x-large: #{$spacing-x-large};
  --spacing-xl-large: #{$spacing-xl-large};
  --spacing-modal-side: #{$spacing-modal-side};

  --negative-spacing-small: #{-$spacing-small};
  --negative-spacing-medium: #{-$spacing-medium};
  --negative-spacing-large: #{-$spacing-large};
  --negative-spacing-x-large: #{-$spacing-x-large};
  --negative-spacing-xl-large: #{-$spacing-xl-large};
  --negative-spacing-modal-side: #{-$spacing-modal-side};

  --section-header-height: 56px;
  --section-item-height: 36px;
  --toolbar-height: 52px;
  --button-height: 34px;
  --width-right-sidebar: 400px;

  --square-logo-height: 31px;

  --border-radius-small: 4px;
  --border-radius: 6px;
  --border-radius-medium: 8px;
  --border-radius-large: 20px;
  --chip-border-radius: 34px;

  --z-index-layer-1: 5;
  --z-index-layer-2: 10;

  --box-shadow-underline: inset 0 -8px 4px -8px var(--color-stroke);

  --font-weight-normal: 400;
  --font-weight-semi-bold: 600;
  --font-weight-bold: 700;

  @-moz-document url-prefix() {
    --font-weight-normal: 300;
    --font-weight-bold: 600;
  }
}

$elevation-value: 1;

$width-narrow-content: 920px;

// Corresponding TypeScript variables in constants/display.ts
$small-screen-breakpoint: 1100px; // also change `smallScreenBreakPoint`
$sidebar-animation-duration: 250ms; // also change `toggleSidebarAnimationTransitionDuration`
$sidebar-animation-timing: $sidebar-animation-duration ease-in; // also change `toggleSidebarAnimationTransition`
$sidebar-width-mini: 64px; // also change `sidebarWidthMini`
$sidebar-width-open: 250px; // also change `sidebarWidthOpen`
$scrollbar-width: 8px; // also change `scrollbarWidth`
$linear-progress-indicator-transition-time: 2000ms; // also change `linearProgressIndicatorTransitionTime`

$transition-timing: 250ms ease-in-out;

$icon-height-medium: 12px;

$circle-bullet-height-medium: 10px;
