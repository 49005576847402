om-issuer-profile-overview-tab {
  om-issuer-profile-entities-overview-widget,
  om-issuer-profile-programmes-overview-widget {
    height: 357px !important;
    width: 200px !important;

    article {
      flex-grow: 1;
      flex-shrink: 1;
    }
  }

  om-issuer-profile-dates-overview-widget,
  om-issuer-profile-notes-overview-widget {
    max-height: 250px;
    min-height: 100%;
    width: 200px !important;
  }
}
