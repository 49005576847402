&,
.mat-drawer-container {
  color: var(--color-primary);
}

.is-hidden {
  display: none;
}

.is-ellipsis {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.is-pre-line {
  white-space: pre-line;
}

.is-subsection {
  font-size: var(--font-size-medium);
  font-weight: var(--font-weight-semi-bold);
  line-height: 14px;
  color: var(--color-label);
  text-transform: uppercase;
  padding-bottom: var(--spacing-large);
}

.flex-grow {
  flex-grow: 1;
}

.flex-shrink {
  flex-shrink: 1;
}

.om-reveal-item {
  opacity: 0;
}
.om-reveal-container {
  &:hover {
    .om-reveal-item {
      opacity: 1;
    }
  }
}

.is-label {
  color: var(--color-label);
}
.is-clickable {
  cursor: pointer;
}
.om-vertical-rule-container {
  margin-left: var(--negative-spacing-medium);
  margin-right: var(--negative-spacing-medium);
  white-space: normal;
  > * {
    display: inline-block;
    padding-left: var(--spacing-medium);
    padding-right: var(--spacing-medium);
    border-left: 1px solid;
    vertical-align: middle;
    &:first-child {
      border-left: 0;
    }
  }
}

* {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

.om-tag {
  border-radius: 7px;
  padding: 2px 4px;
  font-size: var(--font-size-small);
  vertical-align: middle;
  float: right;
  margin-right: 5px;
  background: var(--color-label-background);
  color: var(--color-primary);

  &.is-accent {
    background: var(--color-accent-background);
    color: var(--color-primary);
  }

  &.is-alert {
    background: var(--color-alert-background);
    color: var(--color-primary);
  }

  &.is-warning {
    background: var(--color-warning-background);
    color: var(--color-primary);
  }
}

span.is-tooltip-anchor {
  width: fit-content;
  display: flex;
  align-items: center;
}

article {
  > *:first-child {
    margin-top: 0;
  }
  > *:last-child {
    margin-bottom: 0;
  }
}
aside,
main {
  height: 100px;
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
}

main {
  &:not(.has-sections),
  &.has-sections > *:not(.om-widget-wrapper, .om-page-announcement-icon, .om-pdf-viewer),
  &.has-sections > .om-widget-wrapper > * {
    align-self: center;
    background: var(--color-background-2);
    border-radius: var(--border-radius);
    margin: var(--spacing-large);
    width: calc(100% - calc(2 * var(--spacing-large)));
    @include mat.elevation($elevation-value);
    > * {
      &.mat-toolbar:first-child {
        color: var(--color-primary);
        border-bottom: 1px solid var(--color-stroke);
      }
      &:first-child {
        border-top-left-radius: var(--border-radius);
        border-top-right-radius: var(--border-radius);
      }
      &:last-child {
        border-bottom-left-radius: var(--border-radius);
        border-bottom-right-radius: var(--border-radius);
      }
    }
    .mat-toolbar {
      &:first-child {
        border-top-left-radius: var(--border-radius);
        border-top-right-radius: var(--border-radius);
      }
      &.is-accent {
        border-right-width: 1px;
        border-left-width: 1px;
      }
    }
  }
  &.has-sections {
    width: 100%;
    > *:not(:first-child) {
      margin-top: 0;
    }
  }
  &.is-narrow {
    &:not(.has-sections) {
      max-width: $width-narrow-content;
    }
    &.has-sections {
      > * {
        max-width: $width-narrow-content;
      }
    }
  }

  .om-widget-wrapper {
    width: calc(100% - calc(2 * var(--spacing-large)));
    align-self: center;
    display: flex;
    flex-direction: row;
    margin: var(--spacing-large);
    > * {
      align-self: start !important;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      flex-shrink: 1;
      margin: 0 !important;
      + * {
        margin-left: var(--spacing-large) !important;
      }
    }
  }

  section[right] {
    padding: var(--spacing-medium);
    display: flex;
    justify-content: flex-end;
  }

  .om-page-announcement {
    padding: calc(3 * var(--spacing-large)) var(--spacing-large);
    background: none !important;
    box-shadow: none !important;
    text-align: center;
    font-size: var(--font-size-x-large);
  }

  .om-page-announcement-icon {
    background: none !important;
    background-image: url($assets + 'img/icons/empty.png') !important;
    background-position: center top !important;
    background-repeat: no-repeat !important;
    background-size: 78px !important; // todo: check this when 'real' image is in
    box-shadow: none !important;
    padding-top: 100px;
    margin: var(--spacing-x-large) auto var(--spacing-large);
    text-align: center;
    width: 500px;

    font-size: var(--font-size-large);
    font-weight: var(--font-weight-bold);
  }
  .om-page-announcement-icon + button {
    width: initial !important;
  }
}

a {
  color: var(--color-accent-soft);
  text-decoration: none;

  &.mat-mdc-menu-item {
    color: var(--color-primary);
  }
}
a[target='_blank']:not(.om-hide-external-link-icon),
.om-external-link {
  &::after {
    content: '\e1';
    font-family: OmIconFont;
    font-size: 0.7em;
    line-height: 0.7em;
    display: inline-block;
    position: relative;
    top: -0.2em;
    margin-left: 0.2em;
  }
}

h1,
h2,
h3,
h4,
h5 {
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-bold);
}
p {
  font-size: var(--font-size-medium);
}

em {
  font-style: normal;
  font-weight: var(--font-weight-bold);
}

img {
  &:not(.is-transparent) {
    background: white;
  }

  &.om-flag {
    max-height: 22px;
    border-radius: 15%;
  }
}

small {
  color: var(--color-label);
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-normal);
}

a,
b,
span,
strong {
  &.is-block {
    display: block;
  }
  &.is-accent {
    color: var(--color-accent);
  }
  &.is-alert {
    color: var(--color-alert);
  }
  &.is-warning {
    color: var(--color-warning);
  }
}

strong {
  font-weight: var(--font-weight-bold);
}

// Chrome autocomplete
input,
textarea,
select {
  &:-webkit-autofill {
    &,
    &:hover,
    &:focus {
      -webkit-box-shadow: 0 0 0 30px var(--color-background-2) inset !important;
    }
  }
}

input::-webkit-search-cancel-button {
  display: none;
}

.is-padded {
  padding: var(--spacing-large);
}

.is-bold {
  font-weight: var(--font-weight-bold);
}

.has-shadow-underline {
  box-shadow: var(--box-shadow-underline);
}

.om-disabled {
  pointer-events: none;
  opacity: var(--disabled-opacity);
}

.om-placeholder {
  color: var(--color-label);
}

om-narrow-card + om-narrow-card {
  margin-top: var(--spacing-medium);
  display: block;
}

li.om-document-list {
  border: 1px dashed var(--color-stroke);
}
.om-document-list {
  ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
  }
  li {
    padding-right: var(--spacing-medium);
    margin: calc(var(--spacing-medium) / 2) 0;
  }
}

.om-basic-list {
  margin: 0;
  list-style: none;
  padding: 0;
  font-size: var(--font-size-medium);
  li {
    line-height: 1.5em;
    padding: 0;
    + li {
      padding-top: var(--spacing-medium);
    }
    * + * {
      padding-left: var(--spacing-medium);
    }
  }

  dt,
  dd {
    line-height: 1.5em;
    padding: 0;
  }
  dt {
    font-weight: var(--font-weight-bold);
    color: var(--color-primary);
  }
}

table {
  border-collapse: collapse;
  &.is-new-style {
    width: 100%;
    tr,
    th,
    td {
      border: 1px solid var(--color-stroke);
    }

    tr {
      &:nth-child(odd) {
        background-color: var(--color-row-odd);
      }
      &:nth-child(even) {
        background-color: var(--color-row-even);
      }
    }

    th {
      background: var(--color-background-emphasis);
      font-weight: var(--font-weight-bold);
      font-size: small;
    }

    th,
    td {
      padding: var(--spacing-medium);
    }
    .is-icon-column {
      width: var(--button-height);
    }
  }
}

table.om-table {
  width: 100%;

  tr {
    &:nth-child(odd) {
      background-color: var(--color-row-odd);
    }
    th,
    td {
      padding: var(--spacing-large);
    }
    th {
      font-weight: var(--font-weight-bold);
      text-align: left;
    }
  }
}

dl {
  margin: 0;
  > div {
    display: flex;
    align-items: center;
  }

  dt,
  dd {
    display: flex;
    align-items: center;
    margin: 0;
  }
  dt {
    font-weight: var(--font-weight-bold);
  }
  dd {
    padding-left: var(--spacing-small);
  }

  &.is-table {
    > div {
      border: 1px solid var(--color-stroke);
      border-bottom-width: 0;

      &:nth-child(even) {
        background: var(--color-row-odd);
      }

      &:last-of-type {
        border-bottom-width: 1px;
      }
    }

    dt,
    dd {
      padding-left: var(--spacing-large);
      min-height: 40px;
    }
    dt {
      max-width: 312px;
      flex: 0 0 30%;
      white-space: nowrap;
    }
    dd {
      border-left: 1px solid var(--color-stroke);
    }
  }
}

.om-display-icon-list {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;

  li {
    margin: 0 var(--spacing-medium) 0 0;

    &:hover {
      z-index: 1;
      .mat-icon {
        transform: scale(3, 3);
      }
    }
  }

  .mat-icon {
    height: 24px;
    width: 24px;
    transition: transform $transition-timing;
  }
}

.om-profile {
  .mat-mdc-tab-links {
    justify-content: center;
  }
}

.has-pointer {
  cursor: pointer;
}

.om-banner {
  height: 32px;
  width: 100%;
  font-size: var(--font-size-medium);
  line-height: var(--line-height-large);
  text-align: center;
  font-weight: var(--font-weight-bold);
  color: var(--color-accent-soft-contrast);
  background: var(--color-accent-soft);

  &.is-label {
    background: var(--color-label);
  }
  &.is-accent {
    color: var(--color-accent-contrast);
    background: var(--color-accent);
  }
  &.is-alert {
    color: var(--color-alert-contrast);
    background: var(--color-alert);
  }
  &.is-warning {
    color: var(--color-warning-contrast);
    background: var(--color-warning);
  }
}
.ag-tool-panel-wrapper,
.mdc-dialog__container,
.mat-drawer {
  .om-banner {
    color: var(--color-background-contrast);
    background: var(--color-background-3);

    &.is-alert {
      color: var(--color-alert);
      background: var(--color-alert-background);
    }
    &.is-warning {
      color: var(--color-warning);
      background: var(--color-warning-background);
    }
  }
}

.om-display-more {
  text-decoration: underline;
  float: right;
}

// TODO: Move this to ag-grid scss
span,
div {
  &.is-level-new {
    background-color: var(--color-accent-soft-background) !important;
    border: 1px solid var(--color-accent-soft) !important;
  }
  &.is-level-wider {
    background-color: var(--color-accent-background) !important;
    border: 1px solid var(--color-accent) !important;
  }
  &.is-level-tighter {
    background-color: var(--color-warning-background) !important;
    border: 1px solid var(--color-warning) !important;
  }
  &.is-row-greyed-out {
    background-color: var(--color-greyed-out) !important;
    border: 1px solid var(--color-greyed-out) !important;
  }
  &.is-level-below-target-basis {
    color: var(--color-label);
  }
  &.is-priced {
    font-weight: var(--font-weight-bold);
  }
  &.om-list-cell {
    text-decoration: underline dashed;
    text-underline-position: under;
  }
}

@mixin omImageGrid($minImageWidth: 40px) {
  padding: 0 !important;
  list-style: none;
  display: grid;
  gap: var(--spacing-medium);
  grid-template-columns: repeat(auto-fit, minmax($minImageWidth, 1fr));

  img {
    display: block;
    border-radius: 4px;
    width: 100%;
  }

  li {
    margin: 0 !important;
  }
}

om-label-value-table {
  font-size: var(--font-size-medium);
}

om-set-password {
  li {
    font-size: 13px;
  }
}

om-dynamic-form-field,
om-maturities-table {
  .om-readonly-form-field {
    font-size: var(--font-size-medium);
    margin-bottom: var(--spacing-large);
    color: var(--color-primary);
  }
}

om-curve-renderer,
om-syndicate-pricer-chart-item {
  div {
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
    font-size: var(--font-size-medium-uppercase);
    &:nth-child(1) {
      font-weight: var(--font-weight-bold);
    }
  }
}

om-list-input,
om-table-input {
  om-select-input {
    margin: 0 -12px;
    mat-select {
      padding: 0 12px !important;
    }
  }
}

.om-input-box {
  background: var(--color-background-3);
  border-top: 1px solid var(--color-stroke);
  padding: var(--spacing-large);
  text-align: right;
  .mat-mdc-form-field {
    margin-bottom: var(--spacing-large);
  }

  &:has(om-file-input) {
    position: relative;
  }
}

span.is-inline-new {
  display: inline-block;
  border: 1px solid;
  height: var(--button-height);
  line-height: var(--button-height);
  border-radius: var(--border-radius);
  vertical-align: middle;
  padding: 0 12px;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-medium);
  color: var(--color-primary);
}
.mat-mdc-form-field.is-inline-new {
  margin: 0;
  display: inline-block;
  height: var(--button-height);
  line-height: var(--button-height);
  border: 1px solid;
  border-radius: var(--border-radius);
  overflow: hidden;
  position: relative;
  vertical-align: middle;
  .mdc-notched-outline {
    display: none;
  }
  .mat-mdc-text-field-wrapper {
    margin-top: -12px;
  }
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}

.om-grouped-actions {
  display: contents;
  > span,
  > .mat-mdc-form-field,
  > .mat-mdc-button {
    border-radius: 0;
    border-left-width: 0;

    &:first-child {
      border-left-width: 1px;
      border-top-left-radius: var(--border-radius);
      border-bottom-left-radius: var(--border-radius);
    }
    &:last-child {
      border-top-right-radius: var(--border-radius);
      border-bottom-right-radius: var(--border-radius);
    }
  }

  &.is-chip {
    > span,
    > .mat-mdc-form-field,
    > .mat-mdc-button {
      &:first-child {
        border-top-left-radius: var(--button-height);
        border-bottom-left-radius: var(--button-height);
      }
      &:last-child {
        border-top-right-radius: var(--button-height);
        border-bottom-right-radius: var(--button-height);
      }
    }
  }
}
