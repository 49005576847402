// http://mcg.mbitson.com/
& {
  --color-primary: #20364b;
  --color-primary-hover: #18293b;
  --color-primary-contrast: white;

  --color-secondary: #475e72;

  --color-label: #a4aab1;
  --color-label-background: #f4f5f6;
  --color-label-hover: #9299a1;
  --color-label-contrast: white;

  --color-accent: #43be9c;
  --color-accent-background: #e8f7f3;
  --color-accent-background-ag-grid: #c7ece1;
  --color-accent-hover: #34af8a;
  --color-accent-contrast: white;

  --color-accent-soft: #3a82bc;
  --color-accent-soft-background: #e7f0f7;
  --color-accent-soft-hover: #2d6fad;
  --color-accent-soft-contrast: white;

  --color-special: #cbe8ff;
  --color-special-hover: #bee2ff;

  --color-alert: #e99b00;
  --color-alert-background: #fcf3e0;
  --color-alert-background-ag-grid: #f8e1b3;
  --color-alert-hover: #e38900;
  --color-alert-contrast: white;

  --color-warning: #e45b5b;
  --color-warning-background: #fcebeb;
  --color-warning-background-ag-grid: #f7cece;
  --color-warning-hover: #dd4949;
  --color-warning-contrast: white;

  --color-background-1: #edf2f9;
  --color-background-2: #ffffff;
  --color-background-3: #f8f9fc;
  --color-background-4: #daf3ef;
  --color-background-emphasis: #f2f3f7;
  --color-background-contrast: black;

  --color-navigation-1: #060c17;
  --color-navigation-2: #04192a;
  --color-navigation-3: #12283a;
  --color-navigation-contrast: white;

  --color-stroke: #d1dadd;
  --color-greyed-out: #d2d5d8;
  --color-user-select: #b3d7ff;

  --color-row-even: #f2f3f7;
  --color-row-odd: #fafafa;

  --color-working-group-1: rgb(136, 119, 252);
  --color-working-group-2: rgb(102, 162, 97);
  --color-working-group-3: rgb(254, 154, 93);

  --color-acronym-icon-1: #63ab7b;
  --color-acronym-icon-2: #23a9bb;
  --color-acronym-icon-3: #e7ac53;
  --color-acronym-icon-contrast: #fff;

  --color-chart-attention: #ff0000;
}
