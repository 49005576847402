om-autocomplete-input {
  .mat-mdc-form-field {
    width: 100%;
    padding: 0 2px;

    .mat-mdc-form-field-infix {
      padding: 9px 10px !important;
    }

    .mat-mdc-chip-set {
      width: 100%;
      margin-left: -8px;
      .mdc-evolution-chip-set__chips {
        margin-left: 0;
        width: 100%;
      }
    }

    .mat-mdc-form-field-icon-prefix {
      .mat-icon {
        padding: 0;
      }
    }
    .mat-mdc-form-field-icon-suffix {
      > .mat-icon {
        width: 10px;
        top: 10px;
        right: 10px;
        &:not(.mat-mdc-chip-disabled) {
          cursor: pointer;
        }
      }
    }
  }
}
.mat-mdc-option {
  .is-pre-line {
    white-space: pre-line;
  }
}
