@mixin empty-badge {
  content: '';
  position: absolute;
  border-radius: 50%;
  width: 6px;
  height: 6px;
  line-height: 0;
  text-align: center;
  background-color: currentColor;
  top: 50%;
  transform: translate(0, -50%);
  opacity: 1;
  display: none;
}
