om-issuer-list-widget {
  article {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
  }
  .mat-column-logo {
    width: 80px;
    text-align: center;

    img {
      max-width: 60px;
      max-height: 40px;
      padding: 2px;
      vertical-align: middle;
    }
  }

  .mat-column-activity {
    cursor: pointer;
  }

  .mat-column-name {
    cursor: pointer;
  }

  .mat-column-name,
  .mat-column-blackout {
    width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  // TODO: Remove once we implement a shared search component
  .mat-mdc-form-field {
    margin-left: 0;
    margin-right: 0;
    border-radius: 0;
    border-top-left-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
    border: thin solid var(--color-label);
    background-color: transparent !important;
    // Border
    .mdc-notched-outline {
      color: transparent;
    }

    // Hover Border
    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
      border: none;
    }
  }
}
