@import '../../../../../node_modules/font-face-generator/mixin';

@include font-face(
  $path: $assets + 'fonts',
  $types: 'woff2' 'woff',
  $fonts: (
    'ProximaNova': (
      200: (
        normal: 'ProximaNova-Light',
        italic: 'ProximaNova-LightItalic',
      ),
      400: (
        normal: 'ProximaNova-Regular',
        italic: 'ProximaNova-RegularItalic',
      ),
      600: (
        normal: 'ProximaNova-Semibold',
        italic: 'ProximaNova-SemiboldItalic',
      ),
      700: (
        normal: 'ProximaNova-Bold',
        italic: 'ProximaNova-BoldItalic',
      ),
      800: (
        normal: 'ProximaNova-Extrabold',
      ),
      900: (
        normal: 'ProximaNova-Black',
      ),
    ),
  )
);
