om-row-expand-cell-renderer {
  cursor: pointer;
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;

  &::before,
  &::after {
    content: ' ';
    border-bottom: 2px solid var(--color-primary);
    transition: transform $transition-timing;
    position: absolute;
    left: 8px;
    top: 9px;
    width: 15px;
  }

  &:not(.is-expanded)::after {
    transform: rotate(-0.25turn);
  }
}
