om-curve-analysis-chart {
  .mat-drawer-container {
    background: none;

    .mat-drawer-content {
      overflow: hidden;
    }

    .mat-drawer-backdrop.mat-drawer-shown {
      background: none;
    }

    .mat-drawer form {
      display: flex;
      max-width: 300px;
      align-items: center;
      margin: 0 var(--spacing-x-large) 0 var(--spacing-x-large);
    }
  }
}
