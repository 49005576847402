om-help-center {
  .mat-mdc-list-item {
    cursor: pointer;
    font-size: var(--font-size-medium);
    font-weight: var(--font-weight-bold);
    background: var(--color-special) !important;
    &:hover {
      background: var(--color-special-hover) !important;
    }
    .mdc-list-item__content {
      height: inherit;
    }
    .mdc-list-item__primary-text {
      display: flex;
      height: inherit;
      align-items: center;
      color: var(--color-primary) !important;
    }
    .mat-icon {
      color: var(--color-primary);
      margin-left: 4px;
    }
  }
}
