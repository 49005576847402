om-issuer-profile-dates-overview-widget {
  article {
    overflow: auto !important;
  }
  .om-basic-list {
    display: flex;
    flex-direction: row;
    li {
      flex-grow: 1;
      flex-shrink: 1;
      padding: 0;
      b {
        padding-right: var(--spacing-medium);
      }
    }
    + .om-basic-list {
      padding-top: var(--spacing-large);
    }
  }
}
