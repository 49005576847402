om-flexible-button-list {
  width: 100%;
  display: block;

  .om-button-list-wrapper {
    overflow: hidden;
    width: fit-content;
    display: flex;
    align-items: center;

    ul {
      display: inline-flex;
      list-style: none;
      padding: 0;
      margin: 0;
    }

    button {
      margin-left: 8px;
      transition: opacity 500ms ease-in-out;
    }

    &.is-redrawing {
      button {
        opacity: 0;
      }
    }

    .om-button-list {
      display: inline-flex;
      padding: 0;
      margin: 0;
    }
  }
}
