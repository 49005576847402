om-tab-control {
  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--color-background-3);
    button {
      min-width: 120px;
      padding: 0 var(--spacing-medium) !important;
      &:first-child {
        justify-content: left;
      }
      &:last-child {
        justify-content: right;
      }
    }
    p {
      margin: 0;
      font-size: var(--font-size-medium);
    }
  }
}
