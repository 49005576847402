om-version-history-sidebar {
  width: 451px;
  .mat-toolbar {
    display: flex;
    justify-content: space-between;
    padding: 9px 16px;
    border-bottom: 2px solid var(--color-stroke);

    om-shared-search {
      margin-right: -6px;
      width: 211px;
    }
  }

  .mat-mdc-checkbox {
    background: var(--color-background-emphasis);
    padding: 10px 14px;

    .mdc-checkbox {
      margin-right: 6px;
    }
  }

  .scroll-viewport {
    height: 100%;
    .trade-data-input {
      color: var(--color-primary);
      font-size: var(--font-size-medium);
      display: grid;
      padding: 10px 16px;

      .trade-data-label {
        font-weight: var(--font-weight-bold);
      }

      .mat-mdc-list {
        padding: 0;

        .mat-mdc-list-item {
          padding: 0;
          align-items: center;
          height: fit-content;

          &::before {
            position: absolute;
            width: 14px;
            height: 14px;
            top: 5px;
            display: inline-block;
            opacity: 0.5;
            content: '';
          }

          &.is-old-data {
            &:first-child {
              &:hover::before,
              &::before {
                background: var(--color-secondary);
              }
            }
          }

          &.is-new-data {
            &:first-child {
              &:hover::before,
              &::before {
                background: var(--color-accent);
              }
            }
          }

          &:not(:first-child) {
            &:hover::before,
            &::before {
              background: var(--color-background-2);
            }
          }

          .mdc-list-item__content {
            margin-left: 20px;

            .mdc-list-item__primary-text {
              white-space: initial;
            }
          }
        }
      }

      &:nth-child(odd) {
        background: var(--color-row-odd);
      }
      &:nth-child(even) {
        background: var(--color-row-even);
      }
    }
  }

  .no-trade-data {
    color: var(--color-primary);
    display: block;
    font-size: var(--font-size-large);
    font-weight: var(--font-weight-bold);
    margin: auto;
    text-align: center;
    padding: 56px 32px;
  }
}
