om-email-list {
  .mat-mdc-list-item {
    background: var(--color-background-2) !important;
    height: unset !important;
    border-bottom: 1px solid var(--color-stroke);
    .om-email-item {
      cursor: pointer;
      width: 100%;
      margin-top: 15px;
      margin-bottom: 15px;
      margin-right: 10px;
      margin-left: 0;
      div {
        display: flex;
        justify-content: space-between;
        section {
          display: flex;
          align-items: center;
        }
        .om-email-from-section {
          flex-grow: 1;
          font-weight: var(--font-weight-bold);
        }
        .om-email-attachment-section {
          padding: 0 15px 0 15px;
        }
        .om-email-sent-section {
          color: var(--color-label);
          font-size: 12px;
        }
      }
      .om-email-subject {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 0;
        margin-top: 25px;
      }
      > span {
        font-size: var(--font-size-small);
      }
    }
    &.is-selected {
      border-right: 4px solid var(--color-accent);
    }
  }

  .mat-mdc-list-base {
    padding-top: 0 !important;
  }
}
