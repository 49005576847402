om-bullet-levels {
  .mat-mdc-tab-labels {
    .mdc-tab {
      min-width: 250px;
    }
  }
  .mat-toolbar {
    dl {
      width: 100%;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      div {
        flex-direction: column;
        align-items: flex-start;
        + div {
          padding-left: calc(2 * var(--spacing-large));
        }
      }
    }
    white-space: normal;
  }
}
