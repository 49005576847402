om-password-strength-meter {
  display: flex;
  flex-direction: column;
  padding-bottom: var(--spacing-medium);

  .strength-bars {
    display: flex;
    gap: var(--spacing-small);

    div {
      width: 100%;
      height: 8px;
      border-radius: var(--border-radius-small);
      background-color: var(--color-greyed-out);

      &.is-weak {
        background-color: var(--color-warning);
      }

      &.is-okay {
        background-color: var(--color-alert);
      }

      &.is-strong {
        background-color: var(--color-accent);
      }
    }
  }

  .strength-name {
    padding-top: var(--spacing-small);
    text-align: center;
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-small);
    color: var(--color-greyed-out);

    &.is-weak {
      color: var(--color-warning);
    }

    &.is-okay {
      color: var(--color-alert);
    }

    &.is-strong {
      color: var(--color-accent);
    }
  }
}
