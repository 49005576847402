om-sidenav-toggle {
  .mat-mdc-nav-list {
    padding: 0;
    .mat-mdc-list-item {
      font-size: var(--font-size-medium);
      .mdc-list-item__primary-text {
        color: var(--color-navigation-contrast);
      }
    }
    .mat-mdc-list-item,
    .mat-icon {
      color: inherit;
    }
  }
}
