om-set-filter-lazy-load {
  display: flex;
  flex-direction: column;
  max-height: 250px;
  max-width: 400px;

  section {
    padding: 0 var(--spacing-medium);
    margin-top: -1px;
    // overflow: hidden;
    flex-shrink: 0;
    + section {
      border-top: 1px solid var(--color-stroke);
    }
  }
  > div {
    border-top: 1px solid var(--color-stroke);
    flex-grow: 1;
    flex-shrink: 1;
    overflow-y: auto;
  }

  .mat-mdc-checkbox {
    display: block;
    margin: var(--spacing-small) 0;
    .mdc-form-field {
      margin: 0;
      width: 100%;
      label {
        flex-grow: 1;
      }
    }
    &.is-hidden {
      display: none;
    }
  }

  input {
    margin: var(--spacing-medium) 0 var(--spacing-small);
    width: calc(100% - var(--spacing-medium) + var(--spacing-small));
    padding: var(--spacing-small);
  }
}
