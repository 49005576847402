$progress-height: 14px;
$border-radius: calc($progress-height/2);

om-linear-progress-indicator {
  .om-progress {
    position: relative;
    height: $progress-height;
    background-color: var(--color-background-1);
    width: 100%;
    border-radius: $border-radius;

    padding: 1px;
    border: 1px solid var(--color-stroke);

    span {
      position: absolute;
      left: 1px;
      height: 10px;
      width: 0;
      background-color: var(--color-accent);
      border-radius: $border-radius;
      transition: width $linear-progress-indicator-transition-time;
    }
  }
}
