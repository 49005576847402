om-trade-blotter {
  .mdc-tab {
    width: 200px;
  }

  > .mat-toolbar {
    section {
      flex-wrap: wrap;
    }
    .mat-mdc-button {
      margin: var(--spacing-medium) calc(var(--spacing-medium) / 2);
    }
  }
}
