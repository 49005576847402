om-two-factor-authentication {
  .mat-mdc-progress-spinner {
    padding: 200px 0 200px 0;

    svg {
      top: 50%;
    }
  }

  div > {
    h3 {
      text-align: center;
      padding: var(--spacing-large);

      &.is-accent {
        background-color: var(--color-accent-background);
      }
      &.is-warning {
        background-color: var(--color-warning-background);
      }
    }

    article {
      margin-bottom: var(--spacing-x-large);

      section {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin-bottom: var(--spacing-large);

        strong {
          width: 33%;
          text-align: center;
        }
      }
    }

    form {
      display: flex;
      align-items: center;
      justify-content: center;
      grid-gap: var(--spacing-large);
      margin: var(--spacing-large) 0 var(--spacing-large) 0;
      .mat-mdc-form-field {
        margin-bottom: 0 !important;
        input {
          padding: 9px 12px;
        }
      }
      .mat-mdc-form-field-subscript-wrapper {
        display: none;
      }
    }

    qrcode {
      text-align: center;
    }

    .new-device-section {
      padding-top: var(--spacing-large);
      border-top: 1px solid var(--color-stroke);
    }

    section:last-child {
      text-align: center;
    }
  }
}
