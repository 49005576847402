om-document-generator-comments-container {
  --document-generator-comments-sidebar-width: 432px;
  --comment-width: 400px;
  width: var(--document-generator-comments-sidebar-width);

  min-width: var(--document-generator-comments-sidebar-width);
  width: var(--document-generator-comments-sidebar-width);
  .mat-mdc-tab-group {
    height: 100%;
    .mdc-tab {
      width: calc(var(--document-generator-comments-sidebar-width) / 3);
      min-width: calc(var(--document-generator-comments-sidebar-width) / 3);
      justify-content: center;
    }
    .mat-mdc-tab-body-wrapper {
      flex-grow: 1;
    }
  }

  a {
    color: var(--color-accent);
  }

  om-comment-card {
    transition: width $sidebar-animation-timing;
    width: var(--comment-width);
    &.is-wide {
      width: 600px;
    }
  }

  &.has-fixed-height {
    height: 100%;
  }

  .om-comments-container-row {
    transition: background-color 1s ease-in-out;

    &.om-highlight-pulse {
      background-color: var(--color-alert);
    }
    display: flex;

    om-action-menu {
      margin-bottom: 0;
      .mat-mdc-icon-button {
        height: 28px;
        width: 28px;
      }
    }
  }

  .sort-container {
    display: flex;
    align-items: center;
    height: 36px;
    padding: 4px 16px;
    background-color: var(--color-background-emphasis);
    border-top: 1px solid var(--color-stroke);

    > span {
      font-size: var(--font-size-medium);
      margin-right: var(--spacing-medium);
    }

    .mat-mdc-form-field {
      width: 134px;
      height: 28px;
      justify-content: center;

      .mat-mdc-form-field-flex {
        height: 28px !important;
      }
    }
  }
}
