om-main-view-sidenav {
  .mat-toolbar {
    a {
      display: flex;
    }
    img {
      width: $sidebar-width-open * 0.8;
      min-width: $sidebar-width-open * 0.8;
      margin: 0 $sidebar-width-open * 0.1;
    }
  }
  .om-sidebar-profile {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    width: $sidebar-width-open;
    text-align: center;
    width: $sidebar-width-open;
    transition: width $sidebar-animation-timing;

    .om-sidebar-profile-img-link {
      display: block;
      line-height: 0;
      margin-top: var(--spacing-large);
      transition: margin $sidebar-animation-timing;
      img {
        width: $sidebar-width-mini;
        height: $sidebar-width-mini;
        object-fit: cover;
      }
    }
    .om-sidebar-profile-details {
      font-size: var(--font-size-medium);
      margin: 0;
      padding: 0 var(--spacing-large);
      width: $sidebar-width-open;
      max-height: 400px;
      overflow: hidden;
      transition:
        max-height $sidebar-animation-timing,
        opacity $sidebar-animation-timing;
      strong {
        display: block;
        margin: var(--spacing-large) 0;
      }
      .mat-mdc-button {
        display: block;
        margin-bottom: var(--spacing-large);
      }
    }
  }
}

.mat-drawer {
  &.om-sidebar-open {
    om-main-view-sidenav {
      .om-section {
        margin: var(--spacing-large) 0 0;
        &:first-of-type {
          margin-top: 0;
        }
      }
    }
  }

  &.om-sidebar-mini {
    om-main-view-sidenav {
      .om-sidebar-profile {
        width: $sidebar-width-mini;
        .om-sidebar-profile-img-link {
          margin-top: 0;
        }
        .om-sidebar-profile-details {
          max-height: 0;
          opacity: 0;
        }
      }

      .om-section-header {
        padding: 20px;
      }
    }
  }
}
