om-version-history-sidenav-form {
  margin: 30px 12px;
  .mat-toolbar {
    height: auto;
    min-height: unset;
    .document-icon-container {
      display: flex;
      border-radius: 17px;
      padding: 8px 5px;
      margin-right: 5px;
      background: var(--color-secondary);

      &.is-new-document {
        background: var(--color-accent);
      }

      .mat-icon {
        height: 18px;
      }
    }

    .sidenav-form-header {
      display: flex;
      flex-direction: column;
      position: relative;
      width: 100%;
      line-height: var(--line-height-small);

      > span:first-child {
        font-size: var(--font-size-large);
      }

      > span:nth-child(2) {
        font-size: var(--font-size-small);
        font-weight: var(--font-weight-normal);
        white-space: normal;
      }

      .mat-mdc-button {
        position: absolute;
        height: 20px;
        top: 0;
        right: 0;
        margin-right: -16px;
        border: none;
        background: none;
        color: var(--color-background-2);
        line-height: 12px;

        .mdc-button__label {
          font-size: var(--font-size-medium);
          font-weight: var(--font-weight-normal);
          border-bottom: 1px solid var(--color-background-2);
        }

        &:hover {
          background: none !important;
        }
      }
    }
  }

  form {
    > .mat-mdc-form-field {
      margin-top: 10px;
      margin-bottom: 0 !important;
    }

    .upload-note {
      margin-top: 10px;
      span {
        color: var(--color-background-2);
        font-size: var(--font-size-medium);
      }
    }
  }
}
