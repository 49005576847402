om-page-placeholder {
  position: relative;
  height: 100%;
  text-align: center;

  img {
    height: 90px;
    margin-top: 64px;
  }

  p {
    font-size: var(--font-size-large);
    line-height: 18px;
    margin-top: 35px;
  }
  p + p {
    font-size: var(--font-size-medium);
    margin-top: 0;
  }
}
