om-template-testing-sidebar-content {
  width: 400px;
  .mat-toolbar[footer] {
    border-top: 1px solid var(--color-stroke);
    background: var(--color-background-3);
    .mat-mdc-button.is-strong {
      width: 66px;
    }
  }
}
