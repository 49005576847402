&.om-morpho {
  .mat-mdc-button-base {
    height: var(--button-height);
    line-height: var(--button-height);
    vertical-align: middle;
    padding: 0 var(--spacing-large);
    transition:
      background 0.25s ease-in-out,
      border 0.25s ease-in-out,
      color 0.25s ease-in-out;
    &:not(.flex-shrink) {
      flex-shrink: 0;
    }

    &.mat-mdc-unelevated-button {
      background-color: unset;

      &.is-accent {
        color: var(--color-accent);
      }
      &.is-warning {
        color: var(--color-warning);
      }
    }

    .mat-icon {
      color: currentColor;
    }

    mat-menu + .mat-icon:first-of-type {
      &:last-of-type {
        margin-left: -8px;
        margin-right: -(var(--spacing-small));
      }
      &:not(:last-of-type) {
        margin-left: -8px;
        margin-right: var(--spacing-small);
      }
    }
    &:disabled {
      opacity: var(--disabled-opacity);
      pointer-events: none;
    }
  }

  .mat-mdc-button-disabled {
    opacity: var(--disabled-opacity);
  }

  .mat-mdc-button {
    border-radius: var(--border-radius);
    font-weight: var(--font-weight-bold);

    .mat-icon {
      margin-left: -4px;
      margin-right: var(--spacing-medium);
      vertical-align: middle;
      &[iconPositionEnd] {
        margin-right: -4px;
        margin-left: var(--spacing-medium);
      }
    }

    .mdc-button__label {
      white-space: nowrap;
    }
    &.is-chip {
      border-radius: var(--button-height);
    }
    &.is-thin {
      padding: 0 12px;
    }
  }

  .mat-mdc-icon-button,
  .mat-mdc-icon-button:disabled,
  .mat-mdc-mini-fab.is-not-strong {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: var(--button-height);
    color: currentColor;

    &.is-not-strong {
      background: var(--color-navigation-contrast);
      color: var(--color-primary);
    }

    &.is-chip {
      border-radius: var(--border-radius);
      .mat-icon {
        margin-left: -1px;
      }
    }
    &:not(.is-chip) {
      &.is-accent {
        color: var(--color-accent);
      }
      &.is-label {
        color: var(--color-label);
      }
      &.is-alert {
        color: var(--color-alert);
      }
      &.is-warning {
        color: var(--color-warning);
      }
      &.is-stroke {
        color: var(--color-stroke);
      }
      &:hover:not(:disabled):not(.is-flat) {
        background: var(--color-primary);
        color: var(--color-background-2);
        &.is-accent {
          color: var(--color-accent-contrast);
          background: var(--color-accent);
        }
        &.is-label {
          color: var(--color-label-contrast);
          background: var(--color-label);
        }
        &.is-alert {
          color: var(--color-alert-contrast);
          background: var(--color-alert);
        }
        &.is-warning {
          color: var(--color-warning-contrast);
          background: var(--color-warning);
        }
      }

      &.is-extended {
        width: calc(var(--button-height) + var(--spacing-medium));
        border-radius: calc(var(--button-height) / 2);

        &.has-menu {
          &:after {
            content: '';
            width: 0;
            height: 0;
            display: inline-block;
            border-top: 6px solid currentColor;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
          }
        }
      }
    }
  }

  .mat-mdc-button,
  .mat-mdc-button:disabled:disabled,
  .mat-mdc-icon-button.is-chip,
  .mat-mdc-icon-button.is-chip:disabled {
    border: 1px solid;

    background: transparent;
    border-color: var(--color-primary);
    color: var(--color-primary);
    &.is-active,
    &:hover:not(:disabled):not(.is-flat) {
      background: var(--color-primary);
      color: var(--color-background-2);
    }

    &.is-label {
      border-color: var(--color-label);
      color: var(--color-label);
      &.is-active,
      &:hover:not(:disabled):not(.is-flat) {
        background: var(--color-label);
        color: var(--color-label-contrast);
      }
    }

    &.is-accent {
      border-color: var(--color-accent);
      color: var(--color-accent);
      &.is-active,
      &:hover:not(:disabled):not(.is-flat) {
        background: var(--color-accent);
        color: var(--color-accent-contrast);
      }
    }

    &.is-alert {
      border-color: var(--color-alert);
      color: var(--color-alert);
      &.is-active,
      &:hover:not(:disabled):not(.is-flat) {
        background: var(--color-alert);
        color: var(--color-alert-contrast);
      }
    }

    &.is-warning {
      border-color: var(--color-warning);
      color: var(--color-warning);
      &.is-active,
      &:hover:not(:disabled):not(.is-flat) {
        background: var(--color-warning);
        color: var(--color-warning-contrast);
      }
    }
    &.is-secondary,
    &.is-secondary:disabled {
      &,
      &:hover:not(:disabled) {
        border-color: transparent;
        background: transparent;
        color: var(--color-label);
      }
      &.is-accent {
        color: var(--color-accent);
      }
    }

    &.is-card {
      background: var(--color-background-2);
      border-radius: var(--border-radius);
      border-color: transparent;
      @include mat.elevation($elevation-value);
    }
  }

  .mat-mdc-button,
  .mat-button:disabled {
    &.is-text {
      &,
      &:hover:not(:disabled) {
        color: var(--color-primary);
        background: transparent;
        &.is-label {
          color: var(--color-label);
          background: transparent;
        }
        &.is-accent {
          color: var(--color-accent);
          background: transparent;
        }
        &.is-alert {
          color: var(--color-alert);
          background: transparent;
        }
        &.is-warning {
          color: var(--color-warning);
          background: transparent;
        }
      }
      border: none;
      display: contents;
      font-weight: inherit;
      padding: 0;
      line-height: 1em;
      .mat-icon {
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
      }
      .mat-ripple,
      .mat-mdc-focus-indicator,
      .mat-mdc-button-touch-target,
      .mat-mdc-button-persistent-ripple {
        display: none;
      }
      &:hover:not(:disabled) {
        text-decoration: underline;
      }
    }
  }

  .mat-mdc-button.is-strong,
  .mat-button:disabled.is-strong,
  .mat-mdc-icon-button.is-strong,
  .mdc-icon-button:disabled.is-strong,
  .mat-mdc-mini-fab,
  .mdc-fab--mini:disabled,
  .mat-mdc-fab,
  .mdc-fab:disabled {
    background: var(--color-primary);
    border-color: var(--color-primary);
    border: none;
    color: var(--color-background-2);

    &:not(.is-not-strong) {
      &.is-label {
        background: var(--color-label);
        border-color: var(--color-label);
        color: var(--color-label-contrast);
      }
      &.is-accent {
        background: var(--color-accent);
        border-color: var(--color-accent);
        color: var(--color-accent-contrast);
      }
      &.is-alert {
        background: var(--color-alert);
        border-color: var(--color-alert);
        color: var(--color-alert-contrast);
      }
      &.is-warning {
        background: var(--color-warning);
        border-color: var(--color-warning);
        color: var(--color-warning-contrast);
      }
      &.is-primary {
        background: var(--color-primary);
        border-color: var(--color-primary);
        color: var(--color-background-2);
      }
    }
    &:hover:not(:disabled) {
      background: var(--color-primary-hover);
      &.is-label {
        background: var(--color-label-hover);
      }
      &.is-accent {
        background: var(--color-accent-hover);
      }
      &.is-alert {
        background: var(--color-alert-hover);
      }
      &.is-warning {
        background: var(--color-warning-hover);
      }
      &.is-primary {
        background: var(--color-primary-hover);
      }
    }
  }

  .mat-mdc-mini-fab,
  .mat-fab--mini:disabled {
    box-shadow: none;
    width: var(--button-height);
    .mdc-button__label {
      padding: 6px 0;
    }
  }

  .mat-mdc-fab {
    height: 48px;
    width: 48px;
    .mdc-button__label {
      padding: 12px 0;
    }
    .mat-icon {
      height: 21px;
      margin-top: 0;
    }
  }

  .om-page-toolbar,
  .om-top-toolbar {
    .mat-mdc-icon-button,
    .mat-mdc-icon-button.is-chip,
    .mat-mdc-button {
      &:not(.is-label):not(.is-accent):not(.is-alert):not(.is-stroke):not(.is-warning):not(.is-primary) {
        &:not(.is-text) {
          background: var(--color-navigation-2);
          border-color: var(--color-navigation-contrast);
          color: var(--color-navigation-contrast);
          &.is-active,
          &.is-strong,
          &:hover:not(:disabled):not(.is-flat) {
            background: var(--color-navigation-contrast);
            color: var(--color-navigation-2);
          }

          &.is-flat {
            border-color: var(--color-navigation-2);
          }
        }
        &.is-text,
        &.is-text:disabled {
          &,
          &:hover:not(:disabled) {
            color: var(--color-navigation-contrast);
          }
        }
      }
    }
  }

  .mat-mdc-icon-button,
  .mat-mdc-icon-button.is-chip,
  .mat-mdc-button {
    &.is-invert {
      &:not(.is-label):not(.is-accent):not(.is-alert):not(.is-stroke):not(.is-warning):not(.is-primary) {
        &:not(.is-text) {
          background: transparent;
          border-color: var(--color-navigation-contrast);
          color: var(--color-navigation-contrast);
          &.is-active,
          &.is-strong,
          &:hover:not(:disabled) {
            background: var(--color-navigation-contrast);
            color: var(--color-navigation-2);
          }

          &.is-flat {
            border-color: var(--color-navigation-2);
          }
        }
        &.is-text,
        &.is-text:disabled {
          &,
          &:hover:not(:disabled) {
            color: var(--color-navigation-contrast);
          }
        }
      }
    }
  }

  .om-multi-action {
    display: inline-flex;
    height: var(--button-height);
    padding-right: 5px;
    .mat-mdc-button,
    .mat-mdc-form-field,
    .mat-mdc-icon-button {
      margin-left: 0;
      margin-right: 0;
      border-radius: 0;
      &:first-child {
        border-top-left-radius: var(--border-radius);
        border-bottom-left-radius: var(--border-radius);

        background: var(--color-background-2);
      }
      &:last-child {
        border-top-right-radius: var(--border-radius);
        border-bottom-right-radius: var(--border-radius);
      }
      &:not(:last-child) {
        border-right: none;
      }
      &.is-strong {
        &:not(:first-child) {
          border-left: 1px solid currentColor;
        }
      }
      &.is-toggle-checked {
        background: var(--color-accent-background);
        color: var(--color-background-contrast);
        margin-right: -1px;
        margin-left: -1px;
        border: 1px solid var(--color-accent);
        z-index: 2;
      }
      &.is-toggle-disabled {
        pointer-events: none;
        color: inherit;
        opacity: var(--disabled-opacity);
      }
    }
    .mat-mdc-button {
      &:disabled {
        opacity: 1;
      }
    }
    .mat-mdc-form-field {
      margin: 0;
      .mat-mdc-text-field-wrapper {
        margin: 0;
        padding: 0;
      }
      .mat-mdc-form-field-subscript-wrapper {
        padding: 0;
      }
      .mat-icon {
        height: 16px;
        padding: 0 !important;
      }
      .mdc-notched-outline {
        display: none !important;
      }
    }
    .is-chip {
      &:first-child {
        border-top-left-radius: var(--chip-border-radius);
        border-bottom-left-radius: var(--chip-border-radius);
      }
      &:last-child {
        border-top-right-radius: var(--chip-border-radius);
        border-bottom-right-radius: var(--chip-border-radius);
      }
    }

    om-action-menu {
      > .mat-mdc-icon-button {
        &:first-child {
          border-top-right-radius: var(--chip-border-radius);
          border-bottom-right-radius: var(--chip-border-radius);
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
          border: 1px solid;

          .mat-icon {
            margin-top: 0;
            margin-bottom: 2px;
            margin-left: -5px;
          }

          &:hover {
            border: 1px solid var(--color-primary);
          }
        }
      }
    }

    > .mat-mdc-form-field {
      .mat-mdc-form-field-infix {
        padding: 0;
      }
    }
  }

  .om-floating-action {
    position: absolute;
    right: 16px;
    bottom: 16px;
    z-index: 1;
  }
}

.is-loading {
  .mat-mdc-dialog-content,
  .mat-mdc-dialog-actions,
  .mat-mdc-button {
    pointer-events: none;
  }
  .mat-mdc-button {
    .mat-mdc-progress-spinner {
      display: inline-flex;
      margin-left: 5px;
      width: 16px !important;
      height: 16px !important;
      .mdc-circular-progress__determinate-container {
        svg {
          width: inherit !important;
          height: inherit !important;
          circle {
            stroke: currentColor !important;
          }
        }
      }
      .mdc-circular-progress__indeterminate-container {
        svg {
          width: unset !important;
          height: 100% !important;
        }
      }
    }
  }
}
