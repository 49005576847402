om-callable-input-detail-renderer {
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: var(--spacing-large) var(--spacing-large) calc(2 * var(--spacing-large));
  position: absolute;
  background: var(--color-background-2);

  article {
    table {
      th {
        max-width: 200px;
        white-space: normal;
      }
      td {
        max-width: 300px;
        white-space: normal;
      }
    }

    > mat-form-field {
      display: block;
      width: 100%;
    }

    p {
      width: 100%;
      word-break: break-word;
      white-space: pre-line;
    }

    .om-readonly-callable-grid {
      table {
        thead {
          td {
            font-weight: var(--font-weight-bold);
          }
        }
        td {
          border: 1px solid var(--color-stroke);
          padding: 0 var(--spacing-large);
          text-align: center;
        }
      }
    }

    + article {
      overflow: scroll;
      height: fit-content;
      margin-left: var(--spacing-large);
      padding-top: 5px;
    }
  }
}
