om-dynamic-cell-editor {
  om-primitive-input input {
    padding: unset;
    border: none !important;
    text-align: center;
  }
}

om-primitive-cell-editor {
  display: flex;
  width: 100%;

  om-primitive-input,
  input {
    width: inherit;
  }

  input {
    font-size: var(--font-size-small);
    height: 100%;
    outline: none;
    border: none;
    padding: 0 2px;
  }
}
