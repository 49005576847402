om-issuer-profile-fallback-provisions-widget {
  .mat-toolbar {
    cursor: pointer;
  }

  .om-table {
    th {
      width: 250px;
    }
    td {
      span {
        margin-left: -3px;
      }
    }
  }

  .mat-expansion-panel-body {
    padding-top: 0;
    padding-right: 0;
    padding-left: 0;
    padding-bottom: var(--spacing-large);
  }
}
