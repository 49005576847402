om-narrow-card {
  &.is-active {
    mat-card {
      border-left: var(--border-radius) solid var(--color-accent);
    }
  }
  mat-card {
    border-left: var(--border-radius) solid transparent;
    margin-bottom: var(--spacing-large);

    &.mat-mdc-card {
      &[class*='mat-elevation-z'] {
        &:not(:hover) {
          box-shadow: none;
        }
      }
    }
  }

  display: block;
  .mat-mdc-card-avatar {
    height: 24px;
    width: 24px;
    float: left;

    &.mat-icon {
      color: var(--color-label);
      height: 24px;
    }
  }
  .mat-mdc-card-header {
    .mat-mdc-card-header-text {
      margin: 0;
      mat-card-title {
        margin-right: var(--spacing-medium);
        color: var(--color-primary);
      }
      mat-card-subtitle {
        margin-bottom: 0;
      }

      mat-card-title,
      mat-card-subtitle {
        font-size: var(--font-size-small);
      }
    }
  }

  p {
    white-space: pre-line;
  }
}

.is-active {
  om-narrow-card {
    mat-card {
      border-left: var(--border-radius) solid var(--color-accent);
    }
  }
}
