om-issuer-profile-entities-widget {
  .om-table {
    th {
      width: 250px;
    }
  }
  .om-ratings-wrapper {
    padding: var(--spacing-large);
    border-top: 1px dashed var(--color-stroke);
    background: var(--color-background-3);
    strong {
      display: block;
      font-size: var(--font-size-medium);
      color: var(--color-primary);
      margin-bottom: var(--spacing-large);
    }

    om-issuer-profile-entities-ratings-widget {
      .mat-column-moodys,
      .mat-column-sp,
      .mat-column-fitch,
      .mat-column-scope {
        width: 150px;
      }
    }
  }
}
