.mat-mdc-card,
.mdc-dialog__container,
.mdc-dialog-container,
.mat-drawer,
.mat-expansion-panel {
  background-color: var(--color-background-2);
}

b {
  font-weight: var(--font-weight-bold); // firefox doesn't set the font-weight by default for the b tag.
}
