om-bullet-levels-tab {
  height: 100%;
  display: flex;
  flex-direction: column;
  mat-toolbar[footer] {
    height: 34px;
    min-height: 34px;
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-normal);
    span {
      font-weight: normal !important;
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        margin-left: var(--spacing-medium);
        display: inline-block;
      }
      span {
        display: inline-block;
        vertical-align: middle;
        width: 16px;
        height: 16px;
        margin-top: -2px;
      }
    }
  }
}
